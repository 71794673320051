import API from './API';
import { CONTACT_SERVICE_URL as servicePath } from '../constants/APIconstants';
import { GET, POST, PUT, DELETE } from '../utils/Executer';

class ContactServiceAPI {
  async fetch() {
    return await API.client.execute(GET, servicePath);
  }

  async createContact({ contact }) {
    return await API.client.execute(POST, servicePath, contact);
  }

  async updateContact({ id, contact }) {
    return await API.client.execute(PUT, `${servicePath}${id}`, contact);
  }

  async deleteContact({ id }) {
    return await API.client.execute(DELETE, `${servicePath}${id}`);
  }
}

export default new ContactServiceAPI();
