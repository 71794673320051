// @ts-check

import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { getParentIdByPath } from '../../utils/Utils';
import {
  AUTH_LOGOUT,
  INITIAL_STATE,
  APPLICATION_WIPE_DATA,
  ADMISSION_USE_CACHE,
  ADMISSION_UPDATE_CONTENT,
  ADMISSION_CREATE_CATEGORY,
  ADMISSION_UPDATE_CATEGORY,
  ADMISSION_DELETE_CATEGORY,
  ADMISSION_CHECK_CREATE,
  ADMISSION_CHECK_DELETE,
  ADMISSION_CHECK_UPDATE,
  ADMISSION_CATEGORY_ORDER,
  ADMISSION_CHECKLIST_LOCATION,
  ADMISSION_CHECKLIST_UPDATE_COMMENT,
  ADMISSION_CHECKLIST_UPDATE_CHECK,
  ADMISSION_CHECKLIST_RESET,
  ADMISSION_CHECK_ORDER,
} from '../actions';

/**
 * @typedef {{
 * id: string,
 * path: string,
 * }} AdmissionCategory
 */

/**
 * @typedef {{
 *  categories: null | AdmissionCategory[],
 *  categoriesById: null | { [key: string]: AdmissionCategory },
 *  lastUpdated: null | number,
 *  previousLastUpdated: null | number,
 *  activeChecklist: {[key: string]: unknown},
 *  isReady: boolean
 * }} AdmissionsReducerState
 */

/**
 * @type {AdmissionsReducerState}
 */
const initialState = {
  categories: null,
  categoriesById: null,
  lastUpdated: null,
  previousLastUpdated: null,
  activeChecklist: {},
  isReady: false,
};

/**
 * 
 * @param {AdmissionsReducerState} state 
 * @param {{ categories: AdmissionCategory[], categoriesById: { [key: string]: AdmissionCategory }}} param1 
 */
const updateData = (state, { categories, categoriesById }) =>
  update(state, {
    $merge: {
      categories,
      categoriesById,
      lastUpdated: Date.now(),
      previousLastUpdated: state.lastUpdated || Date.now(),
      isReady: true,
    },
  });

/**
 * 
 * @param {AdmissionsReducerState} state 
 * @param {{ id: string, categories: AdmissionCategory[], }} change 
 */
const updateCategory = (state, change) => {
  let { id } = change;

  // @ts-ignore
  let index = state.categories.findIndex(category => category.id === id);

  return update(state, {
    categories: {
      [index]: { $merge: change },
    },
    categoriesById: {
      [id]: { $merge: change },
    },
    lastUpdated: { $set: Date.now() },
  });
};

/**
 * 
 * @param {AdmissionsReducerState} state 
 * @param {{ id: string }} change 
 */
const deleteCategory = (state, { id }) => {
  // @ts-ignore
  let index = state.categories.findIndex(category => category.id === id);
  
  /**
   * @type {AdmissionCategory}
   */
  // @ts-ignore
  let cat = state.categoriesById[id];

  let parentId = getParentIdByPath(cat.path);

  let updateAction = {
    categories: { $splice: [[index, 1]] },
    categoriesById: {
      // @ts-ignore
      $apply: function (obj) {
        delete obj[id];
        return obj;
      },
    },
    lastUpdated: { $set: Date.now() },
  };

  if (parentId) {
    // @ts-ignore
    let indexParent = state.categories.findIndex(category => category.id === parentId);
    // @ts-ignore
    let currentIndex = state.categories[indexParent].subcategories.findIndex(i => i === id);
    let action = {
      subcategories: {
        $splice: [[currentIndex, 1]],
      },
    };

    // @ts-ignore
    updateAction.categories[indexParent] = action;
    // @ts-ignore
    updateAction.categoriesById[parentId] = action;
  }

  // @ts-ignore
  return update(state, updateAction);
};

/**
 * 
 * @param {AdmissionsReducerState} state 
 * @param {{ category: AdmissionCategory[], path: string }} param1 
 */
const createCategory = (state, { path, category }) => {
  let parentId = getParentIdByPath(path);

  let updateAction = {
    categories: {
      $push: [category],
    },
    categoriesById: {
      // @ts-ignore
      [category.id]: { $set: category },
    },
    lastUpdated: { $set: Date.now() },
  };

  if (parentId) {
    // @ts-ignore
    let indexParent = state.categories.findIndex(category => category.id === parentId);
    let action = {
      // @ts-ignore
      subcategories: { $push: [category.id] },
    };
    // @ts-ignore
    updateAction.categories[indexParent] = action;
    // @ts-ignore
    updateAction.categoriesById[parentId] = action;
  }

  // @ts-ignore
  return update(state, updateAction);
};

/**
 * 
 * @param {AdmissionsReducerState} state 
 * @param {{ id: string, categoryId: string, index: number }} param1 
 */
const updateSubcategories = (state, { id, categoryId, index }) => {
  // @ts-ignore
  let catIndex = state.categories.findIndex(category => category.id === id);
  // @ts-ignore
  let currentIndex = state.categories[catIndex].subcategories.findIndex(i => i === categoryId);
  // @ts-ignore
  let item = state.categories[catIndex].subcategories[currentIndex];

  return update(state, {
   // @ts-ignore
    categories: {
      [catIndex]: {
        subcategories: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },
    // @ts-ignore
    categoriesById: {
      [id]: {
        subcategories: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },

    lastUpdated: { $set: Date.now() },
  });
};

/**
 * 
 * @param {AdmissionsReducerState} state 
 * @param {{ id: string, categoryId: string, sourceId: string }} param1 
 */
const moveItem = (state, { id, categoryId, sourceId }) => {
  // @ts-ignore
  let destinationCatIndex = state.categories.findIndex(category => category.id === categoryId);
  // @ts-ignore
  let sourceCatIndex = state.categories.findIndex(category => category.id === sourceId);
  // @ts-ignore 
  let currentIndex = state.categories[sourceCatIndex].checks.findIndex(item => item.id === id);

  // @ts-ignore
  let check = state.categories[sourceCatIndex].checks[currentIndex];

  return update(state, {
    // @ts-ignore
    categories: {
      [sourceCatIndex]: {
        checks: {
          $splice: [[currentIndex, 1]],
        },
      },
      [destinationCatIndex]: {
        checks: {
          $push: [check],
        },
      },
    },
    // @ts-ignore
    categoriesById: {
      [sourceId]: {
        checks: {
          $splice: [[currentIndex, 1]],
        },
      },
      [categoryId]: {
        checks: {
          $push: [check],
        },
      },
    },

    lastUpdated: { $set: Date.now() },
  });
};

/**
 * 
 * @param {AdmissionsReducerState} state 
 * @param {{ comment: string, categoryId: string, id: string }} param1 
 */
const updateComment = (state, { comment, categoryId, id }) => {
  let isNew = !state.activeChecklist[categoryId];
  // @ts-ignore
  let isNewItem = !isNew && !state.activeChecklist[categoryId][id];
  let change = {};

  if (isNew) {
    change = {
      $set: {
        [id]: { comment },
      },
    };
  } else if (isNewItem) {
    change = {
      $merge: {
        [id]: { comment },
      },
    };
  } else {
    change = {
      [id]: {
        $merge: { comment },
      },
    };
  }

  return update(state, {
    // @ts-ignore
    activeChecklist: {
      [categoryId]: change,
    },
  });
};

/**
 * 
 * @param {AdmissionsReducerState} state 
 * @param {{ checked: boolean, categoryId: string, id: string }} param1 
 */
const updateCheckState = (state, { checked, categoryId, id }) => {
  let isNew = !state.activeChecklist[categoryId];
  // @ts-ignore
  let isNewItem = !isNew && !state.activeChecklist[categoryId][id];
  let change = {};

  if (isNew) {
    change = {
      $set: {
        [id]: { checked },
      },
    };
  } else if (isNewItem) {
    change = {
      $merge: {
        [id]: { checked },
      },
    };
  } else {
    change = {
      [id]: {
        $merge: { checked },
      },
    };
  }

  return update(state, {
    // @ts-ignore
    activeChecklist: {
      [categoryId]: change,
    },
  });
};

/**
 * 
 * @param {AdmissionsReducerState} state 
 * @param {{ id: string, itemId: string, index: number }} param1 
 */
const updateChecksOrder = (state, { id, itemId, index }) => {
  // @ts-ignore
  let catIndex = state.categories.findIndex(category => category.id === id);
  // @ts-ignore
  let currentIndex = state.categories[catIndex].checks.findIndex(i => i.id === itemId);
  // @ts-ignore
  let item = state.categories[catIndex].checks[currentIndex];

  return update(state, {
    // @ts-ignore
    categories: {
      [catIndex]: {
        checks: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },
    // @ts-ignore
    categoriesById: {
      [id]: {
        checks: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },

    lastUpdated: { $set: Date.now() },
  });
};


/**
 * @param {AdmissionsReducerState} state 
 */
const resetChecklist = state =>
  update(state, {
    activeChecklist: { $set: {} },
    lastUpdated: { $set: Date.now() },
  });

/**
 * @param {AdmissionsReducerState} state 
 */
const useCache = state =>
  update(state, {
    isReady: { $set: true },
  });

/**
 * @param {AdmissionsReducerState} state 
 * @param {{ admission: AdmissionsReducerState}} param1
 */
const setInitialState = (state, { admission = initialState }) =>
  update(state, {
    $merge: {
      ...admission,
      activeChecklist: {},
      isReady: false,
    },
  });

const cleanData = () => {
  return initialState;
};

/**
 * @type {{
 *   [INITIAL_STATE]: typeof setInitialState,
 *   [ADMISSION_UPDATE_CONTENT]: typeof updateData,
 *   [ADMISSION_UPDATE_CATEGORY]: typeof updateCategory,
 *   [ADMISSION_CREATE_CATEGORY]: typeof createCategory,
 *   [ADMISSION_DELETE_CATEGORY]: typeof deleteCategory,
 *   [ADMISSION_CATEGORY_ORDER]: typeof updateSubcategories,
 *   [ADMISSION_CHECK_CREATE]: typeof updateCategory,
 *   [ADMISSION_CHECK_UPDATE]: typeof updateCategory,
 *   [ADMISSION_CHECK_DELETE]: typeof updateCategory,
 *   [ADMISSION_CHECK_ORDER]: typeof updateChecksOrder,
 *   [ADMISSION_CHECKLIST_LOCATION]: typeof moveItem,
 *   [ADMISSION_CHECKLIST_UPDATE_COMMENT]: typeof updateComment,
 *   [ADMISSION_CHECKLIST_UPDATE_CHECK]: typeof updateCheckState,
 *   [ADMISSION_CHECKLIST_RESET]: typeof resetChecklist,
 *   [ADMISSION_USE_CACHE]: typeof useCache,
 *   [APPLICATION_WIPE_DATA]: typeof cleanData,
 *   [AUTH_LOGOUT]: typeof cleanData,
 * }}
 */
const actionsRepo = {
  [INITIAL_STATE]: setInitialState,
  [ADMISSION_UPDATE_CONTENT]: updateData,
  [ADMISSION_UPDATE_CATEGORY]: updateCategory,
  [ADMISSION_CREATE_CATEGORY]: createCategory,
  [ADMISSION_DELETE_CATEGORY]: deleteCategory,
  [ADMISSION_CATEGORY_ORDER]: updateSubcategories,
  [ADMISSION_CHECK_CREATE]: updateCategory,
  [ADMISSION_CHECK_UPDATE]: updateCategory,
  [ADMISSION_CHECK_DELETE]: updateCategory,
  [ADMISSION_CHECK_ORDER]: updateChecksOrder,
  [ADMISSION_CHECKLIST_LOCATION]: moveItem,
  [ADMISSION_CHECKLIST_UPDATE_COMMENT]: updateComment,
  [ADMISSION_CHECKLIST_UPDATE_CHECK]: updateCheckState,
  [ADMISSION_CHECKLIST_RESET]: resetChecklist,
  [ADMISSION_USE_CACHE]: useCache,
  [APPLICATION_WIPE_DATA]: cleanData,
  [AUTH_LOGOUT]: cleanData,
};

export default createReducer({ initialState, actionsRepo });

