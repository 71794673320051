import { APP_IS_READY, AUTH_ACTIVATE_ACCOUNT, AUTH_LOGIN } from '../actions';
import { fetchAvailableOrganizations } from '../actions/available-organizations.actions';
import userService from '../../services/user.service';

export const onAuthenticationProcessDone =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_ACTIVATE_ACCOUNT
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(fetchAvailableOrganizations());
    }
  };

export const availableOrganizationsMiddleware = [onAuthenticationProcessDone];
