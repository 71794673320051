import { DPLNK_INIT } from '.';
import { DEEP_LINKING_ACTIONS } from '../../constants';
import { editItem } from './knowledge.items.actions';

export const DEPP_LINK_ACTIONS_APP_INIT = {};
export const DEPP_LINK_ACTIONS_KNOWLEDGE_READY = {
  [DEEP_LINKING_ACTIONS.item_edit]: editItem,
};

export const deepLinkTrigger = payload => dispatch => {
  if (!DEEP_LINKING_ACTIONS[payload.action]) {
    return;
  }

  dispatch({
    type: DPLNK_INIT,
    payload,
  });
};
