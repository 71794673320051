import React, { useCallback } from 'react';
import styles from './report-problem.module.scss';
import { faArrowLeft, faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ISSUE_TYPES, USER_FEEDBACK } from 'shared/constants';
import classnames from 'classnames';

const ISSUE_SCHEMA = Yup.object().shape({
  problems: Yup.array().required('Required!'),
  description: Yup.string(),
});

const ReportProblemComponent = React.memo(function ReportProblemComponent({
  lang,
  close,
  giveFeedback,
}) {
  const submitReport = useCallback(
    async data => {
      await giveFeedback({
        data: {
          issue: { ...data, timestamp: new Date().toISOString() },
          device: {
            platform: 'widget',
            userAgent: window.navigator.userAgent,
          },
          type: ISSUE_TYPES.GIVE_FEEDBACK,
        },
      });

      close();
    },
    [giveFeedback, close],
  );

  const { handleSubmit, handleChange, setFieldValue, values, errors } = useFormik({
    validationSchema: ISSUE_SCHEMA,
    initialValues: {
      problems: [],
      description: '',
    },
    onSubmit: submitReport,
  });

  const renderHeader = useCallback(
    () => (
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} onClick={close} />
          <div className={styles.title}>{lang.TITLE}</div>
        </div>
        <div className={styles.actionButtons}>
          <button type="submit" className={styles.actionButton} onClick={handleSubmit}>
            <FontAwesomeIcon icon={faCheck} className={styles.actionButtonIcon} />
            <p className={styles.actionButtonText}>{lang.SUBMIT}</p>
          </button>
          <button className={styles.actionButton} onClick={close}>
            <FontAwesomeIcon icon={faBan} className={styles.actionButtonIcon} />
            <p className={styles.actionButtonText}>{lang.DISMISS}</p>
          </button>
        </div>
      </div>
    ),
    [close, handleSubmit, lang.DISMISS, lang.SUBMIT, lang.TITLE],
  );

  const handleTagChange = useCallback(
    (tag, checked) => {
      const nextSelectedTags = checked
        ? [...values.problems, tag]
        : values.problems.filter(t => t !== tag);
      setFieldValue('problems', nextSelectedTags);
    },
    [values.problems, setFieldValue],
  );

  const renderOptions = useCallback(
    () => (
      <div className={styles.tags}>
        <h5 className={styles.label}>{lang.PROBLEMS_TITLE}</h5>
        <div className={styles.tagWrapper}>
          {Object.keys(USER_FEEDBACK).map(problem => {
            const selected = values.problems.includes(problem);

            return (
              <p
                key={problem}
                checked={selected}
                onClick={() => handleTagChange(problem, !selected)}
                className={classnames([styles.tag, selected && styles.selected])}>
                {lang?.[problem]}
              </p>
            );
          })}
        </div>
      </div>
    ),
    [handleTagChange, lang, values.problems],
  );

  const renderComments = useCallback(
    () => (
      <div>
        <h5 className={styles.label}>{lang.DESCRIPTION}</h5>
        <textarea
          placeholder={lang.DESCRIPTION_PLACEHOLDER}
          name="description"
          value={values.description}
          error={errors.description}
          onChange={handleChange}
          className={styles.textarea}
        />
      </div>
    ),
    [
      errors.description,
      handleChange,
      lang.DESCRIPTION,
      lang.DESCRIPTION_PLACEHOLDER,
      values.description,
    ],
  );

  return (
    <div className={styles.root}>
      {renderHeader()}
      {renderOptions()}
      {renderComments()}
    </div>
  );
});

export { ReportProblemComponent };
