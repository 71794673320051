import API from './API';
import { GET, POST } from '../utils/Executer';
import { AUTHENTICATION_SERVICE_URL as servicePath } from '../constants/APIconstants';

class AuthenticationServiceAPI {
  async loginWithEmail({ email }) {
    return await API.client.execute(
      POST,
      `${servicePath}`,
      { email },
      {
        skipGenericErrorHandling: true,
      },
    );
  }

  async verifyCode(data) {
    return await API.client.execute(POST, `${servicePath}verify-code`, data, {
      skipGenericErrorHandling: true,
    });
  }

  async oauthVerification({ provider, ...data }) {
    return await API.client.execute(POST, `${servicePath}oauth/token/${provider}`, data, {
      skipGenericErrorHandling: true,
    });
  }

  async contactUs(data) {
    return await API.client.execute(POST, `${servicePath}contact-us`, data, {
      skipGenericErrorHandling: true,
    });
  }

  async authContext() {
    return await API.client.execute(GET, `${servicePath}context`, null, {
      skipGenericErrorHandling: true,
    });
  }

  async activateOTPAccount({ email, organizationId }) {
    return await API.client.execute(
      POST,
      `${servicePath}activate-account`,
      { email, organizationId },
      {
        skipGenericErrorHandling: true,
      },
    );
  }

  async verifyActivateAccount({ email, code }) {
    return await API.client.execute(
      POST,
      `${servicePath}activate-account/verify`,
      { email, code },
      { skipGenericErrorHandling: true },
    );
  }

  async checkAvailabilityByRegion({ email }) {
    return await Promise.all(
      Object.values(API.executers).map(
        async client =>
          await client.execute(
            POST,
            `${servicePath}check`,
            { email },
            {
              skipGenericErrorHandling: true,
            },
          ),
      ),
    );
  }

  async refreshToken(data) {
    return await API.client.execute(POST, `${servicePath}refresh-token`, data, {
      skipGenericErrorHandling: true,
    });
  }

  async activateOAuthAccount({ provider, ...body }) {
    return await API.client.execute(POST, `${servicePath}activate-oauth/${provider}`, body, {
      skipGenericErrorHandling: true,
    });
  }

  async logout() {
    return await API.client.execute(
      POST,
      `${servicePath}logout`,
      {},
      {
        skipGenericErrorHandling: true,
      },
    );
  }

  async switchOrganization({ id }) {
    return await API.client.execute(
      POST,
      `${servicePath}switch-organization/${id}`,
      {},
      {
        skipGenericErrorHandling: true,
      },
    );
  }

  async exchangeAPIKeyWithToken({ apiKey }) {
    return await API.client.execute(
      POST,
      `${servicePath}api-key/exchange`,
      {},
      {
        skipGenericErrorHandling: true,
        headers: {
          'X-API-Key': apiKey,
        },
      },
    );
  }
}

export default new AuthenticationServiceAPI();
