import { EVENTS_BY_ACTIONS_MAP, SESSION_PROPERTIES } from 'shared/constants';
import { ORGANIZATION_UPDATE, FIRST_TIME } from 'shared/store/actions';
import AnalyticsService from '../../helpers/analytics.service';

export const analyticsByAction = () => next => async action => {
  next(action);

  if (EVENTS_BY_ACTIONS_MAP[action.type]) {
    AnalyticsService.track(EVENTS_BY_ACTIONS_MAP[action.type]);
  }
};

export const analyticsEnricher = () => next => async action => {
  next(action);

  if (action.type === FIRST_TIME) {
    AnalyticsService.setSessionProperty(SESSION_PROPERTIES.IS_FIRST_TIME, true);
  }
};

export const analyticsAddOrgId = () => next => async action => {
  next(action);

  if (action.type === ORGANIZATION_UPDATE) {
    AnalyticsService.setGroup({
      key: 'organization',
      value: action.payload?.name,
    });
    AnalyticsService.setGroup({
      key: 'organizationId',
      value: action.payload?.id,
    });
    AnalyticsService.setGroup({
      key: 'organizationClassification',
      value: action.payload?.classification,
    });
  }
};

export const analyticsMiddleware = [analyticsByAction, analyticsEnricher, analyticsAddOrgId];
