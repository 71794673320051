import { createReducer } from 'shared/utils/redux/reducers.utils';
import { INITIAL_STATE } from 'shared/store/actions';
import { REGISTER_API_KEY } from '../actions';
import update from 'immutability-helper';

const initialState = {
  apiKey: null,
};

const registerApiKey = (state, { apiKey }) =>
  update(state, {
    $merge: {
      apiKey,
    },
  });

const loadInitialState = (state, { auth = {} }) =>
  update(state, {
    $merge: auth,
  });

const actionsRepo = {
  [INITIAL_STATE]: loadInitialState,
  [REGISTER_API_KEY]: registerApiKey,
};

export default createReducer({ initialState, actionsRepo });
