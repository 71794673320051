import React, { useEffect } from 'react';
import { ArrowLeftIcon, NewTabIcon, Tile } from '../../../images';
import styles from './knowledge-content.module.scss';
import { appURL } from 'shared/constants/APIconstants';
import { Redirect } from '../../redirect';
import { Lang } from 'shared/constants';
import contentStyles from '../../../styles/content-style.module.scss';
import { TagWithIcon } from '../../tags';
import { sanitizeHtmlContent } from 'shared/utils/Utils';
import { WIDGET_REDIRECT_QUERY_PARAM } from 'shared/constants';
import { useUpdatedByInfo } from 'shared/hooks/knowledge.hooks';
import { useContributors } from 'shared/hooks/knowledge-content.hooks';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import { formatFullName } from 'shared/utils';

const KnowledgeContentComponent = React.memo(function KnowledgeContentComponent({
  id,
  title,
  subtitle,
  tags,
  owners,
  link,
  fileUpdatedAt,
  updatedAt,
  updatedBy,
  content = null,
  fetchKnowledgeItemContent,
  close,
  lang = Lang.WIDGET,
  langTags = Lang.TAGS,
  langTagsPlaceholder = Lang.TAGS_FORM,
  langEditor = Lang.KNOWLEDGE_EDITOR,
  contributors = [],
}) {
  const appLink = `${appURL}knowledge/items/${id}?${WIDGET_REDIRECT_QUERY_PARAM}`;
  const { formatLongDate } = useDateFormatter();
  const getTagLabel = tag => langTags[tag];
  const validatedOnDate = formatLongDate(fileUpdatedAt || updatedAt);

  const { updatedByName, updatedByEmail } = useUpdatedByInfo({
    updatedBy,
  });

  const updatedAtDate = formatLongDate(updatedAt);

  const { allContributors } = useContributors({ contributors });

  useEffect(() => {
    const fetchContent = async () => {
      if (!content && !!id) {
        await fetchKnowledgeItemContent({ id });
      }
    };
    fetchContent();
  }, [fetchKnowledgeItemContent, id, content]);

  const renderTags = () => {
    if (!tags?.specialty && !tags?.subspecialty) return;

    return (
      <div className={styles.tags}>
        {tags.specialty && (
          <div className={styles.tagContainer}>
            <h6>{langTagsPlaceholder.SPECIALTY_PLACEHOLDER}</h6>
            <TagWithIcon tag={tags.specialty} />
          </div>
        )}
        {tags.subspecialty && (
          <div className={styles.tagContainer}>
            <h6>{langTagsPlaceholder.SUBSPECIALTY_PLACEHOLDER}</h6>
            <TagWithIcon tag={tags.subspecialty} />
          </div>
        )}
      </div>
    );
  };
  return (
    <div className={styles.root}>
      <div
        className={styles.header}
        style={{
          backgroundImage: `url(${Tile})`,
        }}>
        <div className={styles.buttons}>
          <img className={styles.back} src={ArrowLeftIcon} onClick={close} alt={'Back'} />
          <div className={styles.actionButtons}>
            {link && (
              <a href={link} className={styles.openOriginal} target="_blank" rel="noreferrer">
                <span>{lang.KNOWLEDGE_OPEN_ORIGINAL}</span>
                <img
                  src={NewTabIcon}
                  className={styles.openOriginalIcon}
                  alt={lang.KNOWLEDGE_OPEN_ORIGINAL}
                />
              </a>
            )}
            <Redirect text={lang.KNOWLEDGE_REDIRECT} link={appLink} />
          </div>
        </div>
        {tags.contentType && (
          <div className={styles.contentType}>{getTagLabel(tags.contentType)}</div>
        )}
        <h1 className={styles.title}>{title}</h1>
        <h4 className={styles.subtitle}>{subtitle}</h4>
        {owners.length > 0 && (
          <h6 className={styles.metadata}>
            {`${langEditor.VALIDATED_BY} `}
            {owners.map((owner, index) => (
              <>
                {index > 0 && ', '}
                <a href={`mailto:${owner.email}`} target="_blank" rel="noreferrer">
                  {formatFullName(owner)}
                </a>
              </>
            ))}
            {langEditor.ON_DATE.replace('{date}', validatedOnDate)}
          </h6>
        )}
        {allContributors.length > 0 && (
          <div className={styles.metadata}>
            {`${langEditor.CONTRIBUTORS}: `}
            {allContributors.map((contributor, index) => {
              const separator = index === allContributors.length - 1 ? '' : ', ';
              return contributor.email ? (
                <span key={index}>
                  <a href={`mailto:${contributor.email}`} target="_blank" rel="noreferrer">
                    {contributor.displayName}
                  </a>
                  {separator}
                </span>
              ) : (
                <span key={index}>
                  {contributor.displayName}
                  {separator}
                </span>
              );
            })}
          </div>
        )}
        {updatedBy && (
          <h6 className={styles.metadata}>
            {langEditor.UPDATED_BY}
            <a href={`mailto:${updatedByEmail}`} target="_blank" rel="noreferrer">
              {updatedByName}
            </a>
            {langEditor.ON_DATE.replace('{date}', updatedAtDate)}
          </h6>
        )}
        {renderTags()}
      </div>
      <div
        className={contentStyles.contentContainer}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtmlContent(content),
        }}
      />
    </div>
  );
});

export { KnowledgeContentComponent };
