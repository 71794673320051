import { LAB_UPDATE_CONTENT, LAB_USE_CACHE } from '.';
import labServiceApi from '../../api/lab.service.api';
import { isConnected } from '../selectors/network.selector';

export const getLabData = () => async (dispatch, getState) => {
  let state = getState();

  if (!isConnected(state)) {
    return dispatch({
      type: LAB_USE_CACHE,
    });
  }

  let [itemsRes, categoriesRes] = await Promise.all([
    labServiceApi.getValues(),
    labServiceApi.getCategories(),
  ]);

  if (itemsRes.error || categoriesRes.error) {
    return dispatch({
      type: LAB_USE_CACHE,
    });
  }

  let { categories = [] } = categoriesRes.data || {};

  let categoriesById = {};

  categories.forEach(category => (categoriesById[category.id] = category));

  let { values: items = [] } = itemsRes.data || {};

  let itemsById = {};

  items.forEach(item => (itemsById[item.id] = item));

  dispatch({
    type: LAB_UPDATE_CONTENT,
    payload: {
      items,
      itemsById,
      categories,
      categoriesById,
    },
  });
};
