import { connect } from 'react-redux';
import { ReportProblemComponent } from './report-problem.component';
import { giveFeedback } from 'shared/store/actions/communication-center.actions';
import { Lang } from 'shared/constants';

const mapStateToProps = ({ lang }) => {
  return {
    lang: lang.REPORT_PROBLEM || Lang.REPORT_PROBLEM,
  };
};

export const ReportProblem = connect(mapStateToProps, { giveFeedback })(ReportProblemComponent);
