import { REGISTER_API_KEY } from '.';
import authenticationServiceApi from 'shared/api/authentication.service.api';
import API from 'shared/api/API';
import jwtDecode from 'jwt-decode';

export const exchangeAPIKeyAction =
  ({ apiKey }) =>
  async dispatch => {
    const { data, error } = await authenticationServiceApi.exchangeAPIKeyWithToken({ apiKey });

    if (error) {
      return {
        error: {
          code: error.response.data.code,
        },
      };
    }

    API.setToken(data.accessToken, true);

    // Check token expiration time and refresh it if needed (3 hours before expiration)
    const { exp, id } = jwtDecode(data.accessToken);

    const expirationTime = exp * 1000;
    const currentTime = Date.now();
    const timeToRefresh = expirationTime - currentTime - 3 * 60 * 60 * 1000;

    setTimeout(() => {
      dispatch(exchangeAPIKeyAction({ apiKey }));
    }, timeToRefresh);

    return { userId: id };
  };

export const registerApiKey =
  ({ apiKey }) =>
  async dispatch => {
    const res = await dispatch(exchangeAPIKeyAction({ apiKey }));

    dispatch({
      type: REGISTER_API_KEY,
      payload: { apiKey },
    });

    return res;
  };
