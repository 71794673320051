import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { APPLICATION_WIPE_DATA, AI_UPDATE_STATE } from '../actions';

const initialState = {
  isChatOpen: false,
};

const updateChatState = (state, { isChatOpen }) =>
  update(state, {
    $merge: {
      isChatOpen: isChatOpen,
    },
  });

const cleanData = () => initialState;

const actionsRepo = {
  [AI_UPDATE_STATE]: updateChatState,
  [APPLICATION_WIPE_DATA]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
