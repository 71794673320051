import { APPLICATION_STEPS } from '../../constants';
import update from 'immutability-helper';
import _ from 'lodash';
import { APPLICATION_SET_ADMIN_MODE, APP_FINISH_STEP, APP_IS_READY, AUTH_LOGOUT } from '../actions';
import { createReducer } from '../../utils/redux/reducers.utils';

const initialState = {
  isReady: false,
  adminMode: false,
  parts: {
    [APPLICATION_STEPS.PERSIST_STORAGE]: false,
    [APPLICATION_STEPS.AUTHENTICATION_PROCESS]: false,
  },
};

const finishStep = (state, { step }) => {
  if (_.isUndefined(APPLICATION_STEPS[step])) {
    throw new Error(`[App Reducer] non exist step - ${step}`);
  }

  return update(state, {
    parts: {
      [step]: { $set: true },
    },
  });
};

const setAppReady = state =>
  update(state, {
    isReady: { $set: true },
  });

const setAdminMode = (state, payload) =>
  update(state, {
    adminMode: { $set: payload.state },
  });

const resetAdminMode = state => {
  return update(state, {
    adminMode: { $set: initialState.adminMode },
  });
};

const actionsRepo = {
  [APP_FINISH_STEP]: finishStep,
  [APP_IS_READY]: setAppReady,
  [APPLICATION_SET_ADMIN_MODE]: setAdminMode,
  [AUTH_LOGOUT]: resetAdminMode,
};

export default createReducer({ initialState, actionsRepo });
