import update from 'immutability-helper';
import { REMOTE_CONFIG_DEFAULT, REMOTE_CONFIG_KEYS } from '../../constants';
import { createReducer } from '../../utils/redux/reducers.utils';
import { MAINTENANCE_STATUS_UPDATE } from '../actions';

const initialState = REMOTE_CONFIG_DEFAULT[REMOTE_CONFIG_KEYS.MAINTENANCE];

const updateMaintenanceStatus = (state, payload) =>
  update(state, {
    $merge: payload,
  });

const actionsRepo = {
  [MAINTENANCE_STATUS_UPDATE]: updateMaintenanceStatus,
};

export default createReducer({ initialState, actionsRepo });
