import { currentEnvironment } from '../services/Environment';
import { ENVIRONMENT_KEYS } from './environment';

export const NOTIFICATIONS_MAX_DAYS_WITHOUT_PROMPT_MS = 259200000;

export const NOTIFICATION_APP_ID_BY_ENV = {
  [ENVIRONMENT_KEYS.PRODUCTION]: 'f433a23d-6457-4118-b090-be89e1812023',
  [ENVIRONMENT_KEYS.STAGING]: '5ef947ce-2124-40e5-96dd-d471d42a4013',
  [ENVIRONMENT_KEYS.DEVELOPMENT]: '5ef947ce-2124-40e5-96dd-d471d42a4013',
};

export const NOTIFICATION_APP_ID = NOTIFICATION_APP_ID_BY_ENV[currentEnvironment()];
