import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { getDrugClassIcon } from '../../constants/drugClassIconsMapper';
import { ArrowIcon, CompendiumIcon } from '../../images';
import { Tags } from '../tags';
import sharedStyles from './items.module.scss';
import styles from './compendium-item.module.scss';
import classnames from 'classnames';
import { CompendiumPreview } from './compendium-preview/compendium-preview';
import analyticsService from '../../helpers/analytics.service';
import { EVENTS, ENTITY_TYPES } from 'shared/constants';

const MAX_CONTENT_LINES = 4;

const CompendiumItemComponent = React.memo(function CompendiumItemComponent({
  id,
  title,
  tags,
  value,
  drugClass,
  ...props
}) {
  const ref = useRef(null);
  const [valueHeight, setValueHeight] = useState(0);
  const isLargeValue = valueHeight > styles.maxHeight.replace('px', '');
  const [showPreview, setShowPreview] = useState(false);
  const [valueToDisplay, setValueToDisplay] = useState(value);

  useEffect(() => {
    if (isLargeValue) {
      const textToDisplay =
        value.split('\n\n').slice(0, MAX_CONTENT_LINES).join('\n\n') + '\n\n...';

      setValueToDisplay(textToDisplay);
    } else {
      setValueToDisplay(value);
    }
  }, [value, isLargeValue]);

  useEffect(() => {
    setValueHeight(ref.current.clientHeight);
  }, [value, setValueHeight]);

  const onOpen = useCallback(() => {
    const EVENT_DATA = {
      id,
      title,
      ...tags,
    };

    analyticsService.track(EVENTS.LAB_ITEMS.OPEN, EVENT_DATA);

    analyticsService.track(EVENTS.CONTENT.OPEN, {
      ...EVENT_DATA,
      entityType: ENTITY_TYPES.compendium,
    });
  }, [id, title, tags]);

  const openPreview = useCallback(() => {
    setShowPreview(true);
    onOpen();
  }, [setShowPreview, onOpen]);

  const closePreview = useCallback(() => {
    setShowPreview(false);
  }, [setShowPreview]);

  return (
    <>
      <div
        key={id}
        className={classnames([sharedStyles.item, isLargeValue && sharedStyles.clickable])}
        onClick={isLargeValue ? openPreview : null}>
        <div className={sharedStyles.wrapper}>
          <img className={sharedStyles.icon} src={CompendiumIcon} alt={'compendium'} />
          <div>
            <h4>{title}</h4>
            <div
              ref={ref}
              className={classnames([styles.value, isLargeValue && styles.largeValue])}>
              <ReactMarkdown className={classnames([isLargeValue && styles.expandContent])}>
                {valueToDisplay}
              </ReactMarkdown>
            </div>
            <Tags tags={tags} />
          </div>
        </div>
        <div className={styles.rightSideIcons}>
          {drugClass && (
            <img
              className={styles.drugClassIcon}
              src={getDrugClassIcon(drugClass)}
              alt={drugClass}
            />
          )}
          {isLargeValue && (
            <div className={styles.expand}>
              <p className={styles.expandText}>Read More...</p>
              <img className={sharedStyles.arrow} src={ArrowIcon} alt={title} />
            </div>
          )}
        </div>
      </div>
      {showPreview && (
        <CompendiumPreview
          {...{ id, title, tags, value, drugClass, ...props }}
          close={closePreview}
        />
      )}
    </>
  );
});

export { CompendiumItemComponent };
