import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { AUTH_LOGOUT, INITIAL_STATE, ORGANIZATION_UPDATE } from '../actions';

const initialState = {};

const updateOrganization = (state, organizationData) =>
  update(state, {
    $set: organizationData,
  });

const loadInitialState = (state, { organization = initialState }) =>
  update(state, {
    $merge: organization,
  });

const authLogout = () => {
  return initialState;
};

const actionsRepo = {
  [ORGANIZATION_UPDATE]: updateOrganization,
  [INITIAL_STATE]: loadInitialState,
  [AUTH_LOGOUT]: authLogout,
};

export default createReducer({ initialState, actionsRepo });
