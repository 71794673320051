import { INITIAL_STATE, LOAD_STORAGE, UPDATE_STORAGE } from '.';
import { debounce } from 'lodash';
import { STORAGE_REDUCERS_DATA_TO_ELIMINATE, STORAGE_REDUCERS_TO_SAVE } from '../../constants';

export const loadState = () => ({
  type: LOAD_STORAGE,
});

const updateStateToStorageAction = debounce((dispatch, getState) => {
  const state = getState();

  let updatedData = {};

  // Map the reducers to the state
  STORAGE_REDUCERS_TO_SAVE.forEach(reducer => {
    const eliminators = STORAGE_REDUCERS_DATA_TO_ELIMINATE[reducer];
    let data;

    if (eliminators) {
      data = { ...state[reducer] };

      const keys = Object.keys(eliminators);
      keys.forEach(key => {
        if (eliminators[key] === true || eliminators[key][global.platform]) {
          delete data[key];
          return true;
        }
      });
    } else {
      data = state[reducer];
    }

    updatedData[reducer] = data;
  });

  dispatch({
    type: UPDATE_STORAGE,
    payload: updatedData,
  });
}, 500);

export const updateStateToStorage = () => (dispatch, getState) => {
  updateStateToStorageAction(dispatch, getState);
};

export const applyInitialState = payload => ({
  type: INITIAL_STATE,
  payload,
});
