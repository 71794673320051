import { connect } from 'react-redux';
import { updateSearch } from 'shared/store/actions/search.actions';
import { SearchComponent } from './search.component';

const mapStateToProps = ({ search, lang }) => ({
  searchTerm: search.term,
  lang: lang.WIDGET,
});

export const Search = connect(mapStateToProps, { updateSearch })(SearchComponent);
