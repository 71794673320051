import React, { useCallback, useContext } from 'react';
import { KNOWLEDGE_TYPES } from 'shared/constants/knowledge.constants';
import {
  DocumentIcon,
  ImageIcon,
  ItemBinderIcon,
  PresentationIcon,
  VideoIcon,
  WebsiteLinkIcon,
} from '../../images';
import { Tags } from '../tags';
import sharedStyles from './items.module.scss';
import classnames from 'classnames';
import analyticsService from '../../helpers/analytics.service';
import { EVENTS, ENTITY_TYPES, WIDGET_REDIRECT_QUERY_PARAM } from 'shared/constants';
import classNames from 'classnames';
import { isVideoUrl } from 'shared/utils';
import ApplicationContext from '../../context/application.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faChevronRight, faTag } from '@fortawesome/free-solid-svg-icons';
import { appURL } from 'shared/constants/APIconstants';

const TYPE_TO_ICON_MAPPER = {
  content: DocumentIcon,
  pdf: DocumentIcon,
  document: DocumentIcon,
  image: ImageIcon,
  video: VideoIcon,
  ppt: PresentationIcon,
  'external-link': WebsiteLinkIcon,
};

const KnowledgeItemComponent = React.memo(function KnowledgeItemComponent({
  item = {},
  knowledgeShowItemContent,
  itemStyle = 'regular',
  onClickEvent = () => {},
  knowledgeShowKeywords,
}) {
  const { id, title, subtitle, tags, type, link } = item;
  const isContent = type === KNOWLEDGE_TYPES.CONTENT;
  const isVideo = isVideoUrl(link);
  const Icon = isVideo
    ? TYPE_TO_ICON_MAPPER[KNOWLEDGE_TYPES.VIDEO]
    : TYPE_TO_ICON_MAPPER[type] || ItemBinderIcon;

  const { showTags } = useContext(ApplicationContext);

  const onOpen = useCallback(() => {
    const event = {
      id,
      title,
      type,
      ...tags,
    };

    // Trigger event
    onClickEvent(event);

    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.OPEN, event);
    analyticsService.track(EVENTS.CONTENT.OPEN, {
      ...event,
      entityType: ENTITY_TYPES.knowledge,
    });
  }, [id, title, type, tags, onClickEvent]);

  const openContent = useCallback(() => {
    knowledgeShowItemContent(id);
    onOpen();
  }, [knowledgeShowItemContent, id, onOpen]);

  const redirectToApp = useCallback(() => {
    const link = `${appURL}knowledge/items/${id}?${WIDGET_REDIRECT_QUERY_PARAM}`;
    onOpen();
    window.open(link, '_blank');
  }, [id, onOpen]);

  const openOptions = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      knowledgeShowKeywords(id);
    },
    [knowledgeShowKeywords, id],
  );

  const renderItem = () => (
    <div
      key={id}
      className={classnames([sharedStyles.item, sharedStyles.clickable, sharedStyles[itemStyle]])}
      onClick={isContent ? openContent : redirectToApp}>
      <div className={classNames([sharedStyles.wrapper, showTags && sharedStyles.withTags])}>
        <img className={sharedStyles.icon} src={Icon} alt={'knowledge'} />
        <div>
          <h4>{title}</h4>
          {showTags && (
            <>
              <h5>{subtitle}</h5>
              <Tags tags={tags} />
            </>
          )}
        </div>
      </div>
      <div className={sharedStyles.actionIconsWrapper}>
        <FontAwesomeIcon
          icon={faTag}
          className={classnames(sharedStyles.actionIcon, sharedStyles.clickable)}
          onClick={openOptions}
        />
        <FontAwesomeIcon
          icon={isContent ? faChevronRight : faArrowUpRightFromSquare}
          className={sharedStyles.actionIcon}
        />
      </div>
    </div>
  );

  const render = () => {
    if (!id) {
      return null;
    }
    return renderItem();
  };

  return render();
});

export { KnowledgeItemComponent };
