import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { AUTH_LOGOUT, AVAILABLE_ORGANIZATIONS_UPDATE } from '../actions';

const initialState = {
  organizations: [],
};

const updateOrganization = (state, { organizations }) =>
  update(state, {
    $merge: { organizations },
  });

const authLogout = () => {
  return initialState;
};

const actionsRepo = {
  [AVAILABLE_ORGANIZATIONS_UPDATE]: updateOrganization,
  [AUTH_LOGOUT]: authLogout,
};

export default createReducer({ initialState, actionsRepo });
