import { Lang } from 'shared/constants';
import { PandaClosesEyes } from 'shared/images/characters';
import styles from './not-allowed.module.scss';

/**
 * @param {{lang: object}} param0
 */
export function NotAllowed({ lang = Lang.WIDGET }) {
  return (
    <div className={styles.container}>
      <img src={PandaClosesEyes} alt="panda close eyes" />
      <h1>{lang.IP_NOT_ALLOWED}</h1>
      <p>{lang.TRY_SWITCH_NETWORK}</p>
    </div>
  );
}
