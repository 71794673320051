import update from 'immutability-helper';
import { SHOW_REPORT_PROBLEM_VIEW } from '../actions';
import { createReducer } from 'shared/utils/redux/reducers.utils';

const initialState = {
  active: false,
};

const showReportProblem = (state, { active = false }) => {
  return update(state, {
    $merge: { active },
  });
};

const actionsRepo = {
  [SHOW_REPORT_PROBLEM_VIEW]: showReportProblem,
};

export default createReducer({ initialState, actionsRepo });
