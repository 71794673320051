export const WARNING_ABOUT_TO_EXPIRY_IN_DAYS = 7;
export const KNOWLEDGE_HIDE_EXPIRED_CONTENT_AFTER_IN_DAYS = 31;
export const KNOWLEDGE_TO_BE_EXPIRED_ITEMS_IN_DAYS = 15;

export const DEFAULT_CONTENT_TAGS = [
  'covid',
  'ventilation',
  'coronavirus',
  'enfant',
  'natel',
  'platre',
  'telephone',
  'corona',
  'osteo',
  'dpi',
  'SARS-CoV-2',
  'enfants',
  'pédiatrie',
  'articulaire',
  'virus',
  'sars',
  'livret',
  'hémodialyse',
  'tutoriaux',
  'pain',
  'DPI',
  'dialyse',
  'prise en charge',
  'masque',
  'sédation',
  'paludisme',
  "salle d'accouchement",
  'anesthesie',
  'nouveau-né',
  'bipap',
  'dossier',
  'ketamine',
  'Rein',
  'tete',
  'cortico',
  'isolement',
  'test',
  'suture',
  'contention',
];

export const MOBILE_MAX_ITEMS_PER_LINE_TO_BREAK = 3;
