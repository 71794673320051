import { APP_IS_READY, KNOWLEDGE_UPDATE_CONTENT } from '../actions';
import { DEPP_LINK_ACTIONS_KNOWLEDGE_READY } from '../actions/deep-linking.actions';

export const deepLinkingTriggerOnAppReadyMiddleware =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    next(action);

    if (action.type === APP_IS_READY) {
      // dispatch(updateStateToStorage());
    }
  };

export const deepLinkingTriggerOnKnowledgeReadyMiddleware =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    next(action);

    if (action.type === KNOWLEDGE_UPDATE_CONTENT) {
      // dispatch(updateStateToStorage());
      let { action, ...payload } = getState().deepLinking;
      if (action && DEPP_LINK_ACTIONS_KNOWLEDGE_READY[action]) {
        dispatch(DEPP_LINK_ACTIONS_KNOWLEDGE_READY[action](payload));
      }
    }
  };

export const deepLinkingMiddleware = [
  deepLinkingTriggerOnAppReadyMiddleware,
  deepLinkingTriggerOnKnowledgeReadyMiddleware,
];
