import API from './API';
import { GET } from '../utils/Executer';
import { LABEL_SERVICE_URL as servicePath } from '../constants/APIconstants';

class LabelsServiceAPI {
  async getLabels() {
    return await API.client.execute(GET, servicePath);
  }
}

export default new LabelsServiceAPI();
