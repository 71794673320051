import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { DPLNK_INIT } from '../actions';

const initialState = {};

const setDeepLink = (state, payload) =>
  update(state, {
    $merge: payload,
  });

const actionsRepo = {
  [DPLNK_INIT]: setDeepLink,
};

export default createReducer({ initialState, actionsRepo });
