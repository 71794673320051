import { FS_ALLOW_EXT } from '../../constants';
import _ from 'lodash';

export const getFileExt = ({ url }) => {
  if (!url) return '';
  let splitted = url.split('.');
  return splitted[splitted.length - 1].toLowerCase();
};

export const getFileName = ({ url }) => {
  let splitted = url.split('/');
  return splitted[splitted.length - 1].toLowerCase();
};

export const isRelativePath = ({ url }) => {
  let splitted = url.split('/');
  return splitted.length === 1;
};

export const isSupportedExt = ({ url }) => {
  return FS_ALLOW_EXT.includes(getFileExt({ url }));
};

export const isDownloaded = (fsState, { id, oldId }) => {
  return !_.isUndefined(fsState.map[oldId || id]);
};

export const getLocalFile = (fsState, { id, oldId }) => {
  return !_.isUndefined(fsState.map[oldId || id]) ? fsState.map[oldId || id] : null;
};
