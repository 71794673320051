import { createReducer } from 'shared/utils/redux/reducers.utils';
import { KNOWLEDGE_SELECT_ITEM } from 'shared/store/actions';
import update from 'immutability-helper';
import { KNOWLEDGE_SHOW_ITEM_CONTENT } from '../actions';

const initialState = {
  id: null,
  itemId: null,
};

const selectItem = (state, { id = null }) => {
  return update(state, {
    $merge: { id },
  });
};

const showItemContent = (state, { itemId = null }) => {
  return update(state, {
    $merge: { itemId },
  });
};

const actionsRepo = {
  [KNOWLEDGE_SELECT_ITEM]: selectItem,
  [KNOWLEDGE_SHOW_ITEM_CONTENT]: showItemContent,
};

export default createReducer({ initialState, actionsRepo });
