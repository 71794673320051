import styles from './not-found.module.scss';
import { Lang } from 'shared/constants';
import { useEffect } from 'react';
import analyticsService from '../../helpers/analytics.service';
import { EVENTS } from 'shared/constants/analytics';

const NotFoundComponent = ({ searchTerm = '', lang = Lang.WIDGET }) => {
  useEffect(() => {
    analyticsService.track(EVENTS.SEARCH.NOT_FOUND, {
      searchTerm,
    });
  });

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.emoji}>🤔</h3>
      <h3>{lang.NOT_FOUND_TITLE}</h3>
      <h4>{lang.NOT_FOUND_SUBTITLE.replace('{searchTerm}', searchTerm)}</h4>
      <h5>{lang.NOT_FOUND_DESCRIPTION}</h5>
    </div>
  );
};
export { NotFoundComponent };
