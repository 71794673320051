import React from 'react';
import { ContactsIcon } from '../../images';
import sharedStyles from './items.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPager, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { formatFullName } from 'shared/utils';

library.add(faPager, faPhone, faEnvelope);

const ContactItemComponent = React.memo(function ContactItemComponent({
  id,
  firstName,
  lastName,
  department,
  email,
  phone,
  pager,
  cisco,
}) {
  const renderDetail = ({ detail, icon, isLast }, i) => {
    if (detail) {
      return (
        <div className={sharedStyles.wrapper} key={i}>
          <FontAwesomeIcon icon={icon} className={sharedStyles.icon} />
          <h5>{detail}</h5>
          {!isLast && <h5 className={sharedStyles.delimiter}>|</h5>}
        </div>
      );
    }

    return null;
  };

  const contactDetails = [
    { detail: phone, icon: 'phone' },
    { detail: pager, icon: 'pager' },
    { detail: cisco, icon: 'pager' },
    { detail: email, icon: 'envelope' },
  ];

  return (
    <div key={id} className={sharedStyles.item}>
      <div className={sharedStyles.wrapper}>
        <img
          className={classNames([sharedStyles.icon, sharedStyles.contactIcon])}
          src={ContactsIcon}
          alt="contact"
        />

        <div className={sharedStyles.lineWrapper}>
          <h4>
            {formatFullName({ firstName, lastName, email })} ${department ? `(${department})` : ''}
          </h4>
          <div className={classNames(sharedStyles.wrapper, sharedStyles.value)}>
            {contactDetails
              .filter(({ detail }) => detail !== undefined)
              .map(({ detail, icon }, i, arr) =>
                renderDetail({ detail, icon, isLast: i === arr.length - 1 }, i),
              )}
          </div>
        </div>
      </div>
    </div>
  );
});

export { ContactItemComponent };
