import API from './API';
import { POST } from '../utils/Executer';
import { ANALYTICS_SERVICE_URL as servicePath } from '../constants/APIconstants';

class AnalyticsServiceAPI {
  /**
   * Track an event
   * @param {{
   *  event: string,
   *  properties: { [key: string]: any }
   * }} { event, eventProperties }
   * @return {*}
   * @memberof AnalyticsServiceAPI
   */
  async trackEvent(payload) {
    return await API.client.execute(POST, `${servicePath}events`, payload, {
      skipGenericErrorHandling: true,
    });
  }
}

export default new AnalyticsServiceAPI();
