import {
  APP_IS_READY,
  AUTH_LOGIN,
  ORGANIZATION_UPDATE,
  ORGANIZATION_SWITCH,
  APPLICATION_WIPE_DATA,
  AUTH_UPDATE_TOKEN,
  PROFILE_COMPLETED,
  NOTIFICATION_PROMPT_CLOSED,
} from '../actions';
import { updateOrganization, organizationAttachId } from '../actions/organization.actions';
import userService from '../../services/user.service';
import { updateToken } from '../actions/auth.actions';
import { needToUpdateProfile } from '../selectors/user.selector';
import { navigate } from '../../services/navigation.service';
import { ROUTES } from '../../constants';

export const onAuthenticationProcessDone =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(updateOrganization());
    }
  };

export const onSelectInitialOrganization =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === PROFILE_COMPLETED ||
      action.type === NOTIFICATION_PROMPT_CLOSED
    ) {
      if (!userService.isAuthenticated) return false;
      if (needToUpdateProfile(getState())) return false;

      // delay to make sure router is ready
      setTimeout(() => {
        navigate(ROUTES.SWITCH_ORGANIZATION);
      }, 0);
    }
  };

export const onOrganizationUpdate =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === ORGANIZATION_UPDATE) {
      dispatch(organizationAttachId({ id: action.payload?.id }));
    }
  };

export const onOrganizationSwitched =
  ({ dispatch }) =>
  next =>
  async action => {
    if (action.type === ORGANIZATION_SWITCH) {
      dispatch({ type: APPLICATION_WIPE_DATA });
      dispatch(
        updateToken({
          token: action.payload?.token,
          accessToken: action.payload?.accessToken,
          refreshToken: action.payload?.refreshToken,
        }),
      );
    }

    next(action);
  };

export const organizationMiddleware = [
  onSelectInitialOrganization,
  onAuthenticationProcessDone,
  onOrganizationUpdate,
  onOrganizationSwitched,
];
