import { connect } from 'react-redux';
import { WidgetComponent } from './widget.component';
import { getRoot } from 'shared/store/selectors/knowledge.selector';
import { knowledgeShowItemContent } from '../../store/actions/knowledge-viewer.actions';
import { knowledgeShowKeywords } from '../../store/actions/keywords.actions';
import { showReportProblem } from '../../store/actions/report-problem.actions';

const mapStateToProps = ({
  search,
  knowledge,
  organization,
  lang,
  knowledgeViewer,
  keywordsViewer,
  reportProblemViewer,
}) => {
  const knowledgeRoot = getRoot(knowledge);

  return {
    searchTerm: search.term,
    results: search.results,
    sessionId: search.sessionId,
    showKnowledgeBase: knowledgeRoot?.id,
    lang: lang.WIDGET,
    organization,
    knowledgeItemId: knowledgeViewer?.itemId,
    keywordsItemId: keywordsViewer?.id,
    isReportProblem: reportProblemViewer.active,
  };
};

export const Widget = connect(mapStateToProps, {
  knowledgeShowItemContent,
  knowledgeShowKeywords,
  reportProblem: showReportProblem,
})(WidgetComponent);
