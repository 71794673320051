import userService from '../../services/user.service';
import { APPLICATION_REFRESH_DATE, APP_IS_READY, AUTH_LOGIN, AUTH_UPDATE_TOKEN } from '../actions';
import { getLabels } from '../actions/labels.actions';

export const labelsFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(getLabels());
    }
  };

export const labelsMiddleware = [labelsFetchDataMiddleware];
