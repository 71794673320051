import { DEFAULT_CONTENT_TAGS } from 'shared/constants';
import { useEffect, useState } from 'react';
import organizationServiceApi from 'shared/api/organization.service.api';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getEmailsForContributors } from '../store/selectors/contact.selector';
import { getLang } from '../store/selectors/lang.selector';

/**
 * Get suggested keywords and functions to update, reset and search keywords
 * @param {Object} { keywords } - knowledge item keywords or empty array
 * @returns {Object} { suggestedKeywords, newKeywords, updateKeywords, resetKeywords, searchKeywords, searchValue }
 */
export const useKeywords = ({ keywords = [] }) => {
  const [organizationKeywords, setOrganizationKeywords] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [newKeywords, setNewKeywords] = useState([]);
  const [search, setSearch] = useState('');

  const fetchKeywords = async () => {
    const { data } = await organizationServiceApi.getKeywords();
    setOrganizationKeywords(_.uniq([...(data.keywords ?? []), ...DEFAULT_CONTENT_TAGS]));
  };

  useEffect(() => {
    fetchKeywords();
  }, []);

  useEffect(() => {
    const suggestedKeywords = organizationKeywords
      .filter(word => !keywords.includes(word))
      .filter(word => !newKeywords.includes(word))
      .filter(word => search === '' || word.toLowerCase().includes(search.toLowerCase()));

    setFilteredKeywords(suggestedKeywords);
    // eslint-disable-next-line
  }, [newKeywords, keywords.length, search, organizationKeywords]);

  const updateKeywords = words => {
    setNewKeywords(_.uniq([...words]));
    setSearch('');
  };

  const resetKeywords = () => setNewKeywords([]);

  const searchKeywords = value => {
    setSearch(value);
  };

  return {
    suggestedKeywords: filteredKeywords,
    newKeywords,
    updateKeywords,
    resetKeywords,
    searchKeywords,
    searchValue: search,
  };
};

/**
 * @typedef {{
 *  displayName: string,
 *  containerId?: string,
 *  userId?: string,
 *  mentionedBy: string }} Contributor
 */

/**
 * Get contributors with emails for internal contributors and the language for the component
 * @param {Contributor[]} param0
 * @returns {{ allContributors: ({ email?: string } & Contributor) [], lang: Object }}
 */
export function useContributors({ contributors }) {
  const allContributors = useSelector(getEmailsForContributors(contributors));

  const lang = useSelector(getLang('KNOWLEDGE_EDITOR'));

  return { allContributors, lang };
}
