import _ from 'lodash';
import {
  AUTH_ATTACH_USER_ID,
  AUTH_FINISH_ONBOARDING,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_UPDATE_USER,
  AUTH_UPDATE_TOKEN,
  AUTH_SET_REGION,
  PROFILE_COMPLETED,
  AUTH_UPDATE_TOKEN_DETAILS,
  AUTH_UPDATE_AUTH_CONTEXT,
  AUTH_ACTIVATE_ACCOUNT,
} from '.';
import authenticationServiceApi from '../../api/authentication.service.api';
import userServiceApi from '../../api/user.service.api';
import { USER_PROFILE_SOURCE } from '../../constants';

export const signIn =
  ({ accessToken, refreshToken, user }) =>
  async dispatch => {
    dispatch({
      type: AUTH_LOGIN,
      payload: { accessToken, refreshToken, user },
    });
  };

export const activateAccount = () => async dispatch =>
  dispatch({
    type: AUTH_ACTIVATE_ACCOUNT,
  });

export const updateToken =
  ({ accessToken, refreshToken }) =>
  dispatch => {
    dispatch(updateTokenDetails({ accessToken }));

    const payload = {
      accessToken,
      refreshToken,
    };
    const parsedPayload = _.omitBy(payload, _.isUndefined);

    dispatch({
      type: AUTH_UPDATE_TOKEN,
      payload: parsedPayload,
    });
  };

export const updateTokenDetails =
  ({ accessToken }) =>
  dispatch => {
    dispatch({
      type: AUTH_UPDATE_TOKEN_DETAILS,
      payload: { accessToken },
    });
  };

export const updateAuthenticationContext =
  ({ context }) =>
  dispatch => {
    dispatch({
      type: AUTH_UPDATE_AUTH_CONTEXT,
      payload: { context },
    });
  };

export const attachUserDetails = user => dispatch => {
  dispatch({
    type: AUTH_UPDATE_USER,
    payload: { user },
  });
};

export const setRegion =
  ({ region }) =>
  dispatch => {
    dispatch({
      type: AUTH_SET_REGION,
      payload: { region },
    });
  };

export const attachUserId = ({ id }) => ({
  type: AUTH_ATTACH_USER_ID,
  payload: { id },
});

export const logout =
  ({ skipApiCall = false } = {}) =>
  async dispatch => {
    if (!skipApiCall) {
      await authenticationServiceApi.logout();
    }

    dispatch({ type: AUTH_LOGOUT });
  };

export const finishOnboarding = () => async dispatch => {
  dispatch({ type: AUTH_FINISH_ONBOARDING });
};

export const updateUserData =
  ({ user: userData, source = USER_PROFILE_SOURCE.PROFILE_PAGE }) =>
  async dispatch => {
    const res = await userServiceApi.updateUserProfile({ user: userData });

    if (res.error) {
      return res;
    }

    dispatch({
      type: AUTH_UPDATE_USER,
      payload: {
        user: res?.data?.user,
      },
    });

    if (
      source === USER_PROFILE_SOURCE.POPUP &&
      !userData?.profileCompletedAt &&
      res?.data?.user?.profileCompletedAt
    ) {
      dispatch({
        type: PROFILE_COMPLETED,
      });
    }

    return res;
  };

export const snoozeProfile =
  ({ id }) =>
  async () => {
    const res = await userServiceApi.snoozeProfile({ id });

    if (res.error) {
      throw new Error(res.data.message);
    }
  };

export const fetchAuthenticatedUser = () => async dispatch => {
  const res = await userServiceApi.me();

  if (res.error) {
    throw new Error(res.data.message);
  }

  dispatch(attachUserDetails(res.data.user));
};
