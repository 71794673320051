import { UPDATE_DEVICE_FLAGS } from '../actions';
import { updateStateToStorage } from '../actions/storage.actions';

export const onDeviceFlagsUpdated =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === UPDATE_DEVICE_FLAGS) {
      dispatch(updateStateToStorage());
    }
  };

export const deviceFlagsMiddleware = [onDeviceFlagsUpdated];
