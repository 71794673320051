import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { AUTH_LOGOUT, INITIAL_STATE, PREFERENCES_UPDATE } from '../actions';

const initialState = {
  preferences: {},
  lastSync: null,
  lastUpdated: null,
};

const loadInitialState = (state, { user = initialState }) =>
  update(state, {
    $merge: user,
  });

const updatePreference = (state, preference) =>
  update(state, {
    preferences: {
      $merge: preference,
    },
    lastUpdated: { $set: Date.now() },
  });

const authLogout = () => {
  return initialState;
};

const actionsRepo = {
  [INITIAL_STATE]: loadInitialState,
  [PREFERENCES_UPDATE]: updatePreference,
  [AUTH_LOGOUT]: authLogout,
};

export default createReducer({ initialState, actionsRepo });
