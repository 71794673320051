import update from 'immutability-helper';
import {
  APPLICATION_WIPE_DATA,
  FAVORITES_ADD_ITEM,
  FAVORITES_MARK_FOR_REMOVAL,
  FAVORITES_UPDATE_CONTENT,
  FAVORITES_USE_CACHE,
  INITIAL_STATE,
} from '../actions';
import { createReducer } from '../../utils/redux/reducers.utils';

const initialState = {
  byId: {},
  lastUpdated: null,
};

const addToFavorites = (state, { itemId, entity }) =>
  update(state, {
    byId: { $merge: { [itemId]: { itemId, entity } } },
    lastUpdated: { $set: Date.now() },
  });

const setInitialState = (state, { favorites = initialState }) =>
  update(state, {
    $merge: favorites,
  });

const updateFavorites = (state, { favoritesById }) => {
  return update(state, {
    $merge: {
      byId: favoritesById,
      lastUpdated: Date.now(),
    },
  });
};

const useCache = state =>
  update(state, {
    isReady: { $set: true },
  });

const markForRemoval = (state, { itemId }) =>
  update(state, {
    byId: { [itemId]: { $merge: { toDelete: true } } },
    lastUpdated: { $set: Date.now() },
  });

const cleanData = () => {
  return initialState;
};

const actionsRepo = {
  [INITIAL_STATE]: setInitialState,
  [FAVORITES_ADD_ITEM]: addToFavorites,
  [FAVORITES_UPDATE_CONTENT]: updateFavorites,
  [FAVORITES_USE_CACHE]: useCache,
  [FAVORITES_MARK_FOR_REMOVAL]: markForRemoval,
  [APPLICATION_WIPE_DATA]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
