import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ArrowLeftIcon, Tile } from '../../../images';
import styles from './compendium-preview.module.scss';
import sharedStyles from '../knowledge-content/knowledge-content.module.scss';
import contentStyles from '../../../styles/content-style.module.scss';
import { Lang } from 'shared/constants';
import { TagWithIcon } from '../../tags';

const CompendiumPreviewComponent = React.memo(function CompendiumPreviewComponent({
  title,
  subtitle,
  tags,
  value,
  close,
  langTags = Lang.TAGS,
  langTagsPlaceholder = Lang.TAGS_FORM,
}) {
  const getTagLabel = tag => langTags[tag];

  const renderTags = () => {
    if (!tags?.specialty && !tags?.subspecialty) return;

    return (
      <div className={sharedStyles.tags}>
        {tags.specialty && (
          <div className={sharedStyles.tagContainer}>
            <h6>{langTagsPlaceholder.SPECIALTY_PLACEHOLDER}</h6>
            <TagWithIcon tag={tags.specialty} />
          </div>
        )}
        {tags.subspecialty && (
          <div className={sharedStyles.tagContainer}>
            <h6>{langTagsPlaceholder.SUBSPECIALTY_PLACEHOLDER}</h6>
            <TagWithIcon tag={tags.subspecialty} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={sharedStyles.root}>
      <div
        className={sharedStyles.header}
        style={{
          backgroundImage: `url(${Tile})`,
        }}>
        <div className={sharedStyles.buttons}>
          <img className={sharedStyles.back} src={ArrowLeftIcon} onClick={close} alt={'Back'} />
        </div>
        {tags.contentType && (
          <div className={sharedStyles.contentType}>{getTagLabel(tags.contentType)}</div>
        )}
        <h2 className={styles.title}>{title}</h2>
        <h4 className={styles.subtitle}>{subtitle}</h4>

        {renderTags()}
      </div>
      <div className={contentStyles.contentContainer}>
        <ReactMarkdown>{value}</ReactMarkdown>
      </div>
    </div>
  );
});

export { CompendiumPreviewComponent };
