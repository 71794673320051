import admissionReducer from './admission.reducer';
import applicationReducer from './application.reducer';
import auth from './auth.reducer';
import availableOrganizationsReducer from './available-organizations.reducer';
import deepLinkingReducer from './deep-linking.reducer';
import deviceFlagsReducer from './deviceFlags.reducer';
import favoritesReducer from './favorites.reducer';
import fsReducer from './fs.reducer';
import knowledgeReducer from './knowledge.reducer';
import labReducer from './lab.reducer';
import langManagerReducer from './lang-manager.reducer';
import langReducer from './lang.reducer';
import maintenanceReducer from './maintenance.reducer';
import notificationReducer from './notification.reducer';
import organizationReducer from './organization.reducer';
import ownersReducer from './owners.reducer';
import searchReducer from './search.reducer';
import userReducer from './user.reducer';
import usersManagementReducer from './users-management.reducer';
import communicationReducer from './communication.reducer';
import generalConfigReducer from './general-config.reducer';
import featureFlagReducer from './feature-flag.reducer';
import contactsReducer from './contacts.reducer';
import labelsReducer from './labels.reducer';
import workspaceReducer from './workspace.reducer';
import aiReducer from './ai.reducer';
import oauthConfigReducer from './oauth-configuration.reducer';

export const baseReducersMap = {
  lang: langReducer,
  maintenance: maintenanceReducer,
  generalConfig: generalConfigReducer,
  featureFlags: featureFlagReducer,
  auth,
  organization: organizationReducer,
  application: applicationReducer,
  deviceFlags: deviceFlagsReducer,
  knowledge: knowledgeReducer,
  search: searchReducer,
  favorites: favoritesReducer,
  user: userReducer,
  fs: fsReducer,
  notification: notificationReducer,
  owners: ownersReducer,
  lab: labReducer,
  availableOrganizations: availableOrganizationsReducer,
  usersManagement: usersManagementReducer,
  admission: admissionReducer,
  deepLinking: deepLinkingReducer,
  langManager: langManagerReducer,
  communication: communicationReducer,
  contacts: contactsReducer,
  labels: labelsReducer,
  workspace: workspaceReducer,
  ai: aiReducer,
  oauthConfig: oauthConfigReducer,
};
