import update from 'immutability-helper';
import { KNOWLEDGE_SHOW_KEYWORDS_VIEW } from '../actions';
import { createReducer } from 'shared/utils/redux/reducers.utils';

const initialState = {
  id: null,
};

const showKeywordsView = (state, { id = null }) => {
  return update(state, {
    $merge: { id },
  });
};

const actionsRepo = {
  [KNOWLEDGE_SHOW_KEYWORDS_VIEW]: showKeywordsView,
};

export default createReducer({ initialState, actionsRepo });
