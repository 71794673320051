import { Lang } from '../../constants/lang';
import { createReducer } from '../../utils/redux/reducers.utils';
import { LANG_MANAGER_UPDATE_LANG } from '../actions';

const initialState = Lang;

const updateLang = (state, { lang }) => lang;

const actionsRepo = {
  [LANG_MANAGER_UPDATE_LANG]: updateLang,
};

export default createReducer({ initialState, actionsRepo });
