import { connect } from 'react-redux';
import { getRoot } from 'shared/store/selectors/knowledge.selector';
import { KnowledgeBaseComponent } from './knowledge-base.component';
import { knowledgeSelectItem } from '../../store/actions/knowledge-viewer.actions';

const mapStateToProps = ({ knowledge }) => ({
  category: getRoot(knowledge),
});

export const KnowledgeBase = connect(mapStateToProps, { knowledgeSelectItem })(
  KnowledgeBaseComponent,
);
