import { connect } from 'react-redux';
import { getCategoriesById, getCategoryById } from 'shared/store/selectors/knowledge.selector';
import { KnowledgeSectionListComponent } from './knowledge-section-list.component';

const mapStateToProps = ({ knowledge, knowledgeViewer, lang }) => {
  const { id } = knowledgeViewer;
  const category = id ? getCategoryById(knowledge, { id }) : {};

  const parentCategory = id
    ? getCategoryById(knowledge, {
        id: category.pathParts[category.pathParts.length - 1],
      })
    : {};

  return {
    category,
    parentCategory,
    sections: id ? getCategoriesById(knowledge, id) : [],
    lang: lang.WIDGET,
  };
};

export const KnowledgeSectionList = connect(mapStateToProps)(KnowledgeSectionListComponent);
