import Mixpanel from 'mixpanel-browser';
import {
  MIXPANEL_TOKENS,
  SESSION_PROPERTIES,
  ENVIRONMENT_KEYS,
  ANALYTICS_TO_TRACK_VIA_API,
} from 'shared/constants';
import { currentEnvironment } from 'shared/services/Environment';
import AnalyticsAPI from 'shared/api/analytics.service.api';
import { nanoid } from 'nanoid';

class AnalyticsService {
  async init() {
    await Mixpanel.init(MIXPANEL_TOKENS[currentEnvironment()]);

    // Session properties to be sent with every event
    this.sessionProperties = {
      [SESSION_PROPERTIES.CLIENT_APP]: 'widget',
    };

    Mixpanel.register_once(this.sessionProperties);

    // Generate session id
    this.sessionProperties[SESSION_PROPERTIES.SESSION_ID] = nanoid();
  }

  track(eventName, properties = {}, options = {}) {
    const eventData = {
      ...properties,
      ...this.sessionProperties,
    };

    console.debug(`[Analytics] Track event - ${eventName}`, eventData);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.track(eventName, eventData);

    if (ANALYTICS_TO_TRACK_VIA_API.includes(eventName)) {
      AnalyticsAPI.trackEvent({ event: `${eventName} {proxy}`, properties: eventData });
    }
  }

  setUserId(id) {
    console.debug(`[Analytics] Set user id - ${id}`);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.identify(id);
  }

  registerUserData(data) {
    console.debug(`[Analytics] Set user data`, data);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.register(data);
  }

  setGroup({ key, value }) {
    console.debug(`[Analytics] Set group ${key} - ${value}`);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.set_group(key, value);
    this.sessionProperties[key] = value;
    Mixpanel.register_once(this.sessionProperties);
  }

  setSessionProperty(key, value) {
    this.sessionProperties[key] = value;
  }
}

export default new AnalyticsService();
