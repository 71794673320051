import { useSelector } from 'react-redux';
import { getOrganizationLocale } from 'shared/store/selectors/organization.selector';
import {
  formatLongDate,
  formatLongDateTime,
  formatRangeDate,
  formatShortDate,
  formatShortDateTime,
  formatShortTime,
} from 'shared/utils/date.utils';
import { getRegion } from '../store/selectors/user.selector';
import { REGIONS } from '../constants/region.constants';

const VALID_LOCALE_REGIONS = {
  [REGIONS.EU]: 'en-GB',
  [REGIONS.US]: 'en-US',
};

const useDateFormatter = () => {
  const region = useSelector(getRegion);
  const DEFAULT_LOCALE = VALID_LOCALE_REGIONS[region];
  const locale = useSelector(getOrganizationLocale) || DEFAULT_LOCALE;

  return {
    /**
     *
     * @param {*} date
     * @returns the date in the format "dd MMMM yyyy"
     */
    formatLongDate: date => formatLongDate({ date, locale }),
    /**
     *
     * @param {*} date
     * @returns the date in the format "dd MMMM yyyy HH:mm"
     */
    formatLongDateTime: date => formatLongDateTime({ date, locale }),
    /**
     *
     * @param {*} start
     * @param {*} end
     * @returns the date range in the format "dd MMMM yyyy - dd MMMM yyyy"
     */
    formatRangeDate: (start, end) => formatRangeDate({ start, end, locale }),
    /**
     *
     * @param {*} date
     * @returns the date in the format "dd mm yyyy"
     */
    formatShortDate: date => formatShortDate({ date, locale }),
    /**
     *
     * @param {*} date
     * @returns the date in the format "dd mm yyyy HH:mm"
     */
    formatShortDateTime: date => formatShortDateTime({ date, locale }),
    /**
     *
     * @param {*} date
     * @returns the time in the format "HH:mm"
     */
    formatShortTime: date => formatShortTime({ date, locale }),
  };
};

export { useDateFormatter };
