import {
  KNOWLEDGE_CREATE_ITEM,
  KNOWLEDGE_CREATE_LINK,
  KNOWLEDGE_DELETE_ITEM,
  KNOWLEDGE_DELETE_LINK,
  KNOWLEDGE_UPDATE_ITEM,
  KNOWLEDGE_UPDATE_LINK,
  KNOWLEDGE_UPDATE_ITEMS_ORDER,
  KNOWLEDGE_UPDATE_ITEM_LOCATION,
  KNOWLEDGE_MOVE_LINK,
  KNOWLEDGE_UPDATE_ITEM_CONTENT,
} from '.';
import knowledgeServiceApi from '../../api/knowledge.service.api';
import axios from 'axios';
import { navigate } from '../../services/navigation.service';
import { KNOWLEDGE_TYPES, ROUTES } from '../../constants';
import { getAssetsServiceBaseURL } from '../../constants/APIconstants';
import { getRegion } from '../selectors/user.selector';
import FormDataLib from 'form-data';

export const getUploadUrl =
  ({ type, size, name }) =>
  async () => {
    let res = await knowledgeServiceApi.getUploadUrl({ type, size, name });

    return res.data;
  };

export const fetchKnowledgeItemContent =
  ({ id }) =>
  async dispatch => {
    const res = await knowledgeServiceApi.getItemContent({ id });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_ITEM_CONTENT,
      payload: {
        item: res.data,
      },
    });

    return res.data;
  };

export const createKnowledgeItem = (data, initialWorkspaceId) => async (dispatch, getState) => {
  let workspaceId = initialWorkspaceId;

  if (!initialWorkspaceId) {
    workspaceId = getState().workspace.selected;
  }

  let res = await knowledgeServiceApi.createKnowledgeItem({ data, workspaceId });

  if (res.error) {
    throw new Error(res.data.message);
  }

  dispatch({
    type: KNOWLEDGE_CREATE_ITEM,
    payload: {
      item: res.data,
      categoryId: data.categoryId,
    },
  });

  return res.data;
};

export const updateKnowledgeItem =
  ({ id, ...data }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.updateKnowledgeItem({ id, data });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_ITEM,
      payload: {
        item: res.data,
      },
    });

    return res.data;
  };

export const updateKnowledgeItemsOrder =
  ({ id, itemId, index }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.updateKnowledgeItemsOrder({
      id,
      itemId,
      index,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_ITEMS_ORDER,
      payload: { id, itemId, index },
    });
  };

export const moveKnowledgeItem =
  ({ id, categoryId, sourceId }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.moveKnowledgeItem({
      id,
      categoryId,
      sourceId,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_ITEM_LOCATION,
      payload: { id, categoryId, sourceId },
    });
  };

export const deleteKnowledgeItem =
  ({ id }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.deleteKnowledgeItem({ id });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_DELETE_ITEM,
      payload: {
        id,
      },
    });
  };

export const uploadDocument = (file, onUploadProgress) => async (dispatch, getState) => {
  const { type, size, name } = file;

  const {
    url,
    id: assetId,
    type: knowledgeType,
    fileUrl,
  } = await dispatch(getUploadUrl({ type, size, name }));

  if (!fileUrl) {
    throw new Error('Failed to get upload url');
  }

  await axios.put(url, file, {
    headers: {
      'Content-Type': type,
    },
    onUploadProgress,
  });

  const region = getRegion(getState());

  return {
    url: `${getAssetsServiceBaseURL(region)}${fileUrl}`,
    assetId,
    type: knowledgeType,
  };
};

export const uploadFile =
  ({ file, onUploadProgress }) =>
  async dispatch => {
    try {
      const { url, assetId, type } = await dispatch(uploadDocument(file, onUploadProgress));
      return { url, assetId, type };
    } catch (error) {
      return { error: error.message };
    }
  };

export const uploadFileToGCP =
  ({ file }) =>
  async () => {
    try {
      const formData = new FormDataLib();
      formData.append('file', file);
      const { data, error } = await knowledgeServiceApi.uploadFileThroughServer(formData);

      if (error) {
        return { error: error.message };
      }

      return { url: data.assetUrl };
    } catch (error) {
      return { error: error.message };
    }
  };

export const finalizeUploadFile =
  ({ assetId }) =>
  async () => {
    const res = await knowledgeServiceApi.finalizedUploadFile({ id: assetId });

    if (res.error) {
      return { error: res.error.message };
    }

    return res.data;
  };

export const convertAssetToPdf =
  ({ assetId }) =>
  async () => {
    const res = await knowledgeServiceApi.convertAssetToPdf({ assetId });

    if (res.error) {
      return { error: res.error.message };
    }

    return res.data;
  };

export const rejectDuplicatedItem =
  ({ assetId }) =>
  async () => {
    await knowledgeServiceApi.rejectDuplicatedItem({ id: assetId });
  };

export const triggerOcr =
  ({ assetId }) =>
  async () => {
    await knowledgeServiceApi.triggerOcrConversion({ assetId });
  };

export const retroOcrConversion =
  ({ itemId }) =>
  async () => {
    const res = await knowledgeServiceApi.getKnowledgeItemAsset({ itemId });

    if (res.error) {
      throw new Error(res.data.message);
    }

    const { asset } = res.data;

    if (!asset) {
      throw new Error('No asset found');
    }

    await knowledgeServiceApi.triggerOcrConversion({ assetId: asset.id });

    // only pdf is supported for now
    const assetType = KNOWLEDGE_TYPES.PDF;

    return { assetId: asset.id, url: asset.url, type: assetType };
  };

export const fetchOcrData =
  ({ assetId }) =>
  async () => {
    const res = await knowledgeServiceApi.fetchOcrData({ assetId });

    if (res.error) {
      throw new Error(res.data.message);
    }

    return res.data?.task;
  };

export const editItem =
  ({ id }) =>
  async dispatch => {
    navigate(ROUTES.SEARCH, { id });
  };

export const createKnowledgeLink =
  ({ categoryId, linkOwnerIds, items }) =>
  async dispatch => {
    const data = items.map(item => ({
      itemId: item.id,
      ownerIds: linkOwnerIds,
      thumbnail: item.thumbnail ?? '',
    }));

    const res = await knowledgeServiceApi.createKnowledgeLink({ categoryId, data });

    if (res?.error) {
      return { error: res.data.message };
    }

    dispatch({
      type: KNOWLEDGE_CREATE_LINK,
      payload: {
        items: res.data,
        categoryId: categoryId,
      },
    });

    return res.data;
  };

export const deleteKnowledgeLink =
  ({ id, categoryId }) =>
  async dispatch => {
    const res = await knowledgeServiceApi.deleteKnowledgeLink({ categoryId, itemId: id });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_DELETE_LINK,
      payload: {
        id,
        categoryId,
      },
    });
  };

export const updateKnowledgeLink =
  ({ id, categoryId, linkOwnerIds }) =>
  async dispatch => {
    const res = await knowledgeServiceApi.updateKnowledgeLink({
      categoryId,
      itemId: id,
      data: { ownerIds: linkOwnerIds },
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_LINK,
      payload: {
        id,
        categoryId,
        linkOwnerIds,
      },
    });
  };

export const moveKnowledgeLink =
  ({ id, sourceId: categoryId, categoryId: newCategoryId, item }) =>
  async dispatch => {
    const res = await knowledgeServiceApi.moveKnowledgeLink({
      categoryId,
      newCategoryId,
      itemId: id,
      ownerIds: item.linkOwnerIds,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_MOVE_LINK,
      payload: {
        id,
        categoryId,
        newCategoryId,
      },
    });
  };
