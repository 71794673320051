import { ENVIRONMENT_KEYS } from './environment';
import {
  APP_IS_READY,
  AUTH_FINISH_ONBOARDING,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  FIRST_TIME,
} from '../store/actions';

export const EVENTS = {
  APPLICATION: {
    FIRST_INIT: '[Application] First Init',
    OPEN: '[Application] Open',
    PAGE_VIEW: 'Page View',
  },
  UPDATE_MANAGER: {
    DISMISS_UPDATE: '[Update Manager] Dismiss',
    UPDATE: '[Update Manager] Update',
  },
  CONTENT: {
    OPEN: '[Content] Open',
    UPLOAD: '[Content] Upload',
    UPDATE: '[Content] Update',
  },
  KNOWLEDGE_ITEMS: {
    OPEN: '[Knowledge] Open',
    CLOSE: '[Knowledge] Close',
    OPEN_INFORMATION: '[Knowledge] Information - open',
    CLOSE_INFORMATION: '[Knowledge] Information - close',
    FILTER_BY_ALL: '[Knowledge] Filter by all',
    FILTER_BY_DOCUMENTS: '[Knowledge] Filter by documents',
    FILTER_BY_MEDIA: '[Knowledge] Filter by media',
    FILTER_BY_CONTACTS: '[Knowledge] Filter by contacts',
    FILTER_BY_COMPENDIUM: '[Knowledge] Filter by compendium',
    FILTER_BY_CHECKLIST: '[Knowledge] Filter by checklist',
    SEARCH_CLICK: '[Knowledge] Search - click',
    SEARCH_ON_BLUR: '[Knowledge] Search - on blur',
    EDIT_ITEM: '[Knowledge] Edit item',
    CLOSE_EDIT_MODE: '[Knowledge] Close edit mode',
    CREATE_KNOWLEDGE_ITEM: '[Knowledge] Create knowledge',
    UPDATE_KNOWLEDGE_ITEM: '[Knowledge] Update knowledge',
    TOC_SCROLL: '[Knowledge] TOC scroll',
    TOGGLE_SMART_FORMAT: '[Knowledge] Toggle smart format',
    CREATE_NEW_ITEM_BUTTON: '[Knowledge] Create new item button',
    UPLOAD_FILE_IMPRESSION: '[Knowledge] Upload file impression',
    UPLOAD_FILE_START: '[Knowledge] Upload file start',
    UPLOAD_FILE_DONE: '[Knowledge] Upload file done',
    OPEN_TOC: '[Knowledge] Open TOC',
    CLOSE_TOC: '[Knowledge] Close TOC',
    DOWNLOAD_FILE: '[Knowledge] Download file',
    DOWNLOAD_FILE_IMPRESSION: '[Knowledge] Download file impression',
    OPEN_IN_NEW_TAB: '[knowledge] Open in new tab',
    DUPLICATION_DETECTED: '[Knowledge] Duplication detected - impression',
    DUPLICATION_DETECTED_CREATE_LINK: '[Knowledge] Duplication detected - create link',
    DUPLICATION_DETECTED_DISMISS: '[Knowledge] Duplication detected - dismiss',
    FAILED_TO_LOAD: '[Knowledge] Failed to load knowledge',
  },
  SMART_FORMAT_CONVERSION: {
    TOGGLE_STATE: '[SmartFormat] Toggle state',
    START: '[SmartFormat] Process start',
    DONE: '[SmartFormat] Process done',
    FAILED: '[SmartFormat] Process failed',
  },
  SMART_FORMAT_CONVERSION_TIME: {
    LATER: 'later',
    IMMEDIATE: 'immediate',
  },
  ONBOARDING: {
    FINISH: '[Onboarding] finish',
    CLICK_ON_NEXT: '[Onboarding] next',
  },
  DRAWER: {
    OPEN: '[Drawer] Open',
    OPEN_FROM_ICON: '[Drawer] Click on icon',
    ABOUT_ORGANIZATION: '[Drawer] About Organization',
    ABOUT_US: '[Drawer] About C8.health',
    GIVE_FEEDBACK: '[Drawer] Give feedback',
    CONTACT: '[Drawer] Contact C8.health',
    SWITCH_ORGANIZATION: '[Drawer] switch organization',
  },
  AUTH: {
    LOGIN: '[Auth] Login',
    LOGOUT: '[Auth] Logout',
    MAGIC_LINK_TRIGGERED: '[Auth] Magic link triggered',
    LOGIN_BUBBLE_CLICKED: '[Auth] Login bubble clicked',
    LOGIN_BUBBLE_LOGIN_FLOW_OPENED: '[Auth] Login bubble login flow opened',
    LOGIN_BUBBLE_LOGIN_FLOW_CLOSED: '[Auth] Login bubble login flow closed',
    LOGIN_BUBBLE_LOGIN_SUCCESS: '[Auth] Login bubble login success',
    LOGIN_BUBBLE_LOGIN_FAILED: '[Auth] Login bubble login failed',
  },
  WELCOME: {},
  APPLICATION_SCREEN: {
    HEADER_CLICK: '[Header] Click on header',
  },
  NOTIFICATION_MODAL: {
    CONFIRM: '[Notification] Confirm',
    CONFIRM_AND_APPROVED: '[Notification] Confirm and Approved',
    CONFIRM_AND_DENIED: '[Notification] Confirm and Denied',
    LATER: '[Notification] Later',
  },
  NOTIFICATIONS: {
    OPEN_NOTIFICATION: '[Notification] Open from notification',
    OPEN_KNOWLEDGE_ITEM_FROM_NOTIFICATION: '[Notification] Open knowledge item',
    CLOSE_KNOWLEDGE_ITEM_FROM_NOTIFICATION: '[Notification] Close knowledge item',
  },
  LAB_ITEMS: {
    OPEN: '[Lab] Open',
    OPEN_INFORMATION: '[Lab] Information - open',
    CLOSE_INFORMATION: '[Lab] Information - close',
  },
  CHECKLISTS: {
    OPEN: '[Checklist] Open',
  },

  SEARCH: {
    SEARCHING: '[Search] Searching',
    NOT_FOUND: '[Search] Not found',
    SESSION_START: '[Search] Session start',
    RESULTS_LISTED: '[Search] Results listed',
    RESULT_CLICKED: '[Search] Result clicked',
    SHOW_MORE: '[Search] Show more',
  },

  LANGUAGE_SELECTOR: {
    OPEN: '[Lang Selector] Open',
    SELECT_LANG: '[Lang Selector] Select lang',
  },
  MARKDOWN_EDITOR: {
    OPEN: '[Editor] Open',
  },
  USER_PROFILE: {
    OPEN: '[Profile] Open',
    COMPLETE: '[Profile] Complete profile',
    UPDATE: '[Profile] Update',
    SNOOZE: '[Profile] Snooze',
  },
  DEEP_LINK: {
    OPEN: '[Deep Link] Open',
  },
  FAVORITES: {
    ADD: '[Favorites] Add',
    REMOVE: '[Favorites] Remove',
  },
  SHARE: {
    COPY_LINK: '[Share] Copy link',
  },
  CONTACT: {
    CALL: '[Contact] Call',
    SMS: '[Contact] SMS',
    PAGER: '[Contact] Pager',
    CISCO: '[Contact] Cisco',
    EMAIL: '[Contact] Email',
    OPEN_DETAILS: '[Contact] Open details',
  },
  USER_PREFERENCES: {
    ADD_RESOURCE: '[User] Add resource',
    RESOURCE_OPEN: '[User] Resource open',
    DELETE_RESOURCE: '[User] Delete resource',
  },
  SUGGEST_CONTENT: {
    START: '[Suggest Content] Start Suggesting',
    SELECT_SOURCE: '[Suggest Content] Select Source',
    SUBMIT: '[Suggest Content] Submit',
  },
  SUGGEST_KEYWORDS: {
    ADD: '[Suggest Keywords] Add keywords',
  },
  ROTATIONS: {
    OPEN_LINK: '[Rotations] Open link',
    OPEN: '[Rotations] Open',
    SEARCH: '[Rotations] Search',
    SIDEBAR_TAB_SELECTED: '[Rotations] Sidebar Tab selected',
    TAB_SELECTED: '[Rotations] Tab selected',
    ADD_RESOURCES_CLICK: '[Rotations] Add resources click',
    ADD_RESOURCE: '[Rotations] Add resource',
    RESOURCE_OPEN: '[Rotations] Resource open',
    DELETE_RESOURCE: '[Rotations] Delete resource',
    EDIT_ROTATION_DETAILS_CLICK: '[Rotations] Edit rotation details click',
    ROTATION_DETAILS_UPDATED: '[Rotations] Rotation details updated',
    ADD_TEAM_MEMBER: '[Rotations] Add team member',
    REMOVE_TEAM_MEMBER: '[Rotations] Remove team member',
    TEAM_MEMBER_CLICKED: '[Rotations] Team member clicked',
    WELCOME_LETTER_EDIT: '[Rotations] Edit welcome letter',
    WELCOME_LETTER_PREVIEW: '[Rotations] Preview welcome letter',
    WELCOME_LETTER_SAVE: '[Rotations] Save welcome letter',
  },
  AI_CHAT: {
    OPEN: '[AI Chat] Open',
    CLOSE: '[AI Chat] Close',
    MESSAGE: '[AI Chat] Message',
    ERROR: '[AI Chat] Error',
    EDIT_MESSAGE: '[AI Chat] Edit message',
    CLEAR_CHAT: '[AI Chat] Clear chat',
    COPY_TO_CLIPBOARD: '[AI Chat] Copy to clipboard',
    OPEN_RESOURCES: '[AI Chat] Open resources',
    OPEN_REFERENCES: '[AI Chat] Open references',
    TOGGLE_REFERENCE: '[AI Chat] Toggle reference',
    FEEDBACK: {
      GOOD: '[AI Chat] Good feedback',
      BAD: '[AI Chat] Bad feedback',
      CANCELED: '[AI Chat] Cancel feedback',
    },
    SUBMIT_FEEDBACK: '[AI Chat] Submit feedback',
    BAD_FEEDBACK_OPTION: '[AI Chat] Chose bad feedback option',
    UNSELECT_FEEDBACK_OPTION: '[AI Chat] Unselect feedback option',
  },
  KNOWLEDGE_COMMENTS: {
    COMMENT_SENT: '[Knowledge Comments] Comment sent',
    REPLY_CLICKED: '[Knowledge Comments] Reply clicked',
    DELETE_CLICKED: '[Knowledge Comments] Delete clicked',
    COMMENTS_LINK_CLICKED: '[Knowledge Comments] Comments link clicked',
  },
  CATEGORIES: {
    ADD: '[Categories] Add new category',
    REMOVE: '[Categories] Remove category',
    EDIT: '[Categories] Edit category',
  },
  KNOWLEDGE_HUB: {
    OPEN: '[Knowledge Hub] Open',
    SELECT_ORGANIZATION: '[Knowledge Hub] Select organization',
    SEARCHING: '[Knowledge Hub] Searching',
    NOT_FOUND: '[Knowledge Hub] Not found',
    RESULTS_LISTED: '[Knowledge Hub] Results listed',
    IMPORT: '[Knowledge Hub] Import',
    IMPORT_DISMISS: '[Knowledge Hub] Import dismiss',
    IMPORT_EDIT_CONTENT: '[Knowledge Hub] Import with content edited',
    PUBLISH: '[Knowledge Hub] Publish',
    SHARE_ITEM: '[Knowledge Hub] Share item',
    UNSHARE_ITEM: '[Knowledge Hub] Unshare item',
    SHARE_CATEGORY: '[Knowledge Hub] Share category',
    UNSHARE_CATEGORY: '[Knowledge Hub] Unshare category',
  },
  QUICK_LINKS: {
    OPEN: '[Quick Links] Open',
  },
};

export const ANALYTICS_CATEGORIES_TYPES = {
  SECTION: 'section',
  BINDER: 'binder',
  DIRECTORY: 'directory',
};

export const ANALYTICS_CONTENT_CATEGORIES_TYPES = {
  KNOWLEDGE: 'knowledge',
  LAB: 'lab',
  CHECKLIST: 'checklist',
};

export const ANALYTICS_TO_TRACK_VIA_API = [
  EVENTS.APPLICATION.OPEN,
  EVENTS.AUTH.LOGIN,
  EVENTS.AUTH.MAGIC_LINK_TRIGGERED,
  EVENTS.KNOWLEDGE_COMMENTS.COMMENT_SENT,
  EVENTS.KNOWLEDGE_ITEMS.OPEN,
  EVENTS.CONTENT.OPEN,
  EVENTS.SEARCH.SESSION_START,
  EVENTS.CONTACT.OPEN_DETAILS,
  EVENTS.CHECKLISTS.OPEN,
];

export const EVENTS_BY_ACTIONS_MAP = {
  [APP_IS_READY]: EVENTS.APPLICATION.OPEN,
  [AUTH_FINISH_ONBOARDING]: EVENTS.ONBOARDING.FINISH,
  [AUTH_LOGOUT]: EVENTS.AUTH.LOGOUT,
  [AUTH_LOGIN]: EVENTS.AUTH.LOGIN,
  [FIRST_TIME]: EVENTS.APPLICATION.FIRST_INIT,
};

export const SESSION_PROPERTIES = {
  CLIENT_APP: 'client app',
  SESSION_ID: 'session id',
  IS_FIRST_TIME: 'first session',
  USER_TYPE: 'user type',
};

export const MIXPANEL_TOKENS = {
  [ENVIRONMENT_KEYS.PRODUCTION]: 'a03aba374445d95b9fa4ec14bd701629',
  [ENVIRONMENT_KEYS.STAGING]: '49966fd547743a765f002cdfc9b4df8e',
  [ENVIRONMENT_KEYS.DEVELOPMENT]: '49966fd547743a765f002cdfc9b4df8e',
};
