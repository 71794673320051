import { combineReducers, legacy_createStore as createStore } from '@reduxjs/toolkit';
import { baseReducersMap } from 'shared/store/reducers';
import { middleware } from 'shared/store/middlewares';
import authReducers from './reducers/auth.reducers';
import knowledgeViewerReducer from './reducers/knowledgeViewer.reducer';
import keywordsReducer from './reducers/keywords.reducer';
import { analyticsMiddleware } from './middleware/analytics.middleware';
import reportProblemReducer from './reducers/reportProblemViewer.reducer';

const reducersMap = {
  ...baseReducersMap,
  auth: authReducers,
  knowledgeViewer: knowledgeViewerReducer,
  keywordsViewer: keywordsReducer,
  reportProblemViewer: reportProblemReducer,
};

export const store = createStore(
  combineReducers(reducersMap),
  {},
  middleware([...analyticsMiddleware]),
);

global.store = store;
