import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import {
  APPLICATION_WIPE_DATA,
  AUTH_LOGOUT,
  INITIAL_STATE,
  LABELS_UPDATE,
  LABELS_USE_CACHE,
} from '../actions';

const initialState = {
  list: null,
  isReady: false,
  lastUpdated: null,
};

const updateLabels = (state, { labels }) =>
  update(state, {
    $merge: {
      list: labels,
      lastUpdated: Date.now(),
      isReady: true,
    },
  });

const useCache = state =>
  update(state, {
    isReady: { $set: true },
  });

const setInitialState = (state, { labels = initialState }) =>
  update(state, {
    $merge: {
      ...labels,
      isReady: false,
    },
  });

const cleanData = (state, payload) => {
  return initialState;
};

const actionsRepo = {
  [INITIAL_STATE]: setInitialState,
  [LABELS_UPDATE]: updateLabels,
  [LABELS_USE_CACHE]: useCache,
  [APPLICATION_WIPE_DATA]: cleanData,
  [AUTH_LOGOUT]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
