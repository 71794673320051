import { connect } from 'react-redux';
import { getKnowledgeItemById } from 'shared/store/selectors/knowledge.selector';
import { KnowledgeItemComponent } from './knowledge-item.component';
import { knowledgeShowItemContent } from '../../store/actions/knowledge-viewer.actions';
import { knowledgeShowKeywords } from '../../store/actions/keywords.actions';

const mapStateToProps = ({ knowledge }, { id }) => {
  //workaround to deal with not properly deleted item ids from category.knowledgeItems, related to https://headtotoe-io.atlassian.net/browse/PLT-362
  let item = getKnowledgeItemById({ knowledge }, id);

  if (!item?.title) {
    item = {};
  }
  return { item };
};

export const KnowledgeItem = connect(mapStateToProps, {
  knowledgeShowItemContent,
  knowledgeShowKeywords,
})(KnowledgeItemComponent);
