import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { getParentIdByPath } from '../../utils/Utils';
import {
  AUTH_LOGOUT,
  INITIAL_STATE,
  KNOWLEDGE_CREATE_CATEGORY,
  KNOWLEDGE_CREATE_ITEM,
  KNOWLEDGE_DELETE_CATEGORY,
  KNOWLEDGE_DELETE_ITEM,
  KNOWLEDGE_UPDATE_CATEGORY,
  KNOWLEDGE_UPDATE_CONTENT,
  KNOWLEDGE_UPDATE_ITEM,
  KNOWLEDGE_UPDATE_ITEMS_ORDER,
  KNOWLEDGE_UPDATE_SUBCATEGORIES,
  KNOWLEDGE_USE_CACHE,
  KNOWLEDGE_UPDATE_ITEM_LOCATION,
  APPLICATION_WIPE_DATA,
  KNOWLEDGE_CREATE_LINK,
  KNOWLEDGE_DELETE_LINK,
  KNOWLEDGE_UPDATE_LINK,
  KNOWLEDGE_MOVE_LINK,
  KNOWLEDGE_DASHBOARD_UPDATE_CONTENT,
  KNOWLEDGE_UPDATE_ITEM_CONTENT,
  KNOWLEDGE_UPDATE_KEYWORDS,
} from '../actions';
import _ from 'lodash';

const initialState = {
  items: null,
  itemsOpenEventsForOrganization: null,
  itemsOpenEventsForUser: null,
  itemsById: null,
  categories: null,
  categoriesById: null,
  lastUpdated: null,
  previousLastUpdated: null,
  isReady: false,
};

const updateKnowledge = (state, { items, itemsById, categories, categoriesById }) =>
  update(state, {
    $merge: {
      items,
      itemsById,
      categories,
      categoriesById,
      lastUpdated: Date.now(),
      previousLastUpdated: state.lastUpdated || Date.now(),
      isReady: true,
    },
  });

const updateKnowledgeDashboard = (
  state,
  { itemsOpenEventsForOrganization, itemsOpenEventsForUser },
) =>
  update(state, {
    $merge: {
      itemsOpenEventsForOrganization,
      itemsOpenEventsForUser,
      isReady: true,
    },
  });

const updateItemContent = (state, { item }) => {
  let index = state.items.findIndex(i => i.id === item.id);

  return update(state, {
    items: { [index]: { $merge: item } },
    itemsById: {
      [item.id]: { $merge: item },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const updateCategory = (state, change) => {
  let { id } = change;
  let index = state.categories.findIndex(category => category.id === id);

  return update(state, {
    categories: {
      [index]: { $merge: change },
    },
    categoriesById: {
      [id]: { $merge: change },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const deleteCategory = (state, { id }) => {
  let index = state.categories.findIndex(category => category.id === id);
  let cat = state.categoriesById[id];

  let parentId = getParentIdByPath(cat.path);

  let updateAction = {
    categories: { $splice: [[index, 1]] },
    categoriesById: {
      $apply: function (obj) {
        delete obj[id];
        return obj;
      },
    },
    lastUpdated: { $set: Date.now() },
  };

  if (parentId) {
    let indexParent = state.categories.findIndex(category => category.id === parentId);
    let currentIndex = state.categories[indexParent].subcategories.findIndex(i => i === id);
    let action = {
      subcategories: {
        $splice: [[currentIndex, 1]],
      },
    };

    updateAction.categories[indexParent] = action;
    updateAction.categoriesById[parentId] = action;
  }

  return update(state, updateAction);
};

const createCategory = (state, { path, category }) => {
  let parentId = getParentIdByPath(path);

  let updateAction = {
    categories: {
      $push: [category],
    },
    categoriesById: {
      [category.id]: { $set: category },
    },
    lastUpdated: { $set: Date.now() },
  };

  if (parentId) {
    let indexParent = state.categories.findIndex(category => category.id === parentId);
    let action = {
      subcategories: { $push: [category.id] },
    };
    updateAction.categories[indexParent] = action;
    updateAction.categoriesById[parentId] = action;
  }

  return update(state, updateAction);
};

const updateSubcategories = (state, { id, categoryId, index }) => {
  let catIndex = state.categories.findIndex(category => category.id === id);
  let currentIndex = state.categories[catIndex].subcategories.findIndex(i => i === categoryId);
  let item = state.categories[catIndex].subcategories[currentIndex];

  return update(state, {
    categories: {
      [catIndex]: {
        subcategories: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },
    categoriesById: {
      [id]: {
        subcategories: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },

    lastUpdated: { $set: Date.now() },
  });
};

const updateItemsOrder = (state, { id, itemId, index }) => {
  let catIndex = state.categories.findIndex(category => category.id === id);
  const { knowledgeItems } = state.categories[catIndex];
  const currentIndex = knowledgeItems.findIndex(item => item.id === itemId);

  let item = state.categories[catIndex].knowledgeItems[currentIndex];

  return update(state, {
    categories: {
      [catIndex]: {
        knowledgeItems: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },
    categoriesById: {
      [id]: {
        knowledgeItems: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },

    lastUpdated: { $set: Date.now() },
  });
};

const createItem = (state, { item, categoryId }) => {
  let index = state.categories.findIndex(category => category.id === categoryId);

  return update(state, {
    items: { $push: [item] },
    itemsById: {
      [item.id]: { $set: item },
    },
    categories: {
      [index]: { knowledgeItems: { $push: [{ id: item.id }] } },
    },
    categoriesById: {
      [categoryId]: { knowledgeItems: { $push: [{ id: item.id }] } },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const updateItem = (state, { item }) => {
  let index = state.items.findIndex(i => i.id === item.id);

  return update(state, {
    items: { [index]: { $merge: item } },
    itemsById: {
      [item.id]: { $set: item },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const updateKeywords = (state, { id, keywords }) => {
  const index = state.items.findIndex(i => i.id === id);

  // remove duplicates
  const newKeywords = _.uniq([...state.items[index].keywords, ...keywords]);

  return update(state, {
    items: { [index]: { keywords: { $merge: newKeywords } } },
    itemsById: {
      [id]: { keywords: { $merge: newKeywords } },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const deleteItem = (state, { id }) => {
  let index = state.items.findIndex(i => i.id === id);

  return update(state, {
    items: { $splice: [[index, 1]] },
    itemsById: {
      $apply: function (obj) {
        delete obj[id];
        return obj;
      },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const moveItem = (state, { id, categoryId, sourceId }) => {
  let destinationCatIndex = state.categories.findIndex(category => category.id === categoryId);
  let sourceCatIndex = state.categories.findIndex(category => category.id === sourceId);

  const { knowledgeItems } = state.categories[sourceCatIndex];
  const currentIndex = knowledgeItems.findIndex(item => item.id === id);

  return update(state, {
    categories: {
      [sourceCatIndex]: {
        knowledgeItems: {
          $splice: [[currentIndex, 1]],
        },
      },
      [destinationCatIndex]: {
        knowledgeItems: {
          $push: [{ id }],
        },
      },
    },
    categoriesById: {
      [sourceId]: {
        knowledgeItems: {
          $splice: [[currentIndex, 1]],
        },
      },
      [categoryId]: {
        knowledgeItems: {
          $push: [{ id }],
        },
      },
    },

    lastUpdated: { $set: Date.now() },
  });
};

const createLink = (state, { items, categoryId }) => {
  const index = state.categories.findIndex(category => category.id === categoryId);

  const linkedItems = items.map(item => ({
    id: item.itemId,
    linked: true,
    linkOwnerIds: item.ownerIds,
  }));

  return update(state, {
    categories: {
      [index]: { knowledgeItems: { $push: linkedItems } },
    },
    categoriesById: {
      [categoryId]: { knowledgeItems: { $push: linkedItems } },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const deleteLink = (state, { id, categoryId }) => {
  const catIndex = state.categories.findIndex(category => category.id === categoryId);

  const itemIndex = state.categories[catIndex]?.knowledgeItems.findIndex(item => item.id === id);

  return update(state, {
    categories: {
      [catIndex]: { knowledgeItems: { $splice: [[itemIndex, 1]] } },
    },
    categoriesById: {
      [categoryId]: { knowledgeItems: { $splice: [[itemIndex, 1]] } },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const updateLink = (state, { id, categoryId, linkOwnerIds }) => {
  const catIndex = state.categories.findIndex(category => category.id === categoryId);

  const itemIndex = state.categories[catIndex]?.knowledgeItems.findIndex(item => item.id === id);

  return update(state, {
    categories: {
      [catIndex]: { knowledgeItems: { [itemIndex]: { $merge: { linkOwnerIds } } } },
    },
    categoriesById: {
      [categoryId]: { knowledgeItems: { [itemIndex]: { $merge: { linkOwnerIds } } } },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const moveLink = (state, { id, categoryId, newCategoryId }) => {
  const catIndex = state.categories.findIndex(category => category.id === categoryId);
  const newCatIndex = state.categories.findIndex(category => category.id === newCategoryId);

  let itemIndex;

  const item = state.categories[catIndex]?.knowledgeItems.find((item, index) => {
    if (item.id === id) {
      itemIndex = index;
      return item;
    }
    return null;
  });

  return update(state, {
    categories: {
      [catIndex]: { knowledgeItems: { $splice: [[itemIndex, 1]] } },
      [newCatIndex]: { knowledgeItems: { $push: [item] } },
    },
    categoriesById: {
      [categoryId]: { knowledgeItems: { $splice: [[itemIndex, 1]] } },
      [newCategoryId]: { knowledgeItems: { $push: [item] } },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const useCache = state =>
  update(state, {
    isReady: { $set: true },
  });

const setInitialState = (state, { knowledge = initialState }) =>
  update(state, {
    $merge: {
      ...knowledge,
      isReady: false,
    },
  });

const cleanData = (state, payload) => {
  return initialState;
};

const actionsRepo = {
  [INITIAL_STATE]: setInitialState,
  [KNOWLEDGE_UPDATE_CONTENT]: updateKnowledge,
  [KNOWLEDGE_DASHBOARD_UPDATE_CONTENT]: updateKnowledgeDashboard,
  [KNOWLEDGE_UPDATE_ITEM_CONTENT]: updateItemContent,
  [KNOWLEDGE_UPDATE_CATEGORY]: updateCategory,
  [KNOWLEDGE_DELETE_CATEGORY]: deleteCategory,
  [KNOWLEDGE_CREATE_CATEGORY]: createCategory,
  [KNOWLEDGE_UPDATE_SUBCATEGORIES]: updateSubcategories,
  [KNOWLEDGE_CREATE_ITEM]: createItem,
  [KNOWLEDGE_UPDATE_ITEM]: updateItem,
  [KNOWLEDGE_UPDATE_KEYWORDS]: updateKeywords,
  [KNOWLEDGE_DELETE_ITEM]: deleteItem,
  [KNOWLEDGE_UPDATE_ITEM_LOCATION]: moveItem,
  [KNOWLEDGE_UPDATE_ITEMS_ORDER]: updateItemsOrder,
  [KNOWLEDGE_CREATE_LINK]: createLink,
  [KNOWLEDGE_DELETE_LINK]: deleteLink,
  [KNOWLEDGE_UPDATE_LINK]: updateLink,
  [KNOWLEDGE_MOVE_LINK]: moveLink,
  [KNOWLEDGE_USE_CACHE]: useCache,
  [APPLICATION_WIPE_DATA]: cleanData,
  [AUTH_LOGOUT]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
