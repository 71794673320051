import remoteConfigService from '../../helpers/remote-config.service';
import { REMOTE_CONFIG_KEYS } from 'shared/constants';
import { LANG_MANAGER_UPDATE_REPO } from 'shared/store/actions';

export const fetchLanguageFromRemoteConfig = () => async dispatch => {
  const repo = remoteConfigService.getJsonValue(REMOTE_CONFIG_KEYS.LANGUAGE);

  dispatch({
    type: LANG_MANAGER_UPDATE_REPO,
    payload: {
      repo,
    },
  });
};
