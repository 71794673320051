// @ts-check

/**
 * @param {{ initialState: State, actionsRepo: ActionsRepo }} param1
 * @return {import("redux").Reducer<
 * State,
 * {
 *  [K in keyof ActionsRepo]: {
 *    type: K,
 *    payload: Parameters<ActionsRepo[K]>[1]
 *  }
 * }[keyof ActionsRepo]
 * >}
 * @template State
 * @template {{ [key: string]: ((state: State, payload: any) => State) }} ActionsRepo
 */
export const createReducer =
  ({ initialState, actionsRepo }) =>
  (state = initialState, { type, payload }) =>
    // @ts-ignore
    !!actionsRepo[type] ? actionsRepo[type](state, payload) : state;
