import { LABELS_UPDATE, LABELS_USE_CACHE } from '.';
import labelsServiceApi from '../../api/labels.service.api';
import { isConnected } from '../selectors/network.selector';

export const getLabels = () => async (dispatch, getState) => {
  let state = getState();

  if (!isConnected(state)) {
    return dispatch({
      type: LABELS_USE_CACHE,
    });
  }

  const { data, error } = await labelsServiceApi.getLabels();

  if (error) {
    return dispatch({
      type: LABELS_USE_CACHE,
    });
  }

  const labelsByCode = data.labels.reduce((accumulator, label) => {
    accumulator[label.code] = label;
    return accumulator;
  }, {});

  dispatch({
    type: LABELS_UPDATE,
    payload: {
      labels: labelsByCode,
    },
  });
};
