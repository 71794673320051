import _ from 'lodash';
import { filterByExpiryDate } from '../../hooks/knowledge.hooks';
import { normalizedContains, removeDiacritics } from '../../utils/Utils';
import { filterByTags } from 'shared/utils/search.utils';
import { getContacts } from './contact.selector';
import Fuse from 'fuse.js';
import { isImprovedSearchEnabled } from './features.selector';
import { SEARCH_TYPES_KEYS, CATEGORY_TYPES } from '../../constants';
import { filterSections } from '../../hooks/search.hooks';

const SHOW_SCORE = false;
const PATTERN = /[a-f\d]{24}$/i;
const isValidObjectId = id => PATTERN.test(id);
const getIdFromTerm = term => term.match(PATTERN)?.pop();

// Search for knowledge item
const searchOnKnowledgeItem = ({ title, subtitle, keywords = [] }, term) => {
  return (
    normalizedContains(title, term) ||
    normalizedContains(subtitle, term) ||
    !!keywords.find(key => normalizedContains(key, term))
  );
};

// Search for lab item
const searchOnLabItem = ({ title, value, keywords = [] }, term) => {
  return (
    normalizedContains(title, term) ||
    normalizedContains(value, term) ||
    !!keywords.find(key => normalizedContains(key, term))
  );
};

// Search for admission item
const searchOnCategory = ({ title, keywords = [] }, term) => {
  return normalizedContains(title, term) || !!keywords.find(key => normalizedContains(key, term));
};

// Search for contacts
export const searchOnContactList = ({ firstName, lastName, name, email, department }, term) => {
  return (
    normalizedContains(firstName, term) ||
    normalizedContains(lastName, term) ||
    normalizedContains(name, term) ||
    normalizedContains(email, term) ||
    normalizedContains(department, term)
  );
};

/**
 * Search for term in results
 * @param {Object[]} results
 * @param {String} term
 * @param {Object} state
 * @returns {{
 *  knowledge: Object[],
 *  lab: Object[],
 *  admission: Object[],
 *  contacts: Object[],
 * }}
 */
export const innerSearch = (results = {}, term, state = {}) => {
  const termSplit = term.split(' ').slice(1);

  const departmentLang = state?.lang?.['TAGS'] || {};

  termSplit.forEach(termPart => {
    results.lab = results.lab.filter(item => searchOnLabItem(item, termPart));
    results.knowledge = results.knowledge?.filter(item => searchOnKnowledgeItem(item, termPart));
    results.admission = results.admission.filter(item => searchOnCategory(item, termPart));
    results.knowledgeCategories = results.knowledgeCategories.filter(category =>
      searchOnCategory(category, termPart),
    );
    results.labCategories = results.labCategories.filter(category =>
      searchOnCategory(category, termPart),
    );
    results.contacts = results.contacts?.filter(item =>
      searchOnContactList(
        { ...item, department: departmentLang[item?.professional?.department] },
        termPart,
      ),
    );
  });

  return results;
};

/**
 * Create a map of tags and their occurrences
 * @param {{
 *  specialty: { [key: string]: number },
 *  subspecialty: { [key: string]: number },
 *  contentType: { [key: string]: number },
 *  targetAudience: { [key: string]: number },
 *  labels: { [key: string]: number },
 * }} map
 * @param {{
 *  specialty: String,
 *  subspecialty: String,
 *  contentType: String,
 *  targetAudience: String[],
 *  labels: String[],
 * }} [tags={}]
 */
const addToMap = (map, tags = {}) => {
  Object.entries(tags).forEach(([key, value]) => {
    if (!value) return;

    const incrementCount = tagKey => {
      map[key][tagKey] = (map[key][tagKey] || 0) + 1;
    };

    Array.isArray(value) ? value.forEach(incrementCount) : incrementCount(value);
  });
};

/**
 * Old search mechanism using regex and inner search
 *
 * @param {Object} state
 * @param {String} term
 * @param {{}} filters
 * @return {{
 *  initialResults: Object,
 *  results: Object[],
 * }}
 */
const oldSearchMechanism = (state, term, filters) => {
  const { knowledge, lab, admission } = state;
  const contacts = getContacts(state);
  const termSplit = removeDiacritics(term).split(' ');
  const tagsMap = {
    specialty: {},
    subspecialty: {},
    contentType: {},
    targetAudience: {},
    labels: {},
  };
  const filtersByTags = filterByTags({ filters });

  const searchItems = (items, searchFunc) =>
    items?.filter(item => searchFunc(item, termSplit[0])) || [];

  const departmentLang = state.lang['TAGS'] || {};
  const searchContacts =
    contacts
      ?.map(contact => ({
        ...contact,
        department: departmentLang[contact?.professional?.department],
      }))
      .filter(contact => searchOnContactList(contact, termSplit[0])) || [];

  const results = {
    knowledge: searchItems(knowledge?.items, searchOnKnowledgeItem),
    lab: searchItems(lab?.items, searchOnLabItem),
    admission: searchItems(
      admission?.categories.filter(({ type }) => type !== CATEGORY_TYPES.CATEGORY),
      searchOnCategory,
    ),
    knowledgeCategories: searchItems(
      knowledge?.categories.filter(filterSections),
      searchOnCategory,
    ),
    labCategories: searchItems(lab?.categories.filter(filterSections), searchOnCategory),
    admissionCategories: searchItems(
      admission?.categories
        .filter(({ type }) => type === CATEGORY_TYPES.CATEGORY)
        .filter(filterSections),
      searchOnCategory,
    ),
    contacts: searchContacts,
  };

  const initialResults = _.clone(results);

  const updatedResults = innerSearch(results, term, state);

  const mapTags = (resultItems, type) => {
    resultItems
      .filter(type)
      .filter(filtersByTags)
      .forEach(item => addToMap(tagsMap, { ...item?.tags, labels: item?.labels }));
  };

  mapTags(updatedResults.knowledge, filterByExpiryDate);
  mapTags(updatedResults.lab, () => true);
  mapTags(updatedResults.admission, () => true);
  mapTags(updatedResults.knowledgeCategories, () => true);
  mapTags(updatedResults.labCategories, () => true);
  mapTags(updatedResults.admissionCategories, () => true);

  const mapResultItems = (resultItems, typeKey) =>
    resultItems.map(item => ({ item, type: typeKey }));

  updatedResults.knowledge = mapResultItems(updatedResults.knowledge, SEARCH_TYPES_KEYS.documents);
  updatedResults.lab = mapResultItems(updatedResults.lab, SEARCH_TYPES_KEYS.compendium);
  updatedResults.admission = mapResultItems(updatedResults.admission, SEARCH_TYPES_KEYS.admission);
  updatedResults.knowledgeCategories = mapResultItems(
    updatedResults.knowledgeCategories,
    SEARCH_TYPES_KEYS.category,
  );
  updatedResults.labCategories = mapResultItems(
    updatedResults.labCategories,
    SEARCH_TYPES_KEYS.category,
  );
  updatedResults.admissionCategories = mapResultItems(
    updatedResults.admissionCategories,
    SEARCH_TYPES_KEYS.category,
  );

  updatedResults.contacts = mapResultItems(updatedResults.contacts, SEARCH_TYPES_KEYS.contacts);

  updatedResults.tagsMap = tagsMap;

  return { initialResults, results: updatedResults };
};

/**
 * Improved search mechanism using Fuse.js
 *
 * @param {Object} state
 * @param {String} term
 * @param {{}} filters
 * @return {{
 *  initialResults: Object,
 *  results: Object[],
 * }}
 */
export const improvedSearchMechanism = (state, term, filters) => {
  const departmentLang = state.lang['TAGS'] || {};
  const tagsMap = {
    specialty: {},
    subspecialty: {},
    contentType: {},
    targetAudience: {},
    labels: {},
  };
  const results = {};
  const contacts =
    getContacts(state)?.map(contact => ({
      ...contact,
      department: departmentLang[contact?.professional?.department],
    })) || [];

  const { IMPROVED_SEARCH_CONFIG = {} } = state.generalConfig;
  const createFuse = (items, config) => new Fuse(items || [], config || {});

  const knowledgeFuse = createFuse(state.knowledge?.items, IMPROVED_SEARCH_CONFIG?.KNOWLEDGE);
  const labFuse = createFuse(state.lab?.items, IMPROVED_SEARCH_CONFIG?.LAB);
  const admissionFuse = createFuse(
    state.admission?.categories?.filter(item => item?.type !== CATEGORY_TYPES.CATEGORY),
    IMPROVED_SEARCH_CONFIG?.CATEGORY,
  );
  const knowledgeCategoriesFuse = createFuse(
    state.knowledge?.categories.filter(filterSections),
    IMPROVED_SEARCH_CONFIG?.CATEGORY,
  );
  const labCategoriesFuse = createFuse(
    state.lab?.categories.filter(filterSections),
    IMPROVED_SEARCH_CONFIG?.CATEGORY,
  );
  const admissionCategoriesFuse = createFuse(
    state.admission?.categories
      ?.filter(item => item?.type === CATEGORY_TYPES.CATEGORY)
      .filter(filterSections),
    IMPROVED_SEARCH_CONFIG?.CATEGORY,
  );
  const contactsFuse = createFuse(contacts, IMPROVED_SEARCH_CONFIG?.CONTACTS);

  const filtersByTags = filterByTags({ filters });
  const convertScoreToPercentage = score => (score * 100).toFixed(3);

  const createSearchResults = (fuse, typeKey, propName = 'title') => {
    if (isValidObjectId(term)) {
      // Perform exact match
      return fuse._docs
        .filter(item => item.id === getIdFromTerm(term))
        .map(item => ({
          item,
          type: typeKey,
          score: 0, // 0 is best possible score, perfect match
        }));
    } else {
      // Perform fuzzy search
      return fuse.search(term).map(({ item, score }) => ({
        item: SHOW_SCORE
          ? { ...item, [propName]: `${item[propName]} (${convertScoreToPercentage(score)})` }
          : item,
        type: typeKey,
        score,
      }));
    }
  };

  results.knowledge = createSearchResults(knowledgeFuse, SEARCH_TYPES_KEYS.documents);
  results.lab = createSearchResults(labFuse, SEARCH_TYPES_KEYS.compendium);
  results.admission = createSearchResults(admissionFuse, SEARCH_TYPES_KEYS.admission);
  results.knowledgeCategories = createSearchResults(
    knowledgeCategoriesFuse,
    SEARCH_TYPES_KEYS.category,
  );
  results.labCategories = createSearchResults(labCategoriesFuse, SEARCH_TYPES_KEYS.category);
  results.admissionCategories = createSearchResults(
    admissionCategoriesFuse,
    SEARCH_TYPES_KEYS.category,
  );
  results.contacts = createSearchResults(contactsFuse, SEARCH_TYPES_KEYS.contacts, 'firstName');

  const initialResults = _.clone(results);

  const mapTags = (resultItems, doFilter) => {
    resultItems
      .filter(({ item }) => doFilter(item) && filtersByTags(item))
      .forEach(({ item }) => addToMap(tagsMap, { ...item?.tags, labels: item?.labels }));
  };

  mapTags(results.knowledge, filterByExpiryDate);
  mapTags(results.lab, () => true);
  mapTags(results.admission, () => true);
  mapTags(results.knowledgeCategories, () => true);
  mapTags(results.labCategories, () => true);
  mapTags(results.admissionCategories, () => true);

  results.tagsMap = tagsMap;

  return { initialResults, results };
};

/**
 * Search for term in knowledge, lab, admission, and contacts
 * @param {Object} state
 * @param {String} term
 * @param {Object} filters
 * @returns {{
 * initialResults: {
 *  knowledge: Object[],
 *  lab: Object[],
 *  admission: Object[],
 *  knowledgeCategories: Object[],
 *  labCategories: Object[],
 *  admissionCategories: Object[],
 *  contacts: Object[],
 * },
 * results: {
 *  knowledge: Object[],
 *  lab: Object[],
 *  admission: Object[],
 *  knowledgeCategories: Object[],
 *  labCategories: Object[],
 *  admissionCategories: Object[],
 *  contacts: Object[],
 *  tagsMap: {
 *    specialty: { [key: string]: number },
 *    subspecialty: { [key: string]: number },
 *    contentType: { [key: string]: number },
 *    targetAudience: { [key: string]: number },
 *    labels: { [key: string]: number },
 *  },
 *  extendedSearch?: {
 *    knowledge: Object[],
 *    lab: Object[],
 *    admission: Object[],
 *    knowledgeCategories: Object[],
 *    labCategories: Object[],
 *    admissionCategories: Object[],
 *    contacts: Object[],
 *    tagsMap: {
 *      specialty: { [key: string]: number },
 *      subspecialty: { [key: string]: number },
 *      contentType: { [key: string]: number },
 *      targetAudience: { [key: string]: number },
 *      labels: { [key: string]: number },
 *    },
 *  },
 * }
 * }}
 * }
 */
export const search = (state, term, filters) => {
  const isImprovedSearch = isImprovedSearchEnabled(state);
  const search = oldSearchMechanism(state, term, filters);

  if (isImprovedSearch) {
    const improvedSearch = improvedSearchMechanism(state, term, filters);
    const { results } = search;
    const { results: improvedResults } = improvedSearch;

    results.extendedSearch = {
      ...improvedResults,
      knowledge: _.differenceBy(improvedResults.knowledge, results.knowledge, 'item.id'),
      lab: _.differenceBy(improvedResults.lab, results.lab, 'item.id'),
      admission: _.differenceBy(improvedResults.admission, results.admission, 'item.id'),
      knowledgeCategories: _.differenceBy(
        improvedResults.knowledgeCategories,
        results.knowledgeCategories,
        'item.id',
      ),
      labCategories: _.differenceBy(
        improvedResults.labCategories,
        results.labCategories,
        'item.id',
      ),
      admissionCategories: _.differenceBy(
        improvedResults.admissionCategories,
        results.admissionCategories,
        'item.id',
      ),
      contacts: _.differenceBy(improvedResults.contacts, results.contacts, 'item.id'),
    };

    results.tagsMap = _.mergeWith(results.tagsMap, improvedResults.tagsMap, (obj, src) =>
      _.isNumber(obj) ? src + Math.abs(obj - src) : undefined,
    );
  }

  return search;
};

export const isOnSearch = ({ search }) => {
  return search.isOnSearch;
};
