import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { INITIAL_STATE, NOTIFICATION_OPEN_PROMPTED, NOTIFICATION_UPDATE_STATE } from '../actions';

const initialState = {
  userSubscriptionEnabled: null,
  hasPrompted: true,
  notificationsEnabled: false,
  subscriptionEnabled: null,
  hasInnerPrompted: null,
  lastInnerPrompted: null,
};

const setInitialState = (state, { notification = initialState }) =>
  update(state, {
    $merge: notification,
  });

const updateState = (state, payload) =>
  update(state, {
    $merge: payload,
  });

const setLastInnerPrompted = state =>
  update(state, {
    $merge: {
      hasInnerPrompted: true,
      lastInnerPrompted: Date.now(),
    },
  });

const actionsRepo = {
  [INITIAL_STATE]: setInitialState,
  [NOTIFICATION_UPDATE_STATE]: updateState,
  [NOTIFICATION_OPEN_PROMPTED]: setLastInnerPrompted,
};

export default createReducer({ initialState, actionsRepo });
