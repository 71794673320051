import update from 'immutability-helper';
import { OAUTH_CONFIGURATIONS_UPDATE } from '../actions';
import { REMOTE_CONFIG_DEFAULT, REMOTE_CONFIG_KEYS } from '../../constants';
import { createReducer } from '../../utils/redux/reducers.utils';

const initialState = REMOTE_CONFIG_DEFAULT[REMOTE_CONFIG_KEYS.OAUTH_CONFIGURATIONS];

const updateOauthConfigStatus = (state, payload) =>
  update(state, {
    $set: payload,
  });

const actionsRepo = {
  [OAUTH_CONFIGURATIONS_UPDATE]: updateOauthConfigStatus,
};

export default createReducer({ initialState, actionsRepo });
