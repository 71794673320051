import authenticationServiceApi from '../api/authentication.service.api';
import { updateToken } from '../store/actions/auth.actions';
import { ServiceBase } from './service.base';
import jwtDecode from 'jwt-decode';

class AuthService extends ServiceBase {
  constructor(props) {
    super(props);
    this.reducer = 'auth';
  }

  /**
   * Refresh access token
   * @returns Boolean - true if succeeded
   * @memberof AuthService
   */
  async refreshToken() {
    const refreshToken = this.state?.refreshToken || null;

    let data;
    try {
      const res = await authenticationServiceApi.refreshToken({
        refreshToken,
      });

      data = res?.data;

      if (res.error) {
        console.log(`Fail to refresh access token - ${data.message}`);
        return false;
      }
    } catch (e) {
      console.log(`Fail to refresh access token - ${e}`);
      return false;
    }

    console.log('Token refresh - Succeeded');

    this.dispatch(updateToken(data));
    return true;
  }

  /**
   * Check if token is expired
   * @private
   * @param {*} { token }
   * @returns Boolean - true if expired or no token
   * @memberof AuthService
   */
  _isTokenExpired({ token }) {
    if (!token) {
      return true;
    }
    const { exp } = jwtDecode(token);
    return 1000 * exp - Date.now() < 5000;
  }
}
export default new AuthService();
