import { connect } from 'react-redux';
import { fetchKnowledgeItemContent } from 'shared/store/actions/knowledge.items.actions';
import { getKnowledgeItemById } from 'shared/store/selectors/knowledge.selector';
import { AddKeywordsComponent } from './add-keywords.component';
import { addKeywordsToKnowledgeItem } from 'shared/store/actions/knowledge.actions';

const mapStateToProps = ({ lang, knowledge }, { id }) => {
  const item = getKnowledgeItemById({ knowledge }, id);

  return {
    ...item,
    lang: lang.ADD_KEYWORDS,
  };
};

export const AddKeywords = connect(mapStateToProps, {
  fetchKnowledgeItemContent,
  addKeywords: addKeywordsToKnowledgeItem,
})(AddKeywordsComponent);
