import React, { useCallback } from 'react';
import { ChecklistIcon, NewTabIcon } from '../../images';
import { Tags } from '../tags';
import sharedStyles from './items.module.scss';
import { appURL } from 'shared/constants/APIconstants';
import classnames from 'classnames';
import analyticsService from '../../helpers/analytics.service';
import { EVENTS, ENTITY_TYPES } from 'shared/constants';
import { WIDGET_REDIRECT_QUERY_PARAM } from 'shared/constants';

const ChecklistItemComponent = React.memo(function ChecklistItemComponent({
  id,
  title,
  tags,
  onClickEvent = () => {},
}) {
  const link = `${appURL}admission?checklist=${id}&${WIDGET_REDIRECT_QUERY_PARAM}`;

  const onOpen = useCallback(() => {
    const event = {
      id,
      title,
      ...tags,
    };

    // Trigger event
    onClickEvent(event);

    analyticsService.track(EVENTS.CHECKLISTS.OPEN, event);
    analyticsService.track(EVENTS.CONTENT.OPEN, {
      ...event,
      entityType: ENTITY_TYPES.compendium,
    });
  }, [id, title, tags, onClickEvent]);

  return (
    <a href={link} target="_blank" rel="noreferrer" onClick={onOpen}>
      <div key={id} className={classnames([sharedStyles.item, sharedStyles.clickable])}>
        <div className={sharedStyles.wrapper}>
          <img className={sharedStyles.icon} src={ChecklistIcon} alt={'checklist'} />
          <div>
            <h4>{title}</h4>
            <Tags tags={tags} />
          </div>
        </div>
        <img className={sharedStyles.newTabIcon} src={NewTabIcon} alt={title} />
      </div>
    </a>
  );
});

export { ChecklistItemComponent };
