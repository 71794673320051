import {
  CONTACTS_CREATE,
  CONTACTS_DELETE,
  CONTACTS_UPDATE_CONTACT,
  CONTACTS_UPDATE_LIST,
  CONTACTS_USE_CACHE,
} from '.';
import contactService from '../../api/contact.service.api';
import { isContactsFeatureEnabled } from '../selectors/features.selector';
import { isConnected } from '../selectors/network.selector';

export const fetchContacts = () => async (dispatch, getState) => {
  let state = getState();

  if (!isContactsFeatureEnabled(state)) {
    return false;
  }

  if (!isConnected(state)) {
    return dispatch({
      type: CONTACTS_USE_CACHE,
    });
  }

  const { data, error } = await contactService.fetch();

  if (error) {
    return dispatch({
      type: CONTACTS_USE_CACHE,
    });
  }

  const { contacts = [] } = data || {};

  const contactsById = contacts.reduce((acc, contact) => {
    acc[contact.id] = contact;
    return acc;
  }, {});

  dispatch({
    type: CONTACTS_UPDATE_LIST,
    payload: {
      contactsById,
    },
  });
};

export const createContact =
  ({ contact }) =>
  async dispatch => {
    const { error, data } = await contactService.createContact({ contact });

    if (error) {
      console.log('Failed to create contact');
      return {
        error: data.message.includes('already exists')
          ? { alreadyExists: [contact.email] }
          : data.message,
      };
    }

    dispatch({
      type: CONTACTS_CREATE,
      payload: data,
    });

    return data;
  };

export const updateContact =
  ({ id, contact }) =>
  async dispatch => {
    const { data, error } = await contactService.updateContact({ id, contact });

    if (error) {
      console.log('Failed to update contact');
      return { error: data.message };
    }

    dispatch({
      type: CONTACTS_UPDATE_CONTACT,
      payload: { id, user: data?.contact },
    });

    return data;
  };

export const deleteContact =
  ({ id }) =>
  async dispatch => {
    const { error, data } = await contactService.deleteContact({ id });

    if (error) {
      console.log('Failed to delete contact');
      return { error: data.message };
    }

    dispatch({
      type: CONTACTS_DELETE,
      payload: { id },
    });
  };
