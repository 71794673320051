export const ISSUE_REPORT_PROBLEMS = {
  INCORRECT_CONTENT: 'INCORRECT_CONTENT',
  LAYOUT_ERRORS: 'LAYOUT_ERRORS',
  MISSING_PARTS: 'MISSING_PARTS',
  READABILITY: 'READABILITY',
  OTHER: 'OTHER',
};

export const USER_FEEDBACK = {
  MISSING_CONTENT: 'MISSING_CONTENT',
  UNREADABLE_CONTENT: 'UNREADABLE_CONTENT',
  TECHNICAL_ISSUE: 'TECHNICAL_ISSUE',
  SUGGESTION: 'SUGGESTION',
  QUESTION: 'QUESTION',
  OTHER: 'OTHER',
};

export const ISSUE_TYPES = {
  BROKEN_FORMAT: 'BROKEN_FORMAT',
  GIVE_FEEDBACK: 'GIVE_FEEDBACK',
};

export const ISSUE_REPORT_ORIGIN = {
  KNOWLEDGE_BASE: 'KNOWLEDGE_BASE',
  KNOWLEDGE_HUB: 'KNOWLEDGE_HUB',
};
