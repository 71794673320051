import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { INITIAL_STATE, UPDATE_DEVICE_FLAGS } from '../actions';

const initialState = {};

const updateDeviceFlagValue = (state, payload) =>
  update(state, {
    $merge: payload,
  });

const loadInitialState = (state, { deviceFlags = initialState }) =>
  update(state, {
    $merge: deviceFlags,
  });

const actionsRepo = {
  [INITIAL_STATE]: loadInitialState,
  [UPDATE_DEVICE_FLAGS]: updateDeviceFlagValue,
};

export default createReducer({ initialState, actionsRepo });
