import { connect } from 'react-redux';
import { getCategoriesById, getCategoryById } from 'shared/store/selectors/knowledge.selector';
import { KnowledgeSectionComponent } from './knowledge-section.component';

const mapStateToProps = ({ knowledge }, { categoryId }) => ({
  ...getCategoryById(knowledge, { id: categoryId }),
  subcategories: getCategoriesById(knowledge, categoryId) ?? [],
});

export const KnowledgeSection = connect(mapStateToProps)(KnowledgeSectionComponent);
