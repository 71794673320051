import userService from '../../services/user.service';
import {
  APPLICATION_REFRESH_DATE,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
  CONTACTS_CREATE,
  CONTACTS_DELETE,
  CONTACTS_UPDATE_CONTACT,
  ORGANIZATION_SWITCH,
  ORGANIZATION_UPDATE,
} from '../actions';
import { fetchContacts } from '../actions/contacts.actions';

export const contactsFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE ||
      action.type === ORGANIZATION_SWITCH ||
      action.type === ORGANIZATION_UPDATE ||
      action.type === CONTACTS_CREATE ||
      action.type === CONTACTS_UPDATE_CONTACT ||
      action.type === CONTACTS_DELETE
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(fetchContacts());
    }
  };

export const contactsMiddleware = [contactsFetchDataMiddleware];
