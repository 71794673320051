import { ServiceBase } from './service.base';

class WorkspaceService extends ServiceBase {
  constructor(props) {
    super(props);
    this.reducer = 'workspace';
  }

  getSelectedWorkspaceId() {
    return this.state.selected;
  }

  hasWorkspaces() {
    return this.state.list.length > 0;
  }
}

export default new WorkspaceService();
