import React, { useContext } from 'react';
import { Lang } from 'shared/constants';
import styles from './tags.module.scss';
import ApplicationContext from '../../context/application.context';

const TagsComponent = React.memo(function TagsComponent({ tags = null, lang = Lang.TAGS }) {
  const { hideSpecialtiesTags } = useContext(ApplicationContext);

  if (!tags) {
    return;
  }

  const getTagLabel = tag => lang[tag];

  return (
    <div className={styles.tags}>
      {tags.contentType && <h5>{getTagLabel(tags.contentType)}</h5>}
      {!hideSpecialtiesTags && tags.specialty && <h5>{getTagLabel(tags.specialty)}</h5>}
      {!hideSpecialtiesTags && tags.subspecialty && <h5>{getTagLabel(tags.subspecialty)}</h5>}
    </div>
  );
});
export { TagsComponent };
