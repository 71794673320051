import _ from 'lodash';
import { getKnowledgeItemById } from './knowledge.selector';
import { FAVORITES_ENTITIES } from '../../constants';
import { getContactItemById } from './contact.selector';
import workspaceService from '../../services/workspace.service';

const FAVORITES_ENTITY_SELECTOR_MAPPER = {
  [FAVORITES_ENTITIES.KNOWLEDGE]: {
    selector: getKnowledgeItemById,
  },
  [FAVORITES_ENTITIES.CONTACT]: {
    selector: getContactItemById,
  },
  [FAVORITES_ENTITIES.USER]: {
    selector: getContactItemById,
  },
};

export const isItemFavorite = (favoritesState, { id: itemId }) =>
  !_.isUndefined(favoritesState.byId[itemId]) &&
  _.isUndefined(favoritesState.byId[itemId].toDelete);

export const getFavoritesItems = ({ favorites, knowledge, contacts, usersManagement }) => {
  const workspaceId = workspaceService.getSelectedWorkspaceId();
  const items = [];

  if (_.isEmpty(favorites.byId) || _.isNull(knowledge.itemsById)) {
    return;
  }

  Object.keys(favorites.byId).forEach(id => {
    if (favorites.byId[id].toDelete) {
      return;
    }

    const item = getFavoritesItemByEntityAndId(
      { favorites, knowledge, contacts, usersManagement },
      { entity: favorites.byId[id].entity, itemId: favorites.byId[id].itemId },
    );

    if (item && favorites.byId[id].workspaceId === workspaceId) {
      items.push({ ...item, entity: favorites.byId[id].entity });
    }
  });

  return items;
};

export const getFavoriteByItemId = (favorites, { itemId }) => favorites.byId[itemId];

export const getFavoritesItemByEntityAndId = (state, { entity, itemId }) => {
  const { selector } = FAVORITES_ENTITY_SELECTOR_MAPPER[entity];
  return selector(state, itemId) || null;
};
