import { Executer } from '../utils/Executer';
import { REGIONS } from '../constants/region.constants';
import { getAssetsServiceBaseURL, getBaseURL } from '../constants/APIconstants';

class API {
  constructor() {
    this.executers = {
      [REGIONS.US]: new Executer({ baseURL: getBaseURL(REGIONS.US) }),
      [REGIONS.EU]: new Executer({ baseURL: getBaseURL(REGIONS.EU) }),
    };
    this.assetsExecuters = {
      [REGIONS.US]: new Executer({ baseURL: getAssetsServiceBaseURL(REGIONS.US) }),
      [REGIONS.EU]: new Executer({ baseURL: getAssetsServiceBaseURL(REGIONS.EU) }),
    };

    //default region
    /** @type {any} */
    this.client = this.executers[REGIONS.US];
    this.assetsClient = this.assetsExecuters[REGIONS.US];
  }

  get execute() {
    return this.client.execute;
  }

  getAPIRegions() {
    return Object.keys(this.executers);
  }

  setClientByRegion(region) {
    this.client = this.executers[region] ?? this.executers[REGIONS.US];
    this.assetsClient = this.assetsExecuters[region] ?? this.assetsExecuters[REGIONS.US];
  }

  setToken(token, enforce = false) {
    this.client.setAccessToken(token, enforce);
    this.assetsClient.setAccessToken(token, enforce);
  }
}

export default new API();
