import { connect } from 'react-redux';
import { KnowledgeContentComponent } from './knowledge-content.component';
import { fetchKnowledgeItemContent } from 'shared/store/actions/knowledge.items.actions';
import { getKnowledgeItemById } from 'shared/store/selectors/knowledge.selector';
import { getOwnersByIDs } from 'shared/store/selectors/owners.selector';

const mapStateToProps = ({ lang, knowledge, owners }, { id }) => {
  const item = getKnowledgeItemById({ knowledge }, id);

  return {
    ...item,
    lang: lang.WIDGET,
    langTags: lang.TAGS,
    langTagsPlaceholder: lang.TAGS_FORM,
    langEditor: lang.KNOWLEDGE_EDITOR,
    owners: getOwnersByIDs(item?.ownerIds || [])({ owners }) || [],
  };
};

export const KnowledgeContent = connect(mapStateToProps, { fetchKnowledgeItemContent })(
  KnowledgeContentComponent,
);
