import {
  COMMUNICATION_DELETE_ITEM,
  COMMUNICATION_MESSAGE_SEND,
  COMMUNICATION_UPDATE_CONTENT,
  COMMUNICATION_USE_CACHE,
} from '.';
import communicationServiceApi from '../../api/communication.service.api';
import { isConnected } from '../selectors/network.selector';

export const sendNotification =
  ({ data }) =>
  async dispatch => {
    const res = await communicationServiceApi.sendNotification({
      data,
    });

    if (res.error) {
      // TODO: add monitor
      return { error: res.error };
    }

    dispatch({
      type: COMMUNICATION_MESSAGE_SEND,
    });

    return {};
  };

export const getCommunicationContent = () => async (dispatch, getState) => {
  let state = getState();

  if (!isConnected(state)) {
    return dispatch({
      type: COMMUNICATION_USE_CACHE,
    });
  }

  let newsFeedItemsRes = await communicationServiceApi.getNewsFeedItems();

  if (newsFeedItemsRes.error) {
    return dispatch({
      type: COMMUNICATION_USE_CACHE,
    });
  }
  const { messages } = newsFeedItemsRes.data;
  dispatch({
    type: COMMUNICATION_UPDATE_CONTENT,
    payload: {
      newsFeedItems: messages,
    },
  });
};

export const deleteCommunicationItem =
  ({ id }) =>
  async dispatch => {
    let { error } = await communicationServiceApi.deleteItem({ id });

    if (error) {
      return;
    }

    dispatch({
      type: COMMUNICATION_DELETE_ITEM,
      payload: {
        id,
      },
    });
  };

export const reportIssue =
  ({ data }) =>
  async () => {
    const res = await communicationServiceApi.reportIssue({ data });

    if (res.error) {
      return { error: res.error };
    }
    return {};
  };

export const giveFeedback =
  ({ data }) =>
  async () => {
    const res = await communicationServiceApi.giveFeedback({ data });

    if (res.error) {
      return { error: res.error };
    }
    return {};
  };
