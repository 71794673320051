import { DEVICE_FLAGS } from '../../constants/deviceFlags';
import { DEFAULT_LANGUAGE, LANGUAGE } from '../../constants/lang';
import { AUTH_LOGIN, AUTH_UPDATE_USER, LANG_MANAGER_UPDATE_REPO } from '../actions';
import { setLang, updateLang } from '../actions/lang.actions';
import { getDeviceFlag } from '../selectors/device-flag.selector';

export const langUpdateOnRepoUpdate =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    next(action);

    if (action.type === LANG_MANAGER_UPDATE_REPO) {
      const FLAG = DEVICE_FLAGS.LANGUAGE;
      const { deviceFlags, auth } = getState();
      let languageKey =
        auth.user?.language || getDeviceFlag(deviceFlags, { key: FLAG }) || DEFAULT_LANGUAGE;

      const { repo } = action.payload;
      const { lang } =
        repo[languageKey.toLowerCase()] ||
        LANGUAGE[languageKey.toLowerCase()] ||
        LANGUAGE[DEFAULT_LANGUAGE];

      dispatch(updateLang({ lang }));
    }
  };

export const langUpdateOnUserUpdate =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === AUTH_UPDATE_USER || action.type === AUTH_LOGIN) {
      if (!action.payload.user.language) {
        return;
      }

      dispatch(setLang({ key: action.payload.user.language }));
    }
  };

export const langMiddleware = [langUpdateOnRepoUpdate, langUpdateOnUserUpdate];
