import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import analyticsService from '../../helpers/analytics.service';
import remoteConfigService from '../../helpers/remote-config.service';
import { fetchData } from '../../store/actions/application.actions';
import { registerApiKey } from '../../store/actions/auth.actions';
import { Widget } from '../widget';
import { EVENTS } from 'shared/constants';
import { v4 as uuidv4 } from 'uuid';
import { IP_RANGE_INVALID } from 'shared/constants/applicationErrorCodes';

// Set global
global.platform = 'web';

const App = React.memo(({ registerApiKey, fetchData }) => {
  const [notAllowedIp, setNotAllowedIp] = useState(false);
  const searchParams = new URLSearchParams(document.location.search);
  const apiKey = searchParams.get('wid');
  const showTags = searchParams.get('showTags') === 'true';

  let storedDeviceId = localStorage.getItem('deviceId');

  if (!storedDeviceId) {
    storedDeviceId = uuidv4();
    localStorage.setItem('deviceId', storedDeviceId);
  }

  useEffect(() => {
    const init = async () => {
      const { error, userId } = await registerApiKey({ apiKey });

      if (error?.code === IP_RANGE_INVALID) {
        setNotAllowedIp(true);
        return;
      }

      await remoteConfigService.init();
      await analyticsService.init();

      if (userId) {
        analyticsService.setUserId(userId);
      }

      analyticsService.track(EVENTS.APPLICATION.OPEN);

      fetchData();
    };
    init();
  });

  return <Widget showTags={showTags} notAllowedIp={notAllowedIp} />;
});

export default connect(null, { registerApiKey, fetchData })(App);
