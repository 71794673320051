import { LANGUAGE } from '../../constants/lang';
import { createReducer } from '../../utils/redux/reducers.utils';
import { LANG_MANAGER_UPDATE_REPO } from '../actions';
import update from 'immutability-helper';

const initialState = { repo: LANGUAGE };

const updateLang = (state, { repo }) =>
  update(state, {
    repo: { $set: repo },
  });

const actionsRepo = {
  [LANG_MANAGER_UPDATE_REPO]: updateLang,
};

export default createReducer({ initialState, actionsRepo });
