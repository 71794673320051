import userService from '../../services/user.service';
import {
  APPLICATION_REFRESH_DATE,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
  ORGANIZATION_SWITCH,
  ORGANIZATION_UPDATE,
  OWNERS_UPDATE,
  USERS_MGMT_INVITE_USERS,
} from '../actions';
import { fetchUsers } from '../actions/users-management.actions';

export const onInviteFriendsMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === USERS_MGMT_INVITE_USERS) {
      dispatch(fetchUsers());
    }
  };

export const usersFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE ||
      action.type === ORGANIZATION_SWITCH ||
      action.type === ORGANIZATION_UPDATE ||
      (action.type === OWNERS_UPDATE && action.payload.isUpdate)
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(fetchUsers());
    }
  };
export const usersManagementMiddleware = [onInviteFriendsMiddleware, usersFetchDataMiddleware];
