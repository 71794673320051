// FILE IS SORTED ALPHABETICALLY, PLEASE KEEP IT THAT WAY

const DIVISION = {
  CARDIAC: 'CARDIAC',
  COMMUNITY: 'COMMUNITY',
  CRITICAL_CARE: 'CRITICAL_CARE',
  CVICU: 'CVICU',
  EDUCATION: 'EDUCATION',
  GENERAL: 'GENERAL',
  LCSW: 'LCSW',
  LEADERSHIP: 'LEADERSHIP',
  OB: 'OB',
  PAIN: 'PAIN',
  PAT: 'PAT',
  PEDIATRIC: 'PEDIATRIC',
  RAAPS: 'RAAPS',
  RESEARCH: 'RESEARCH',
  VA: 'VA',
};

const SPECIALTY = {
  ADDICTION_MEDICINE: 'ADDICTION_MEDICINE',
  ADULT_ANESTHESIA: 'ADULT_ANESTHESIA',
  CARDIAC_ANESTHESIA: 'CARDIAC_ANESTHESIA',
  CRITICAL_CARE: 'CRITICAL_CARE',
  EDUCATION: 'EDUCATION',
  EMERGENCY_MEDICINE: 'EMERGENCY_MEDICINE',
  GENERAL_ANESTHESIA: 'GENERAL_ANESTHESIA',
  MCP: 'MCP',
  NEUROANESTHESIA: 'NEUROANESTHESIA',
  OBSTETRIC_ANESTHESIA: 'OBSTETRIC_ANESTHESIA',
  PALLIATIVE_CARE: 'PALLIATIVE_CARE',
  PAIN_MANAGEMENT: 'PAIN_MANAGEMENT',
  PEDIATRIC_ANESTHESIA: 'PEDIATRIC_ANESTHESIA',
  PEDIATRIC_CARDIAC_ANESTHESIA: 'PEDIATRIC_CARDIAC_ANESTHESIA',
  PEDIATRIC_PAIN_MANAGEMENT: 'PEDIATRIC_PAIN_MANAGEMENT',
  PSYCHOLOGY: 'PSYCHOLOGY',
  QUALITY: 'QUALITY',
  RAAPS: 'RAAPS',
  RESEARCH: 'RESEARCH',
  REGIONAL_ANESTHESIA: 'REGIONAL_ANESTHESIA',
  SIMULATION: 'SIMULATION',
  TRAUMA_ANESTHESIA: 'TRAUMA_ANESTHESIA',
  TRANSPLANT_ANESTHESIA: 'TRANSPLANT_ANESTHESIA',
};

const PROFESSIONS = {
  ADMINISTRATIVE_ASSISTANT: 'ADMINISTRATIVE_ASSISTANT',
  ADMINISTRATOR: 'ADMINISTRATOR',
  ADVANCED_PRACTICE_NURSE: 'ADVANCED_PRACTICE_NURSE',
  ANESTHESIA_ASSISTANT: 'ANESTHESIA_ASSISTANT',
  ANESTHESIA_TECHNICIAN: 'ANESTHESIA_TECHNICIAN',
  APP: 'APP',
  CAA: 'CAA',
  CAREGIVER: 'CAREGIVER',
  CARDIOTECHNICIAN: 'CARDIOTECHNICIAN',
  CHILD_LIFE: 'CHILD_LIFE',
  CRNA: 'CRNA',
  FACULTY: 'FACULTY',
  FELLOW: 'FELLOW',
  GENETIC_COUNSELOR: 'GENETIC_COUNSELOR',
  LOGISTICS_OPERATIONS: 'LOGISTICS_OPERATIONS',
  MEDICAL_ASSISTANT: 'MEDICAL_ASSISTANT',
  MEDICAL_DIRECTOR: 'MEDICAL_DIRECTOR',
  MEDICAL_DOCTOR: 'MEDICAL_DOCTOR',
  MIDWIFE: 'MIDWIFE',
  MSA_STUDENT: 'MSA_STUDENT',
  NURSE: 'NURSE',
  NURSE_PRACTITIONER: 'NURSE_PRACTITIONER',
  OPERATING_ROOM_INSTRUMENTALIST: 'OPERATING_ROOM_INSTRUMENTALIST',
  PARAMEDIC: 'PARAMEDIC',
  PHARMACIST: 'PHARMACIST',
  PHYSICIAN_ASSISTANT: 'PHYSICIAN_ASSISTANT',
  PHYSIOTHERAPIST: 'PHYSIOTHERAPIST',
  REGISTERED_DIETICIAN: 'REGISTERED_DIETICIAN',
  REGISTERED_NURSE: 'REGISTERED_NURSE',
  RESEARCHER: 'RESEARCHER',
  RESPIRATORY_THERAPIST: 'RESPIRATORY_THERAPIST',
  SKILLED_CAREGIVER: 'SKILLED_CAREGIVER',
  SKILLED_TECHNICIAN: 'SKILLED_TECHNICIAN',
  SOCIAL_WORKER: 'SOCIAL_WORKER',
};

const ROLES_ADMINISTRATOR = {
  ADMINISTRATIVE_ASSISTANT_ANESTHETISTS: 'ADMINISTRATIVE_ASSISTANT_ANESTHETISTS',
  ADMINISTRATIVE_ASSISTANT_PEDIATRIC_DIVISION: 'ADMINISTRATIVE_ASSISTANT_PEDIATRIC_DIVISION',
  ADMINISTRATIVE_ASSOCIATE_CHAIR: 'ADMINISTRATIVE_ASSOCIATE_CHAIR',
  ADMINISTRATIVE_COORDINATOR: 'ADMINISTRATIVE_COORDINATOR',
  ADMINISTRATIVE_COORDINATOR_FINANCE: 'ADMINISTRATIVE_COORDINATOR_FINANCE',
  ADMINISTRATIVE_COORDINATOR_STAFFING_SCHEDULING: 'ADMINISTRATIVE_COORDINATOR_STAFFING_SCHEDULING',
  ASSOCIATE_DEPARTMENT_ADMINISTRATOR_OPERATIONS: 'ASSOCIATE_DEPARTMENT_ADMINISTRATOR_OPERATIONS',
  BUSINESS_OPERATIONS_COORDINATOR_PEDIATRIC_DIVISION:
    'BUSINESS_OPERATIONS_COORDINATOR_PEDIATRIC_DIVISION',
  BUSINESS_OPERATIONS_COORDINATOR_SR: 'BUSINESS_OPERATIONS_COORDINATOR_SR',
  CLINICAL_PROGRAM_COORDINATOR_II: 'CLINICAL_PROGRAM_COORDINATOR_II',
  CLINICAL_PROGRAM_COORDINATOR_III: 'CLINICAL_PROGRAM_COORDINATOR_III',
  COMMUNICATION_SPECIALIST: 'COMMUNICATION_SPECIALIST',
  CSG_ADMINISTRATOR_ANESTHESIOLOGY_AND_DIAGNOSTICS:
    'CSG_ADMINISTRATOR_ANESTHESIOLOGY_AND_DIAGNOSTICS',
  DEPARTMENT_ADMINISTRATOR: 'DEPARTMENT_ADMINISTRATOR',
  DIVISION_ADMINISTRATOR_I: 'DIVISION_ADMINISTRATOR_I',
  DIVISION_ADMINISTRATOR_III: 'DIVISION_ADMINISTRATOR_III',
  EDUCATION_PROGRAM_COORDINATOR_II_FELLOWS: 'EDUCATION_PROGRAM_COORDINATOR_II_FELLOWS',
  EDUCATION_PROGRAM_COORDINATOR_II_INTERNS: 'EDUCATION_PROGRAM_COORDINATOR_II_INTERNS',
  EDUCATION_PROGRAM_COORDINATOR_II_RESIDENTS: 'EDUCATION_PROGRAM_COORDINATOR_II_RESIDENTS',
  EDUCATION_PROGRAM_COORDINATOR_III_MSA: 'EDUCATION_PROGRAM_COORDINATOR_III_MSA',
  PROGRAM_COORDINATOR_II_EDUCATION_DIVISION: 'PROGRAM_COORDINATOR_II_EDUCATION_DIVISION',
  PROGRAM_MANAGER_MSA: 'PROGRAM_MANAGER_MSA',
  PROGRAM_MANAGER_QUALITY: 'PROGRAM_MANAGER_QUALITY',
  PROGRAM_MANAGER_SPECIALTY_DIVISIONS: 'PROGRAM_MANAGER_SPECIALTY_DIVISIONS',
  RESIDENT_LEARNER_COORDINATOR: 'RESIDENT_LEARNER_COORDINATOR',
  UNIT_EXECUTIVE_ADMINISTRATOR: 'UNIT_EXECUTIVE_ADMINISTRATOR',
  UNIT_FINANCE_DIRECTOR: 'UNIT_FINANCE_DIRECTOR',
};

const ROLES_APP = {
  NURSE_PRACTITIONER: 'NURSE_PRACTITIONER',
  PHYSICIAN_ASSISTANT: 'PHYSICIAN_ASSISTANT',
  PHYSICIAN_ASSISTANT_SURGICAL: 'PHYSICIAN_ASSISTANT_SURGICAL',
  SOCIAL_WORKER_PAIN_MANAGEMENT: 'SOCIAL_WORKER_PAIN_MANAGEMENT',
};

const ROLES_CAA = {
  ASSISTANT_PROFESSOR: 'ASSISTANT_PROFESSOR',
  CERTIFIED_ANESTHESIOLOGIST_ASSISTANT: 'CERTIFIED_ANESTHESIOLOGIST_ASSISTANT',
  CHIEF_ANESTHETIST: 'CHIEF_ANESTHETIST',
  CHIEF_CAA: 'CHIEF_CAA',
  CRNA: 'CRNA',
  FACULTY_INSTRUCTOR: 'FACULTY_INSTRUCTOR',
  STAFF_CAA: 'STAFF_CAA',
};

const ROLES_CRNA = {
  CHIEF_ANESTHETIST: 'CHIEF_ANESTHETIST',
  CHIEF_CRNA: 'CHIEF_CRNA',
  CRNA: 'CRNA',
  CRNA_ASSISTANT_CHIEF: 'CRNA_ASSISTANT_CHIEF',
  CRNA_CHIEF: 'CRNA_CHIEF',
  CRNA_OPT: 'CRNA_OPT',
  DNP_CRNA: 'DNP_CRNA',
  SRNA: 'SRNA',
  STAFF_CRNA: 'STAFF_CRNA',
};

const ROLES_DOCTOR = {
  ADJUNCT_ASSISTANT_PROFESSOR: 'ADJUNCT_ASSISTANT_PROFESSOR',
  ASSISTANT_PROFESSOR: 'ASSISTANT_PROFESSOR',
  ASSISTANT_PROFESSOR_EMERGENCY_MEDICINE_CRITICAL_CARE:
    'ASSISTANT_PROFESSOR_EMERGENCY_MEDICINE_CRITICAL_CARE',
  ASSISTANT_PROFESSOR_PHYSICAL_MEDICINE_REHABILITATION_PAIN_MANAGEMENT:
    'ASSISTANT_PROFESSOR_PHYSICAL_MEDICINE_REHABILITATION_PAIN_MANAGEMENT',
  ASSOCIATE_PROFESSOR: 'ASSOCIATE_PROFESSOR',
  ATTENDING_PHYSICIAN: 'ATTENDING_PHYSICIAN',
  BASIC_SCIENCE_FACULTY: 'BASIC_SCIENCE_FACULTY',
  CHIEF_PHISICIAN: 'CHIEF_PHISICIAN',
  CHIEF_RESIDENT: 'CHIEF_RESIDENT',
  DEPARTMENT_CHAIR: 'DEPARTMENT_CHAIR',
  DEPARTMENT_CHAIR_AND_JOHN_P_KAMPINE_PROFESSOR_OF_ANESTHESIOLOGY:
    'DEPARTMENT_CHAIR_AND_JOHN_P_KAMPINE_PROFESSOR_OF_ANESTHESIOLOGY',
  FACULTY_PHYSICIAN: 'FACULTY_PHYSICIAN',
  FELLOW: 'FELLOW',
  INSTRUCTOR_FELLOW: 'INSTRUCTOR_FELLOW',
  INTERN: 'INTERN',
  LOCUM_TENENS: 'LOCUM_TENENS',
  MEDICAL_STUDENT: 'MEDICAL_STUDENT',
  PROFESSOR: 'PROFESSOR',
  RESIDENT: 'RESIDENT',
  STAFF_PHYSICIAN: 'STAFF_PHYSICIAN',
};

const ROLES_FACULTY = {
  ADJUNCT_PROFESSOR: 'ADJUNCT_PROFESSOR',
  ASSISTANT_PROFESSOR: 'ASSISTANT_PROFESSOR',
  ASSOCIATE_PROFESSOR: 'ASSOCIATE_PROFESSOR',
  CLINICAL_ASSISTANT_PROFESSOR: 'CLINICAL_ASSISTANT_PROFESSOR',
  PROFESSOR: 'PROFESSOR',
  PROFESSOR_EMERITUS: 'PROFESSOR_EMERITUS',
};

const ROLES_LOGISTICS = {
  EQUIPMENT_MANAGEMENT: 'EQUIPMENT_MANAGEMENT',
  PERIOPERATIVE_STAFF: 'PERIOPERATIVE_STAFF',
  SUPPLY_CHAIN: 'SUPPLY_CHAIN',
};

const ROLES_MSA_STUDENT = {
  MSA_STUDENT: 'MSA_STUDENT',
};

const ROLES_NURSE = {
  CERTIFIED_NURSE: 'CERTIFIED_NURSE',
  CHARGE_NURSE: 'CHARGE_NURSE',
  HEAD_NURSE: 'HEAD_NURSE',
  NURSE_EDUCATOR: 'NURSE_EDUCATOR',
  NURSE_SUPERVISOR: 'NURSE_SUPERVISOR',
  SPECIALISED_NURSE: 'SPECIALISED_NURSE',
  STUDENT_NURSE: 'STUDENT_NURSE',
  TRAINEE_NURSE: 'TRAINEE_NURSE',
};

const ROLES_PARAMEDIC = {
  CERTIFIED_PARAMEDIC: 'CERTIFIED_PARAMEDIC',
  HEAD_PARAMEDIC: 'HEAD_PARAMEDIC',
  STUDENT: 'STUDENT',
  TRAINEE_PARAMEDIC: 'TRAINEE_PARAMEDIC',
};

const ROLES_RESEARCHER = {
  ADMINISTRATIVE_ASSISTANT_SR: 'ADMINISTRATIVE_ASSISTANT_SR',
  CLINICAL_RESEARCH_ASSISTANT_III: 'CLINICAL_RESEARCH_ASSISTANT_III',
  CLINICAL_RESEARCH_COORDINATOR_II: 'CLINICAL_RESEARCH_COORDINATOR_II',
  ENGINEER_II: 'ENGINEER_II',
  GRADUATE_STUDENT: 'GRADUATE_STUDENT',
  LAB_MANAGER: 'LAB_MANAGER',
  LAB_SUPERVISOR: 'LAB_SUPERVISOR',
  LABORATORY_COORDINATOR: 'LABORATORY_COORDINATOR',
  POSTDOCTORAL_FELLOW: 'POSTDOCTORAL_FELLOW',
  PROGRAM_MANAGER_RESEARCH: 'PROGRAM_MANAGER_RESEARCH',
  PROJECT_APPOINTMENT_STUDENT: 'PROJECT_APPOINTMENT_STUDENT',
  RESEARCH_ASSOCIATE_I: 'RESEARCH_ASSOCIATE_I',
  RESEARCH_GRANTS_COORDINATOR: 'RESEARCH_GRANTS_COORDINATOR',
  RESEARCH_SCIENTIST: 'RESEARCH_SCIENTIST',
  RESEARCH_SCIENTIST_I: 'RESEARCH_SCIENTIST_I',
  RESEARCH_SCIENTIST_II: 'RESEARCH_SCIENTIST_II',
  RESEARCH_TECH_II: 'RESEARCH_TECH_II',
  RESEARCH_TECH_III: 'RESEARCH_TECH_III',
  RESEARCH_TECHNOLOGIST_I: 'RESEARCH_TECHNOLOGIST_I',
  SUPERVISOR_LAB: 'SUPERVISOR_LAB',
};

const ROLES_RESPIRATORY_THERAPIST = {
  STAFF_RESPIRATORY_THERAPIST: 'STAFF_RESPIRATORY_THERAPIST',
  RESPIRATORY_THERAPIST_MANAGER: 'RESPIRATORY_THERAPIST_MANAGER',
  RESPIRATORY_THERAPIST_THERAPY: 'RESPIRATORY_THERAPIST_THERAPY',
};

const ROLES_ALL = {
  ...ROLES_ADMINISTRATOR,
  ...ROLES_APP,
  ...ROLES_CAA,
  ...ROLES_CRNA,
  ...ROLES_DOCTOR,
  ...ROLES_FACULTY,
  ...ROLES_LOGISTICS,
  ...ROLES_MSA_STUDENT,
  ...ROLES_NURSE,
  ...ROLES_PARAMEDIC,
  ...ROLES_RESEARCHER,
  ...ROLES_RESPIRATORY_THERAPIST,
};

const TRAINING_LEVELS_MEDICAL_STUDENT = {
  MED1: 'MED1',
  MED2: 'MED2',
  MED3: 'MED3',
  MED4: 'MED4',
  MED5: 'MED5',
  MED6: 'MED6',
};

const TRAINING_LEVELS_RESIDENT = {
  CA_1: 'CA_1',
  CA_2: 'CA_2',
  CA_3: 'CA_3',
  PGY_1: 'PGY_1',
  PGY_2: 'PGY_2',
  PGY_3: 'PGY_3',
  PGY_4: 'PGY_4',
  PGY_5: 'PGY_5',
  PGY_6: 'PGY_6',
  PGY_7: 'PGY_7',
};

const TRAINING_LEVELS_ALL = {
  ...TRAINING_LEVELS_MEDICAL_STUDENT,
  ...TRAINING_LEVELS_RESIDENT,
};

const LANGUAGES = {
  DE: 'DE',
  EN: 'EN',
  FR: 'FR',
  IT: 'IT',
};

const USER_TAGS_KEYS = {
  DEPARTMENT: 'department',
  LEVEL_OF_TRAINING: 'levelOfTraining',
  PROFESSION: 'profession',
  ROLE: 'role',
  ROTATION: 'rotation',
  USERS: 'users',
  DIVISION: 'division',
  LOCATION: 'location',
  SPECIALTY: 'specialty',
};

export {
  DIVISION,
  SPECIALTY,
  PROFESSIONS,
  ROLES_ALL,
  ROLES_ADMINISTRATOR,
  ROLES_APP,
  ROLES_CAA,
  ROLES_CRNA,
  ROLES_DOCTOR,
  ROLES_FACULTY,
  ROLES_LOGISTICS,
  ROLES_MSA_STUDENT,
  ROLES_NURSE,
  ROLES_PARAMEDIC,
  ROLES_RESEARCHER,
  ROLES_RESPIRATORY_THERAPIST,
  TRAINING_LEVELS_MEDICAL_STUDENT,
  TRAINING_LEVELS_RESIDENT,
  TRAINING_LEVELS_ALL,
  LANGUAGES,
  USER_TAGS_KEYS,
};
