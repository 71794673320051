import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { AUTH_LOGOUT, INITIAL_STATE, OWNERS_UPDATE, APPLICATION_WIPE_DATA } from '../actions';

const initialState = {
  items: {},
  lastUpdated: null,
};

const setInitialState = (state, { owners = initialState }) =>
  update(state, {
    $merge: owners,
  });

const updateOwners = (state, { owners }) =>
  update(state, {
    items: { $set: owners },
    lastUpdated: { $set: Date.now() },
  });

const cleanData = () => {
  return initialState;
};

const actionsRepo = {
  [INITIAL_STATE]: setInitialState,
  [OWNERS_UPDATE]: updateOwners,
  [APPLICATION_WIPE_DATA]: cleanData,
  [AUTH_LOGOUT]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
