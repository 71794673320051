import { DEVICE_FLAGS } from '../../constants';
import { APP_FINISH_STEP, APP_INIT, FIRST_TIME, INITIAL_STATE } from '../actions';
import { appIsReady } from '../actions/application.actions';
import { updateDeviceFlags } from '../actions/deviceFlags.actions';
import { loadState } from '../actions/storage.actions';
import _ from 'lodash';

export const appInitMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action);

    if (action.type === APP_INIT) {
      let isReady = getState().application?.isReady;

      if (!isReady) {
        dispatch(loadState());
      } else {
        console.error('App init twice');
      }
    }
  };

export const appReadyMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    // if (action.type === APP_INIT) {
    //   dispatch(loadState())
    // }
  };

export const isFirstTimeMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action);

    if (action.type === INITIAL_STATE) {
      let isFirstTime = getState().deviceFlags[DEVICE_FLAGS.IS_FIRST_TIME];

      if (_.isUndefined(isFirstTime) || isFirstTime === true) {
        let state = _.isUndefined(isFirstTime);
        dispatch(updateDeviceFlags({ key: DEVICE_FLAGS.IS_FIRST_TIME, state }));
        dispatch({ type: FIRST_TIME });

        if (state) {
          console.log('First Time');
        }
      }
    }
  };

export const stepsMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === APP_FINISH_STEP) {
      let state = getState();
      let { parts } = state.application;
      let { step } = action.payload;
      let unfinishedParts = Object.values(parts).filter(state => !state);

      if (unfinishedParts.length === 1 && !parts[step]) {
        next(action);
        dispatch(appIsReady());

        let { CURRENT_LOGIN } = state.deviceFlags;
        dispatch(
          updateDeviceFlags({ key: DEVICE_FLAGS.LAST_OPEN, state: CURRENT_LOGIN || Date.now() }),
        );
        dispatch(updateDeviceFlags({ key: DEVICE_FLAGS.CURRENT_LOGIN, state: Date.now() }));
      } else {
        next(action);
      }
    } else {
      next(action);
    }
  };

export const applicationMiddleware = [
  appInitMiddleware,
  appReadyMiddleware,
  isFirstTimeMiddleware,
  stepsMiddleware,
];
