import React, { useEffect } from 'react';
import sharedStyles from '../widget/widget.module.scss';
import { KnowledgeSectionList } from './knowledge-section-list/knowledge-section-list';

const KnowledgeBaseComponent = React.memo(function KnowledgeBaseComponent({
  category = {},
  knowledgeSelectItem = () => {},
}) {
  useEffect(() => {
    knowledgeSelectItem(category?.id);
  }, [category, knowledgeSelectItem]);

  return (
    <div className={sharedStyles.itemsWrapper}>
      <KnowledgeSectionList knowledgeSelectItem={knowledgeSelectItem} />
    </div>
  );
});

export { KnowledgeBaseComponent };
