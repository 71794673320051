import React from 'react';
import { NotFound } from '../../not-found';
import styles from './search-results.module.scss';
import sharedStyles from '../../widget/widget.module.scss';
import { SEARCH_TYPES_KEYS } from 'shared/constants';
import {
  KnowledgeItem,
  CompendiumItemComponent,
  ChecklistItemComponent,
  ContactItemComponent,
} from '../../items';
import {
  isImprovedSearchEnabled,
  isContactsFeatureEnabled,
} from 'shared/store/selectors/features.selector';
import { useSearchResultsPreparation } from 'shared/hooks/search.hooks';
import { EVENTS } from 'shared/constants/analytics';
import { useSelector } from 'react-redux';
import analyticsService from '../../../helpers/analytics.service';
import { generateUpToDateLink } from 'shared/utils';
import { getLang } from 'shared/store/selectors/lang.selector';

const SEARCH_TYPE = {
  REGULAR: 'regular',
  EXTENDED: 'extended',
};

const SearchResultsComponent = React.memo(function SearchResultsComponent({
  items = {},
  searchTerm = null,
  sessionId,
}) {
  const lang = useSelector(getLang('SEARCH_SCREEN'));
  const adminMode = false;
  const [showExtendedResults, setShowExtendedResults] = React.useState(false);
  const isImprovedSearch = useSelector(isImprovedSearchEnabled);
  const isContactsEnabled = useSelector(isContactsFeatureEnabled);

  const { prepareResults } = useSearchResultsPreparation({}, null, adminMode, isContactsEnabled);

  const { resultsCount, knowledgeItems, labItems, admissionItems, contactItems } =
    prepareResults(items);

  // == Analytics events
  /**
   * Track search session start
   */
  React.useEffect(() => {
    setShowExtendedResults(false);
    // If clean session
    if (!sessionId) {
      return;
    }

    console.debug('[Search] session start', { sessionId, resultsCount, term: searchTerm });

    analyticsService.track(EVENTS.SEARCH.SESSION_START, {
      term: searchTerm,
      sessionId,
      resultsCount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  /**
   * Track click on result
   * @param {Object} item - result item details
   * @param {number} index - result index
   * @param {string} resultType - result type
   * @param {Object} options - Options for analytics
   */
  const onResultClicked = React.useCallback(
    (item, index, resultType, options) => {
      const eventDetails = {
        term: searchTerm,
        sessionId,
        index: index + 1,
        resultsCount,
        score: item?.score || 0,
        resultType,
        ...item,
        ...options,
      };

      analyticsService.track(EVENTS.SEARCH.RESULT_CLICKED, eventDetails);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionId, resultsCount],
  );

  const showExtendedResultsAction = (extendedResultsCount = 0) => {
    setShowExtendedResults(true);
    const eventDetails = {
      term: searchTerm,
      sessionId,
      resultsCount,
      extendedResultsCount,
    };

    analyticsService.track(EVENTS.SEARCH.SHOW_MORE, eventDetails);
  };

  // Prepare render by type
  const renderByType = React.useMemo(
    () => ({
      [SEARCH_TYPES_KEYS.documents]: ({ item }, index, options = {}) => (
        <KnowledgeItem
          key={item.id}
          {...item}
          onClickEvent={event =>
            onResultClicked(event, index, SEARCH_TYPES_KEYS.documents, options)
          }
        />
      ),
      [SEARCH_TYPES_KEYS.compendium]: ({ item }) => (
        <CompendiumItemComponent key={item.id} {...item} />
      ),
      [SEARCH_TYPES_KEYS.admission]: ({ item }, index, options = {}) => (
        <ChecklistItemComponent
          key={item.id}
          {...item}
          onClickEvent={event =>
            onResultClicked(event, index, SEARCH_TYPES_KEYS.admission, options)
          }
        />
      ),
      [SEARCH_TYPES_KEYS.contacts]: ({ item }) => <ContactItemComponent key={item.id} {...item} />,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sessionId, onResultClicked],
  );

  const renderResults = () => {
    // prepare of extended results
    const extendedSearch = isImprovedSearch ? prepareResults(items.extendedSearch) : {};

    // No results to display
    const isEmptyState =
      resultsCount === 0 && (!isImprovedSearch || extendedSearch?.resultsCount === 0);

    const allResults = [...contactItems, ...knowledgeItems, ...labItems, ...admissionItems];

    const options = {
      searchType: SEARCH_TYPE.REGULAR,
    };

    if (resultsCount === 0) {
      return <>{renderUpToDateLink()}</>;
    }

    return (
      <>
        {isEmptyState && <NotFound searchTerm={searchTerm} />}

        {/* Old results */}
        {resultsCount > 0 && (
          <>
            <h5 className={styles.searchHeader}>
              {lang.ITEMS_FOUND.replace("'{number}'", resultsCount).replace('{term}', searchTerm)}
            </h5>
            {allResults.map(({ type, item }, index) =>
              renderByType[type]({ item }, index, options),
            )}
          </>
        )}

        {/* Extended search results */}
        {renderImprovedResults({
          regularResultsCount: resultsCount,
          extendedSearch,
        })}

        {renderUpToDateLink()}
      </>
    );
  };

  const renderImprovedResults = ({ regularResultsCount, extendedSearch }) => {
    // Killer switch - if improved search is disabled
    if (!isImprovedSearch) return null;

    // prepare of extended results
    const { resultsCount, knowledgeItems, labItems, admissionItems, contactItems } = extendedSearch;

    // No results to display
    if (resultsCount === 0) return null;

    // Merge all results and sort by score ASC
    const allResults = [...knowledgeItems, ...labItems, ...admissionItems, ...contactItems].sort(
      ({ score: a }, { score: b }) => a - b,
    );

    const showExtendedResultsItems = showExtendedResults || regularResultsCount === 0;

    if (!showExtendedResultsItems) {
      return (
        <div className={styles.extendedResults}>
          <button
            className={styles.actionButton}
            onClick={() => showExtendedResultsAction(resultsCount)}>
            {lang.SHOW_ADDITIONAL_RESULTS.replace('{number}', resultsCount)}
          </button>
        </div>
      );
    }

    const options = {
      searchType: SEARCH_TYPE.EXTENDED,
      extendedResultsCount: resultsCount,
    };

    // Show results
    return (
      <>
        {/* Show header only if there regular results */}
        {regularResultsCount > 0 && (
          <h5 className={styles.searchHeader}>
            {lang.ADDITIONAL_RESULTS.replace('{term}', searchTerm)}
          </h5>
        )}
        {allResults.map(({ type, item }, index) => renderByType[type]({ item }, index, options))}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const renderUpToDateLink = React.useCallback(() => {
    const [first, upToDateLink] = lang.UP_TO_DATE_LINK.split(/{(.*?)}/);
    const storedDeviceId = localStorage.getItem('deviceId');

    return (
      <div className={styles.upToDate}>
        <p>
          {first}
          <a
            className={styles.upToDateLink}
            target="_blank"
            rel="noopener noreferrer"
            href={generateUpToDateLink(searchTerm, storedDeviceId)}>
            {upToDateLink}
          </a>
        </p>
      </div>
    );
  }, [searchTerm, lang.UP_TO_DATE_LINK]);

  return <div className={sharedStyles.itemsWrapper}>{renderResults()}</div>;
});

export { SearchResultsComponent };
