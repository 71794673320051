import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import {
  APPLICATION_WIPE_DATA,
  WORKSPACES_SELECT_WORKSPACE,
  WORKSPACES_UPDATE_CONTENT,
} from '../actions';

const initialState = {
  list: [],
  selected: null,
  lastUpdated: null,
};

const updateWorkspaces = (state, { workspaces }) =>
  update(state, {
    $merge: {
      list: workspaces,
      lastUpdated: Date.now(),
    },
  });

const selectWorkspace = (state, { selected }) =>
  update(state, {
    $merge: {
      selected: selected,
    },
  });

const cleanData = () => initialState;

const actionsRepo = {
  [WORKSPACES_UPDATE_CONTENT]: updateWorkspaces,
  [WORKSPACES_SELECT_WORKSPACE]: selectWorkspace,
  [APPLICATION_WIPE_DATA]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
