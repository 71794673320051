import { KNOWLEDGE_SELECT_ITEM } from 'shared/store/actions';
import { KNOWLEDGE_SHOW_ITEM_CONTENT } from '.';

export const knowledgeSelectItem = id => dispatch => {
  dispatch({
    type: KNOWLEDGE_SELECT_ITEM,
    payload: { id },
  });
};

export const knowledgeShowItemContent = id => dispatch => {
  dispatch({
    type: KNOWLEDGE_SHOW_ITEM_CONTENT,
    payload: { itemId: id },
  });
};
