import { AVAILABLE_ORGANIZATIONS_UPDATE } from '.';
import organizationServiceApi from '../../api/organization.service.api';

export const fetchAvailableOrganizations = () => async dispatch => {
  let { data, error } = await organizationServiceApi.available();

  if (error) {
    return false;
  }

  dispatch({
    type: AVAILABLE_ORGANIZATIONS_UPDATE,
    payload: data,
  });
};
