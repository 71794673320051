import React, { useCallback } from 'react';
import styles from './widget.module.scss';
import { Search, SearchResultsComponent } from '../search';
import { Lang, ORGANIZATIONS_LOGO_URL } from 'shared/constants';
import { appURL } from 'shared/constants/APIconstants';
import { MobileQRCode } from '../mobile-qr-code';
import { Redirect } from '../redirect';
import { KnowledgeBase } from '../knowledge-base/knowledge-base';
import { Loader } from '../loader/Loader.component';
import { KnowledgeContent } from '../items/knowledge-content/knowldege-content';
import { WIDGET_REDIRECT_QUERY_PARAM } from 'shared/constants';
import ApplicationContext from '../../context/application.context';
import { AddKeywords } from '../items/add-keywords/add-keywords';
import { ReportProblem } from '../report-problem/report-problem';
import { NotAllowed } from '../not-allowed';

const WidgetComponent = React.memo(function WidgetComponent({
  searchTerm = null,
  results = [],
  showKnowledgeBase = false,
  showTags = true,
  hideSpecialtiesTags = true,
  lang = Lang.WIDGET,
  organization = {},
  knowledgeItemId = null,
  knowledgeShowItemContent = () => {},
  sessionId,
  keywordsItemId,
  knowledgeShowKeywords,
  isReportProblem,
  reportProblem,
  notAllowedIp = false,
}) {
  const [context] = React.useState({
    showTags,
    hideSpecialtiesTags,
  });

  const renderLogo = () => (
    <div className={styles.logo}>
      {organization.logo && (
        <img
          src={ORGANIZATIONS_LOGO_URL + 'widget/' + organization.logo}
          alt={organization.name}
          className={styles.logo}
        />
      )}
    </div>
  );

  const closeContent = useCallback(() => {
    knowledgeShowItemContent(null);
  }, [knowledgeShowItemContent]);

  const closeKeywords = useCallback(() => {
    knowledgeShowKeywords(null);
  }, [knowledgeShowKeywords]);

  const closeReportProblem = useCallback(() => {
    reportProblem(false);
  }, [reportProblem]);

  const renderPage = () => {
    if (notAllowedIp) {
      return <NotAllowed />;
    }

    if (!searchTerm && !showKnowledgeBase) {
      return <Loader />;
    }

    if (knowledgeItemId) {
      return <KnowledgeContent id={knowledgeItemId} close={closeContent} />;
    }

    if (!searchTerm) {
      if (isReportProblem) {
        return <ReportProblem close={closeReportProblem} />;
      }

      return <KnowledgeBase />;
    }

    return <SearchResultsComponent items={results} searchTerm={searchTerm} sessionId={sessionId} />;
  };

  return (
    <ApplicationContext.Provider value={context}>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            {renderLogo()}
            <MobileQRCode />
            <Redirect text={lang.SITE_REDIRECT} link={`${appURL}?${WIDGET_REDIRECT_QUERY_PARAM}`} />
          </div>
          {keywordsItemId ? (
            <AddKeywords id={keywordsItemId} close={closeKeywords} />
          ) : (
            <>
              <Search />
              {!searchTerm && !isReportProblem && (
                <h5 className={styles.searchDescription}>{lang.SEARCH_DESCRIPTION}</h5>
              )}
              {renderPage()}
            </>
          )}
        </div>
        <div className={styles.footer}>
          <h5>{lang.COPYRIGHT}</h5>
          {!isReportProblem && !keywordsItemId && !knowledgeItemId && (
            <h5 className={styles.footerLink} onClick={reportProblem}>
              {lang.REPORT_PROBLEM}
            </h5>
          )}
        </div>
      </div>
    </ApplicationContext.Provider>
  );
});

export { WidgetComponent };
