export const ENVIRONMENT_KEYS = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
};

export const ENVIRONMENT = {
  [ENVIRONMENT_KEYS.PRODUCTION]: 'production',
  [ENVIRONMENT_KEYS.STAGING]: 'staging',
  [ENVIRONMENT_KEYS.DEVELOPMENT]: 'development',
};

export const ORGANIZATIONS_LOGO_URL =
  'https://storage.googleapis.com/headtotoe-dev-app-assets/logos/';
