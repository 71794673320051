import React from 'react';
import { RedirectIcon } from '../../images';
import styles from './redirect.module.scss';

const Redirect = React.memo(function Redirect({ text = '', link = '' }) {
  return (
    <a className={styles.link} href={link} target="_blank" rel="noreferrer">
      <h5>{text}</h5>
      <img className={styles.redirectIcon} src={RedirectIcon} alt="redirect" />
    </a>
  );
});

export { Redirect };
