import React, { useCallback, useState } from 'react';
import styles from './add-keywords.module.scss';
import { Lang } from 'shared/constants';
import _ from 'lodash';
import { faArrowLeft, faBan, faCheck, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useKeywords } from 'shared/hooks/knowledge-content.hooks';
import analyticsService from '../../../helpers/analytics.service';
import { EVENTS } from 'shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const NEW_KEYWORD_MAX_LENGTH = 10;

const AddKeywordsComponent = React.memo(function AddKeywordsComponent({
  lang = Lang.ADD_KEYWORDS,
  title,
  id: itemId,
  keywords = [],
  addKeywords,
  close,
}) {
  const [loading, setLoading] = useState(false);

  const {
    suggestedKeywords,
    newKeywords,
    updateKeywords,
    searchKeywords,
    searchValue,
    resetKeywords,
  } = useKeywords({
    keywords,
  });

  const addKeyword = useCallback(
    word => {
      updateKeywords([...newKeywords, word]);
    },
    [updateKeywords, newKeywords],
  );

  const removeKeyword = useCallback(
    word => {
      updateKeywords(newKeywords.filter(item => item !== word));
    },
    [updateKeywords, newKeywords],
  );

  const submitKeywords = useCallback(async () => {
    setLoading(true);

    await addKeywords({ itemId, keywords: newKeywords });

    analyticsService.track(EVENTS.SUGGEST_KEYWORDS.ADD, {
      id: itemId,
      keywords: newKeywords,
    });

    setLoading(false);
    resetKeywords();
    close();
  }, [addKeywords, itemId, newKeywords, resetKeywords, close]);

  const renderHeader = useCallback(
    () => (
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} onClick={close} />
          </div>
          <div className={styles.headerText}>
            <p className={styles.title}>{title}</p>
            <h2 className={styles.pageTitle}>{lang.SUGGEST_KEYWORDS}</h2>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <button
            className={styles.actionButton}
            onClick={submitKeywords}
            disabled={!newKeywords.length || loading}>
            <FontAwesomeIcon icon={faCheck} className={styles.actionButtonIcon} />
            <p className={styles.actionButtonText}>{lang.APPLY}</p>
          </button>
          <button className={styles.actionButton} onClick={close}>
            <FontAwesomeIcon icon={faBan} className={styles.actionButtonIcon} />
            <p className={styles.actionButtonText}>{lang.CANCEL}</p>
          </button>
        </div>
      </div>
    ),
    [
      close,
      lang.APPLY,
      lang.CANCEL,
      lang.SUGGEST_KEYWORDS,
      loading,
      newKeywords.length,
      submitKeywords,
      title,
    ],
  );

  const renderKeywords = useCallback(
    () => (
      <div className={styles.newKeywords}>
        <p className={styles.title}>{lang.ITEM_KEYWORDS}</p>
        <div className={styles.keywords}>
          {_.uniq([...keywords, ...newKeywords]).map(word => {
            const disabled = keywords.includes(word);
            return (
              <div
                key={word}
                className={classNames([styles.keyword, disabled ? styles.disabledKeyword : ''])}
                onClick={() => removeKeyword(word)}
                disabled={disabled}>
                <p
                  className={classNames([
                    styles.keywordText,
                    disabled ? styles.disabledKeywordText : '',
                  ])}>
                  {word}
                </p>
                {!disabled && <FontAwesomeIcon icon={faXmark} className={styles.removeIcon} />}
              </div>
            );
          })}
        </div>
      </div>
    ),
    [keywords, lang.ITEM_KEYWORDS, newKeywords, removeKeyword],
  );

  const renderSuggested = useCallback(
    () => (
      <div className={classNames([styles.keywords, styles.suggestedKeywords])}>
        {suggestedKeywords?.map(word => (
          <div key={word} className={styles.keyword} onClick={() => addKeyword(word)}>
            <p className={styles.keywordText}>{word}</p>
          </div>
        ))}
      </div>
    ),
    [addKeyword, suggestedKeywords],
  );

  const renderNoResults = useCallback(() => {
    const [firstPart, keyword, lastPart] = lang.TAP_TO_ADD_NEW.split("'");
    const isLongKeyword = searchValue.length >= NEW_KEYWORD_MAX_LENGTH;
    const displayKeyword = isLongKeyword
      ? searchValue.slice(0, NEW_KEYWORD_MAX_LENGTH).concat('...')
      : searchValue;

    return searchValue ? (
      <div className={styles.noResults}>
        <p className={styles.title}>
          {lang.KEYWORD_NOT_FOUND.replace('{keyword}', displayKeyword)}
        </p>
        <div
          className={classNames([styles.keyword, styles.addKeywordButton])}
          onClick={() => {
            addKeyword(searchValue);
          }}>
          <p className={styles.keywordText}>{firstPart}</p>
          <p className={classNames([styles.keywordText, styles.keywordBold])}>
            {keyword.replace('{keyword}', `'${displayKeyword}'`)}
          </p>
          <p className={styles.keywordText}>{lastPart}</p>
        </div>
      </div>
    ) : null;
  }, [addKeyword, lang.KEYWORD_NOT_FOUND, lang.TAP_TO_ADD_NEW, searchValue]);

  const renderSuggestedKeywords = useCallback(
    () => (
      <>
        <p className={styles.title}>{lang.TAP_KEYWORD}</p>
        <div className={styles.search}>
          <input
            className={styles.input}
            onChange={({ target }) => searchKeywords(target.value)}
            value={searchValue}
            placeholder={lang.FILTER_KEYWORDS}
          />
          {searchValue ? (
            <FontAwesomeIcon
              className={styles.icon}
              icon={faXmark}
              onClick={() => searchKeywords('')}
            />
          ) : (
            <FontAwesomeIcon className={styles.icon} icon={faSearch} />
          )}
        </div>
        {suggestedKeywords?.length ? renderSuggested() : renderNoResults()}
      </>
    ),
    [
      lang.TAP_KEYWORD,
      lang.FILTER_KEYWORDS,
      searchValue,
      searchKeywords,
      suggestedKeywords?.length,
      renderSuggested,
      renderNoResults,
    ],
  );

  return (
    <div className={styles.root}>
      {renderHeader()}
      {renderKeywords()}
      {renderSuggestedKeywords()}
    </div>
  );
});

export { AddKeywordsComponent };
