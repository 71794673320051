import React from 'react';
import Lottie from 'react-lottie-player';
import loaderData from './loader.json';

const Loader = () => {
  return (
    <Lottie
      loop
      play
      animationData={loaderData}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid',
      }}
      style={{ height: '100px' }}
    />
  );
};

export { Loader };
