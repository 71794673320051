import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import {
  APPLICATION_WIPE_DATA,
  AUTH_LOGOUT,
  CONTACTS_UPDATE_LIST,
  CONTACTS_USE_CACHE,
  INITIAL_STATE,
} from '../actions';

const initialState = {
  contactsById: null,
  lastUpdated: null,
  isReady: false,
};

const updateContacts = (state, { contactsById }) =>
  update(state, {
    $merge: {
      contactsById,
      lastUpdated: Date.now(),
    },
  });

const useCache = state =>
  update(state, {
    $merge: {
      isReady: true,
    },
  });

const setInitialState = (state, { contacts = initialState }) =>
  update(state, {
    $merge: {
      ...contacts,
      isReady: false,
    },
  });

const cleanData = () => {
  return initialState;
};

const actionsRepo = {
  [CONTACTS_UPDATE_LIST]: updateContacts,
  [CONTACTS_USE_CACHE]: useCache,
  [INITIAL_STATE]: setInitialState,
  [APPLICATION_WIPE_DATA]: cleanData,
  [AUTH_LOGOUT]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
