import {
  ADMISSION_DELETE_CATEGORY,
  ADMISSION_UPDATE_CATEGORY,
  KNOWLEDGE_DELETE_ITEM,
  KNOWLEDGE_UPDATE_ITEM,
  LAB_DELETE_ITEM,
  LAB_UPDATE_ITEM,
  ORGANIZATION_SWITCH,
  SEARCH_ON_CHANGE,
  SEARCH_RESULTS,
} from '../actions';
import { forceNewSearch } from '../actions/search.actions';
import { search } from '../selectors/search.selector';

export const fetchSearchResultsMiddleware =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    next(action);

    if (action.type === SEARCH_ON_CHANGE) {
      let { term, force, filters } = action.payload;

      if (!force && term === '') {
        return dispatch({
          type: SEARCH_RESULTS,
          payload: { results: null },
        });
      }

      return dispatch({
        type: SEARCH_RESULTS,
        payload: search(getState(), term, filters),
      });
    }
  };

export const forceSearchUpdateItemUpdatedMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === KNOWLEDGE_UPDATE_ITEM ||
      action.type === KNOWLEDGE_DELETE_ITEM ||
      action.type === LAB_UPDATE_ITEM ||
      action.type === LAB_DELETE_ITEM ||
      action.type === ADMISSION_DELETE_CATEGORY ||
      action.type === ADMISSION_UPDATE_CATEGORY
    ) {
      dispatch(forceNewSearch());
    }
  };

const clearSearchResultsMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    if (action.type === ORGANIZATION_SWITCH) {
      dispatch({
        type: SEARCH_ON_CHANGE,
        payload: { results: null, initialResults: null, term: '' },
      });
    }
    next(action);
  };

export const searchMiddleware = [
  fetchSearchResultsMiddleware,
  forceSearchUpdateItemUpdatedMiddleware,
  clearSearchResultsMiddleware,
];
