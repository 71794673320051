import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import {
  APPLICATION_WIPE_DATA,
  AUTH_LOGOUT,
  USERS_MGMT_CREATE_USER,
  USERS_MGMT_DELETE_USER,
  USERS_MGMT_UPDATE_USER,
  USERS_MGMT_FETCH,
  USERS_MGMT_UPDATE_PERMISSIONS,
  USERS_MGMT_FETCH_STATS,
  USERS_MGMT_CREATE_USERS_IN_BULK,
  USERS_MGMT_UPDATE_USERS_IN_BULK,
  USERS_MGMT_ADD_RESOURCE_TO_USER,
  USERS_MGMT_REMOVE_RESOURCE_FROM_USER,
} from '../actions';

const initialState = {
  list: null,
  stats: null,
  lastUpdated: null,
};

const updateStats = (state, { stats }) =>
  update(state, {
    $merge: {
      stats,
      lastUpdated: Date.now(),
    },
  });

const updateUsers = (state, { users }) =>
  update(state, {
    $merge: {
      list: users,
      lastUpdated: Date.now(),
    },
  });

const updatePermissions = (state, { id, permissions }) => {
  const index = state.list.findIndex(u => u.id === id);

  if (index === -1) return state;

  return update(state, {
    list: {
      [index]: { $merge: { permissions } },
    },
  });
};

const createUser = (state, { user }) => {
  return update(state, {
    list: { $push: [user] },
  });
};

const createUsersInBulk = (state, { users }) => {
  return update(state, {
    list: { $push: users },
  });
};

const updateUserData = (state, { id, user }) => {
  const index = state.list.findIndex(u => u.id === id);

  return update(state, {
    list: {
      [index]: { $merge: user },
    },
  });
};

export const addResourceToUser = (state, { userId, resource }) => {
  const index = state.list.findIndex(u => u.id === userId);

  if (index === -1) return state;

  return update(state, {
    list: {
      [index]: {
        professionalProfile: {
          resources: { $push: [resource] },
        },
      },
    },
  });
};

export const removeResourceFromUser = (state, { userId, resourceId }) => {
  const index = state.list.findIndex(u => u.id === userId);

  if (index === -1) return state;

  const resourceIndex = state.list[index].professionalProfile.resources.findIndex(
    r => r.id === resourceId,
  );

  return update(state, {
    list: {
      [index]: {
        professionalProfile: {
          resources: { $splice: [[resourceIndex, 1]] },
        },
      },
    },
  });
};

const updateUsersInBulk = (state, { users }) => {
  const usersIds = users.map(user => user.id);

  const updatedList = state.list.map(u => {
    if (usersIds.includes(u.id)) {
      return { ...u, ...users.find(user => user.id === u.id) };
    }
    return u;
  });

  return update(state, {
    list: { $merge: updatedList },
  });
};

const deleteUser = (state, { id }) => {
  const index = state.list.findIndex(u => u.id === id);
  return update(state, {
    list: {
      $splice: [[index, 1]],
    },
  });
};

const cleanData = () => {
  return initialState;
};

const actionsRepo = {
  [USERS_MGMT_FETCH_STATS]: updateStats,
  [USERS_MGMT_FETCH]: updateUsers,
  [USERS_MGMT_UPDATE_PERMISSIONS]: updatePermissions,
  [USERS_MGMT_CREATE_USER]: createUser,
  [USERS_MGMT_CREATE_USERS_IN_BULK]: createUsersInBulk,
  [USERS_MGMT_UPDATE_USER]: updateUserData,
  [USERS_MGMT_ADD_RESOURCE_TO_USER]: addResourceToUser,
  [USERS_MGMT_REMOVE_RESOURCE_FROM_USER]: removeResourceFromUser,
  [USERS_MGMT_UPDATE_USERS_IN_BULK]: updateUsersInBulk,
  [USERS_MGMT_DELETE_USER]: deleteUser,
  [APPLICATION_WIPE_DATA]: cleanData,
  [AUTH_LOGOUT]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
