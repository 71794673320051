import { FS_DOWNLOAD_FILE, FS_REMOVE_FILE, FS_UPDATE_LOCAL_FILE } from '.';
import { isRelativePath } from '../selectors/fs.selector';
import { getKnowledgeItemById } from '../selectors/knowledge.selector';

export const downloadFileUpdate = payload => ({
  type: FS_DOWNLOAD_FILE,
  payload,
});

export const removeFile = payload => ({
  type: FS_REMOVE_FILE,
  payload,
});

/**
 * Verify local download files against the new ones
 * And if needed send update event
 * @async
 */
export const verifyDownloadContentIsUpdated = () => async (dispatch, getState) => {
  let { fs, knowledge } = getState();

  Object.values(fs.map).forEach(file => {
    let { id, path } = file;
    let item = getKnowledgeItemById({ knowledge }, id);

    if (!item) {
      return dispatch(removeFile({ id }));
    }

    // Check if have to update the file
    if (
      new Date(file.lastUpdated) > new Date(item.updatedAt) && // Check if the file updated after downloaded
      isRelativePath({ url: path }) // Fallback for temp download directory (iOS virtual directory)
    ) {
      return false;
    }

    dispatch(removeFile({ id }));

    dispatch(updateLocalFile({ id, item }));
  });
};

/**
 * Trigger update local file
 * @param {*} { id, item } - item id and the last updated item
 */
export const updateLocalFile = ({ id, item }) => ({
  type: FS_UPDATE_LOCAL_FILE,
  payload: { id, item },
});
