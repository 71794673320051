import React, { useRef } from 'react';
import QRCode from 'react-qr-code';
import { QrCodeIcon } from 'shared/images/icons';
import { Lang } from 'shared/constants';
import { STORES_LINKS } from 'shared/constants/application.constants';
import styles from './mobile-qr-code.module.scss';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MobileQRCode = React.memo(function MobileQRCode({
  url = STORES_LINKS.mobile,
  lang = Lang.WIDGET,
}) {
  const modal = useRef(null);
  const qrIcon = useRef(null);

  const calculateTransformOrigin = () => {
    const { x, y, width } = qrIcon?.current?.getBoundingClientRect();
    return `${x + width}px ${y}px`;
  };

  const openDialog = () => {
    modal?.current?.style.setProperty('transform-origin', calculateTransformOrigin());
    modal?.current?.showModal();
  };

  const closeDialog = () => {
    modal?.current?.classList.add(styles.close);
    setTimeout(() => {
      // wait for animation to finish
      modal?.current?.close();
      modal?.current?.classList.remove(styles.close);
    }, 300);
  };

  return (
    <>
      <div className={styles.qrContainer} onClick={openDialog}>
        <p className={styles.clickAndScan}>{lang.CLICK_AND_SCAN}</p>
        <img ref={qrIcon} className={styles.qrIcon} src={QrCodeIcon} alt="qr icon" />
      </div>
      <dialog ref={modal}>
        <button className={styles.closeModalBtn} onClick={closeDialog}>
          <FontAwesomeIcon icon={faXmark} className={styles.closeIcon} />
        </button>
        <div>
          <div className={styles.qrCode}>
            <QRCode className={styles.qrLarge} value={url} />
          </div>
          <p>{lang.SCAN_TO_DOWNLOAD_MOBILE_APP}</p>
        </div>
      </dialog>
    </>
  );
});

export { MobileQRCode };
