import EN_LANG from './languages/en.lang.json';
import FR_LANG from './languages/fr.lang.json';
import DE_LANG from './languages/de.lang.json';
import IT_LANG from './languages/it.lang.json';

export const Lang = EN_LANG;

export const LANGUAGE = {
  en: {
    key: 'en',
    label: 'English (en)',
    lang: EN_LANG,
  },
  fr: {
    key: 'fr',
    label: 'Français (fr)',
    lang: FR_LANG,
  },
  de: {
    key: 'de',
    label: 'Deutsch (de)',
    lang: DE_LANG,
  },
  it: {
    key: 'it',
    label: 'Italiano (IT)',
    lang: IT_LANG,
  },
};

export const DEFAULT_LANGUAGE = 'en';
