import { compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import { admissionMiddleware } from './admission.middleware';
import { applicationMiddleware } from './application.middleware';
import { authMiddleware } from './auth.middleware';
import { availableOrganizationsMiddleware } from './available-organizations.middleware';
import { communicationMiddleware } from './communication.middleware';
import { deepLinkingMiddleware } from './deep-linking.middleware';
import { deviceFlagsMiddleware } from './deviceFlags.middlewae';
import { favoritesMiddleware } from './favorites.middleware';
import { fsMiddleware } from './fs.middleware';
import { knowledgeMiddleware } from './knowledge.middleware';
import { labMiddleware } from './lab.middleware';
import { langMiddleware } from './lang.middleware';
import { loggerMiddleware } from './logger.middleware';
import { notificationMiddleware } from './notification.middleware';
import { organizationMiddleware } from './organization.middleware';
import { ownersMiddleware } from './owners.middleware';
import { searchMiddleware } from './search.middleware';
import { userMiddleware } from './user.middleware';
import { usersManagementMiddleware } from './users-management.middleware';
import { contactsMiddleware } from './contacts.middleware';
import { labelsMiddleware } from './labels.middleware';
import { workspaceMiddleware } from './workspace.middleware';

export const middleware = (middlewares = []) =>
  compose(
    composeWithDevTools(
      applyMiddleware(
        thunk,
        loggerMiddleware,
        ...applicationMiddleware,
        ...authMiddleware,
        ...deviceFlagsMiddleware,
        ...searchMiddleware,
        ...userMiddleware,
        ...favoritesMiddleware,
        ...knowledgeMiddleware,
        ...fsMiddleware,
        ...notificationMiddleware,
        ...organizationMiddleware,
        ...ownersMiddleware,
        ...labMiddleware,
        ...availableOrganizationsMiddleware,
        ...deepLinkingMiddleware,
        ...usersManagementMiddleware,
        ...admissionMiddleware,
        ...langMiddleware,
        ...communicationMiddleware,
        ...contactsMiddleware,
        ...labelsMiddleware,
        ...workspaceMiddleware,
        ...middlewares,
      ),
    ),
  );
