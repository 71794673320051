import {
  APP_INIT,
  AUTH_ATTACH_USER_ID,
  NOTIFICATION_ADD_TAG,
  NOTIFICATION_CHECK_STATE,
  NOTIFICATION_OPEN_PROMPTED,
  NOTIFICATION_UPDATE_STATE,
  ORGANIZATION_UPDATE,
} from '../actions';
import { updateStateToStorage } from '../actions/storage.actions';

export const notificationInit =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === APP_INIT) {
      // Trigger the current platform notification middleware
      dispatch({
        type: NOTIFICATION_CHECK_STATE,
      });
    }
  };

export const notificationUpdate =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === NOTIFICATION_UPDATE_STATE || action.type === NOTIFICATION_OPEN_PROMPTED) {
      // Trigger the current platform notification middleware
      dispatch(updateStateToStorage());
    }
  };

export const notificationsAddOrganizationTag =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === ORGANIZATION_UPDATE) {
      dispatch({
        type: NOTIFICATION_ADD_TAG,
        payload: { key: 'organization', value: action.payload?.id },
      });
    }
  };

export const notificationsAddUserIdTag =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === AUTH_ATTACH_USER_ID) {
      dispatch({
        type: NOTIFICATION_ADD_TAG,
        payload: { key: 'id', value: action?.payload?.id },
      });
    }
  };

export const notificationMiddleware = [
  notificationInit,
  notificationUpdate,
  notificationsAddOrganizationTag,
  notificationsAddUserIdTag,
];
