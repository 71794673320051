import update from 'immutability-helper';
import { REMOTE_CONFIG_DEFAULT, REMOTE_CONFIG_KEYS } from '../../constants';
import { createReducer } from '../../utils/redux/reducers.utils';
import { FEATURE_FLAGS_UPDATE } from '../actions';

const initialState = REMOTE_CONFIG_DEFAULT[REMOTE_CONFIG_KEYS.FEATURE_FLAGS];

const updateFeatureFlagsStatus = (state, payload) =>
  update(state, {
    $merge: payload,
  });

const actionsRepo = {
  [FEATURE_FLAGS_UPDATE]: updateFeatureFlagsStatus,
};

export default createReducer({ initialState, actionsRepo });
