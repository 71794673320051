import _ from 'lodash';
import {
  FAVORITES_ADD_ITEM,
  FAVORITES_MARK_FOR_REMOVAL,
  FAVORITES_TOGGLE_ITEM,
  FAVORITES_UPDATE_CONTENT,
  FAVORITES_USE_CACHE,
} from '.';
import FavoritesServiceApi from '../../api/favorites.service.api';
import { isConnected } from '../selectors/network.selector';

export const toggleFavorites = ({ id: itemId, entity }) => ({
  type: FAVORITES_TOGGLE_ITEM,
  payload: { itemId, entity },
});

export const addToFavorites = ({ itemId, entity }) => ({
  type: FAVORITES_ADD_ITEM,
  payload: { itemId, entity },
});

export const markForRemoval = ({ itemId }) => ({
  type: FAVORITES_MARK_FOR_REMOVAL,
  payload: { itemId },
});

export const fetchFavorites = () => async (dispatch, getState) => {
  const state = getState();

  if (!isConnected(state)) {
    return dispatch({
      type: FAVORITES_USE_CACHE,
    });
  }

  const { data, error } = await FavoritesServiceApi.get();

  if (error) {
    return dispatch({
      type: FAVORITES_USE_CACHE,
    });
  }

  const { favorites = [] } = data;
  const favoritesById = {};

  favorites.forEach(({ itemId, ...item }) => {
    favoritesById[itemId] = { itemId, ...item };
  });

  dispatch({
    type: FAVORITES_UPDATE_CONTENT,
    payload: {
      favoritesById,
    },
  });
};

export const addFavorite =
  ({ itemId, entity }) =>
  async (dispatch, getState) => {
    const state = getState();
    const workspaceId = state.workspace.selected;

    if (!isConnected(state)) {
      return dispatch({
        type: FAVORITES_ADD_ITEM,
        payload: { itemId, entity },
      });
    }

    return dispatch(addFavoritesToServer({ items: [{ itemId, entity }], workspaceId }));
  };

export const addFavoritesToServer =
  ({ items, workspaceId }) =>
  async () =>
    await Promise.all(
      items.map(({ itemId, entity }) =>
        FavoritesServiceApi.create({
          itemId,
          entity,
          workspaceId,
        }),
      ),
    );

export const deleteFavorite =
  ({ item }) =>
  async (dispatch, getState) => {
    const state = getState();

    if (!isConnected(state)) {
      return dispatch({
        type: FAVORITES_MARK_FOR_REMOVAL,
        payload: { itemId: item.itemId },
      });
    }

    return dispatch(deleteFavoritesOnServer({ items: [item] }));
  };

export const deleteFavoritesOnServer =
  ({ items }) =>
  async () =>
    await Promise.all(
      items.map(({ id }) =>
        FavoritesServiceApi.delete({
          id,
        }),
      ),
    );

export const saveFavoritesChangesToServer = () => async (dispatch, getState) => {
  const { favorites } = getState();

  if (_.isEmpty(favorites.byId)) {
    return;
  }

  const toAdd = Object.keys(favorites.byId)
    .filter(favKey => _.isUndefined(favorites.byId[favKey].id))
    .map(key => favorites.byId[key]);

  const toDelete = Object.keys(favorites.byId)
    .filter(favKey => !_.isUndefined(favorites.byId[favKey].toDelete))
    .map(key => favorites.byId[key]);

  if (toAdd.length) {
    await dispatch(addFavoritesToServer({ items: toAdd }));
  }

  if (toDelete.length) {
    await dispatch(deleteFavoritesOnServer({ items: toDelete }));
  }
};
