import {
  APPLICATION_REFRESH_DATE,
  APPLICATION_SET_ADMIN_MODE,
  APP_FINISH_STEP,
  APP_INIT,
  APP_IS_READY,
} from '.';

export const appInit = () => ({
  type: APP_INIT,
});

export const finishStep = ({ step }) => ({
  type: APP_FINISH_STEP,
  payload: { step },
});

export const appIsReady = () => ({
  type: APP_IS_READY,
});

export const setAdminMode = state => ({
  type: APPLICATION_SET_ADMIN_MODE,
  payload: { state },
});

export const applicationRefreshDate = state => ({
  type: APPLICATION_REFRESH_DATE,
});
