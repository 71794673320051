import jwtDecode from 'jwt-decode';
import update from 'immutability-helper';
import _ from 'lodash';
import {
  AUTH_ATTACH_USER_ID,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_UPDATE_USER,
  INITIAL_STATE,
  AUTH_UPDATE_TOKEN,
  AUTH_SET_REGION,
  AUTH_UPDATE_TOKEN_DETAILS,
  AUTH_UPDATE_AUTH_CONTEXT,
  AUTH_ADD_RESOURCE_TO_USER,
  AUTH_REMOVE_RESOURCE_FROM_USER,
} from '../actions';
import { createReducer } from '../../utils/redux/reducers.utils';

const initialState = {
  token: null,
  accessToken: null,
  refreshToken: null,
  tokenDetails: {},
  refreshTokenDetails: {},
  user: {},
  id: '',
  region: null,
};

const authLogin = (state, { token, accessToken, refreshToken, user }) =>
  update(state, {
    $merge: {
      token,
      accessToken,
      tokenDetails: { ...jwtDecode(accessToken) },
      refreshToken,
      refreshTokenDetails: { ...jwtDecode(refreshToken) },
      user: update(state.user, { $merge: user }),
    },
  });

const authUpdateToken = (state, { accessToken, refreshToken }) => {
  const payload = {
    accessToken,
    tokenDetails: { ...jwtDecode(accessToken) },
  };

  if (!_.isUndefined(refreshToken)) {
    payload.refreshToken = refreshToken;
    payload.refreshTokenDetails = { ...jwtDecode(refreshToken) };
  }

  return update(state, {
    $merge: payload,
  });
};

const updateAuthContext = (state, { context }) => {
  const payload = {
    tokenDetails: context,
  };

  return update(state, {
    $merge: payload,
  });
};

const updateTokenDetails = (state, { accessToken }) =>
  update(state, {
    $merge: {
      accessToken,
      tokenDetails: { ...jwtDecode(accessToken) },
    },
  });

const attachUserId = (state, { id }) =>
  update(state, {
    id: { $set: id },
  });

const loadInitialState = (state, { auth = {} }) =>
  update(state, {
    $merge: auth,
  });

const updateUserDetails = (state, { user }) =>
  update(state, {
    $merge: {
      user: update(state.user, { $merge: user }),
    },
  });

const addResourceToAuthUser = (state, { resource }) =>
  update(state, {
    user: {
      professionalProfile: {
        resources: { $push: [resource] },
      },
    },
  });

const removeResourceFromAuthUser = (state, { resourceId }) =>
  update(state, {
    user: {
      professionalProfile: {
        resources: { $apply: resources => resources.filter(r => r.id !== resourceId) },
      },
    },
  });

const setRegion = (state, { region }) =>
  update(state, {
    region: { $set: region },
  });

const authLogout = () => {
  return initialState;
};

const actionsRepo = {
  [INITIAL_STATE]: loadInitialState,
  [AUTH_LOGIN]: authLogin,
  [AUTH_UPDATE_TOKEN]: authUpdateToken,
  [AUTH_UPDATE_AUTH_CONTEXT]: updateAuthContext,
  [AUTH_ATTACH_USER_ID]: attachUserId,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_UPDATE_USER]: updateUserDetails,
  [AUTH_ADD_RESOURCE_TO_USER]: addResourceToAuthUser,
  [AUTH_REMOVE_RESOURCE_FROM_USER]: removeResourceFromAuthUser,
  [AUTH_SET_REGION]: setRegion,
  [AUTH_UPDATE_TOKEN_DETAILS]: updateTokenDetails,
};

export default createReducer({ initialState, actionsRepo });
