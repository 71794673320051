class ServiceBase {
  get state() {
    let state = this.storeState;
    return this.reducer ? state[this.reducer] : state;
  }

  get storeState() {
    return global.store?.getState();
  }

  dispatch(action) {
    global.store?.dispatch(action);
  }
}

export { ServiceBase };
