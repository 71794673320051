import { SEARCH_ON_CHANGE, SEARCH_START } from '.';
import { debounce } from 'lodash';
import { isOnSearch } from '../selectors/search.selector';
import { nanoid } from 'nanoid';

const updateTerm = debounce((term, dispatch) => {
  const sessionId = term !== '' ? nanoid() : null;
  console.log('[Search actions] updateTerm', { term, sessionId });

  dispatch({
    type: SEARCH_ON_CHANGE,
    payload: {
      term,
      sessionId,
    },
  });
}, 500);

export const updateSearch = term => (dispatch, getState) => {
  let isSearch = isOnSearch(getState());

  console.log('[Search actions] updateSearch', { isSearch, term });
  if (!isSearch) {
    dispatch({
      type: SEARCH_START,
    });
  }

  updateTerm(term, dispatch);
};

export const forceNewSearch = () => (dispatch, getState) => {
  let { term } = getState().search;
  console.log('[Search actions] forceNewSearch', { term });

  if (!term) {
    return false;
  }

  dispatch({
    type: SEARCH_ON_CHANGE,
    payload: {
      term,
      force: true,
    },
  });
};

export const addSearchFilters = filters => (dispatch, getState) => {
  const { term } = getState().search;

  if (!term || !filters) {
    return false;
  }

  dispatch({
    type: SEARCH_ON_CHANGE,
    payload: {
      term,
      force: true,
      filters,
    },
  });
};
