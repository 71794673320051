import { REGIONS } from '../constants/region.constants';

const DEFAULT_LOCALE = `en-${REGIONS.US}`;
/**
 * Check if date is today
 * @param {*} date
 * @returns true if date is today
 * @example isToday('2022-01-19T14:29:00.000Z') => true
 */
const isToday = date => {
  const today = new Date();
  const dateToCheck = new Date(date);
  return (
    today.getDate() === dateToCheck.getDate() &&
    today.getMonth() === dateToCheck.getMonth() &&
    today.getFullYear() === dateToCheck.getFullYear()
  );
};

/**
 * Check if date is tomorrow
 * @param {*} date
 * @returns true if date is tomorrow
 * @example isTomorrow('2022-01-19T14:29:00.000Z') => true
 */
const isTomorrow = date => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const dateToCheck = new Date(date);
  return (
    tomorrow.getDate() === dateToCheck.getDate() &&
    tomorrow.getMonth() === dateToCheck.getMonth() &&
    tomorrow.getFullYear() === dateToCheck.getFullYear()
  );
};

/**
 * Check if date is in the past
 * @param {*} date
 * @returns true if date is in the past
 * @example isPastDate('2022-01-19T14:29:00.000Z') => false
 */
const isPastDateOrToday = date => {
  const today = new Date();
  const dateToCheck = new Date(date);
  return today >= dateToCheck;
};

/**
 * Calculate days until date
 * @param {*} date
 * @returns days until date
 * @example calculateDaysUntil('2022-01-19T14:29:00.000Z') => 10
 */
const calculateDaysUntil = date => {
  if (!date) {
    return null;
  }

  if (isPastDateOrToday(date)) {
    return null;
  }

  const today = new Date();
  const dateToCheck = new Date(date);
  const diffTime = dateToCheck - today;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

/**
 * Check if date is in the future
 * @param {*} date
 * @returns true if date is in the future
 * @example isFutureDate('2022-01-19T14:29:00.000Z') => true
 */
const isFutureDate = date => {
  const today = new Date();
  const dateToCheck = new Date(date);
  return today < dateToCheck;
};

/**
 * Format date in long format, locale dependent
 * @param {*} date
 * @param {*} options
 * @returns date in long format
 */
const formatDateLocale = ({ date, options, locale = DEFAULT_LOCALE }) => {
  return Intl.DateTimeFormat(locale, options).format(date);
};

/**
 * Format date and time in long format, locale dependent
 * @param {*} date
 * @param {*} options
 * @param {*} locale
 * @returns date and time in long format
 */
const formatDateTimeLocale = ({ date, options, locale = DEFAULT_LOCALE }) => {
  return Intl.DateTimeFormat(locale, options).format(date);
};

/**
 * Format date range in locale dependent
 * @param {*} start
 * @param {*} end
 * @param {*} options
 * @param {*} locale
 * @returns date range in locale dependent
 */
// TODO: formatRange doesn't work in Android, investigate alternative
// const formatDateRangeLocale = ({ start, end, options, locale = DEFAULT_LOCALE }) => {
//   return Intl.DateTimeFormat(locale, options).formatRange(start, end);
// };

/**
 * Format date in long format
 * @param {*} date
 * @param {*} locale
 * @returns date in long format
 * @example "Jan 19, 2022"
 */
const formatLongDate = ({ date, locale, options }) => {
  if (!date) {
    return '';
  }
  return formatDateLocale({
    date: new Date(date),
    options: { year: 'numeric', month: 'short', day: 'numeric', ...options },
    locale,
  });
};

/**
 * Format date and time in long format
 * @param {*} date
 * @param {*} locale
 * @returns date and time in long format
 * @example "January 19, 2022 at 2:29 PM"
 */
const formatLongDateTime = ({ date, locale, options }) => {
  if (!date) {
    return '';
  }
  return formatDateTimeLocale({
    date: new Date(date),
    options: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      ...options,
    },
    locale,
  });
};

/**
 * Format date in short format
 * @param {*} date
 * @param {*} locale
 * @returns date in short format, with year if not current year
 * @example "1/19/22"
 */
const formatShortDate = ({ date, locale, options }) => {
  if (!date) {
    return '';
  }
  const currentYear = new Date().getFullYear();
  const dateYear = new Date(date).getFullYear();

  return formatDateLocale({
    date: new Date(date),
    options: {
      year: currentYear !== dateYear ? 'numeric' : undefined,
      month: 'numeric',
      day: 'numeric',
      ...options,
    },
    locale,
  });
};

/**
 * Format date and time in short format
 * @param {*} date
 * @param {*} locale
 * @returns date and time in short format, with year if not current year
 * @example "1/19/22, 2:31 PM"
 */
const formatShortDateTime = ({ date, locale, options }) => {
  if (!date) {
    return '';
  }
  const currentYear = new Date().getFullYear();
  const dateYear = new Date(date).getFullYear();

  return formatDateTimeLocale({
    date: new Date(date),
    options: {
      year: currentYear !== dateYear ? 'numeric' : undefined,
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      ...options,
    },
    locale,
  });
};

/**
 * Format date range in short format
 * @param {*} start
 * @param {*} end
 * @param {*} locale
 * @returns date range in short format, with year if not current year
 * @example "1/19/22 - 1/21/22"
 */
const formatRangeDate = ({ start, end, locale, options }) => {
  const startDate = formatShortDate({
    date: new Date(start),
    locale,
    options,
  });
  const endDate = formatShortDate({
    date: new Date(end),
    locale,
    options,
  });

  return `${startDate} - ${endDate}`;
};

/**
 * Format time in short format
 * @param {*} date
 * @returns time in short format
 * @example "2:31 PM"
 */
const formatShortTime = ({ date, locale, options }) => {
  if (!date) {
    return '';
  }
  return formatDateTimeLocale({
    date: new Date(date),
    options: { hour: 'numeric', minute: 'numeric', ...options },
    locale,
  });
};

export {
  isToday,
  isTomorrow,
  isPastDateOrToday,
  isFutureDate,
  calculateDaysUntil,
  formatLongDate,
  formatLongDateTime,
  formatShortDate,
  formatShortDateTime,
  formatRangeDate,
  formatShortTime,
};
