import userService from '../../services/user.service';
import {
  ADMISSION_UPDATE_CATEGORY,
  APPLICATION_REFRESH_DATE,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
} from '../actions';
import { getAdmissionData } from '../actions/admission.actions';

export const admissionFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE
    ) {
      if (!userService.isAuthenticated) return false;

      console.log('fetch admission');
      dispatch(getAdmissionData());
    }
  };

export const admissionOnCategoryChangeOwnerIdMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === ADMISSION_UPDATE_CATEGORY) {
      if (!action.payload.ownerIds) return false;

      dispatch(getAdmissionData());
    }
  };

export const admissionMiddleware = [
  admissionFetchDataMiddleware,
  admissionOnCategoryChangeOwnerIdMiddleware,
];
