import { ORGANIZATION_ATTACH_ORG_ID, ORGANIZATION_UPDATE, ORGANIZATION_SWITCH } from '.';
import authenticationServiceApi from '../../api/authentication.service.api';
import organizationServiceApi from '../../api/organization.service.api';
import { setAdminMode } from './application.actions';

export const updateOrganization = () => async dispatch => {
  let { data, error } = await organizationServiceApi.me();

  if (error) {
    return false;
  }

  dispatch({
    type: ORGANIZATION_UPDATE,
    payload: data,
  });
};

export const switchOrganization =
  ({ id }) =>
  async dispatch => {
    let res = await authenticationServiceApi.switchOrganization({
      id,
    });

    if (res.error) {
      return false;
    }

    dispatch(setAdminMode(false));

    dispatch({
      type: ORGANIZATION_SWITCH,
      payload: res.data,
    });
  };

export const organizationAttachId = ({ id }) => ({
  type: ORGANIZATION_ATTACH_ORG_ID,
  payload: { id },
});
