import { currentEnvironment } from '../services/Environment';

export const appForegroundThresholdMs = 5 * 60 * 1000;

export const UPDATE_MANAGER_STATES = {
  SUGGEST: 'suggest',
  MANDATORY: 'mandatory',
};

export const STORES_LINKS = {
  android: 'https://play.google.com/store/apps/details?id=com.headtotoe.app',
  ios: 'https://apps.apple.com/ch/app/headtotoe-io/id1534264609',
  mobile: 'http://qrfy.com/p/ftbFbIGddx',
};

export const SUPPORT_EMAIL = 'support@c8health.com';
export const OFFICIAL_SITE_LINK = 'https://www.c8health.com/';
export const TERMS_AND_CONDITIONS_LINK = 'https://c8health.com/terms-and-conditions-iav/';

export const UP_TO_DATE_SYSID = 'UTD1114160';
export const UP_TO_DATE_URL = 'https://www.uptodate.com/contents/search';

export const APP_SCHEME = Object.freeze({
  DEVELOPMENT: 'staging-c8health',
  STAGING: 'staging-c8health',
  PRODUCTION: 'c8health',
});

export const getAppScheme = () => APP_SCHEME[currentEnvironment().toUpperCase()];
