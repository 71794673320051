// Auth actions
export const REGISTER_API_KEY = '[Auth] Register Api Key';

// Application actions
export const FETCH_DATA = '[Application] Fetch Data';

// Knowledge actions
export const KNOWLEDGE_SHOW_ITEM_CONTENT = '[Knowledge] Show item content';
export const KNOWLEDGE_SHOW_KEYWORDS_VIEW = '[Knowledge] Show keywords view';

export const SHOW_REPORT_PROBLEM_VIEW = '[Report Problem] Show report problem view';
