import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import {
  AUTH_LOGOUT,
  INITIAL_STATE,
  COMMUNICATION_UPDATE_CONTENT,
  COMMUNICATION_USE_CACHE,
  APPLICATION_WIPE_DATA,
  COMMUNICATION_DELETE_ITEM,
} from '../actions';

const initialState = {
  newsFeedItems: null,
  lastUpdated: null,
  previousLastUpdated: null,
  isReady: false,
};

const updateCommunication = (state, { newsFeedItems }) =>
  update(state, {
    $merge: {
      newsFeedItems,
      lastUpdated: Date.now(),
      previousLastUpdated: state.lastUpdated || Date.now(),
      isReady: true,
    },
  });

const deleteCommunicationItem = (state, { id }) => {
  let index = state.newsFeedItems.findIndex(i => i.id === id);
  return update(state, {
    newsFeedItems: { $splice: [[index, 1]] },
    $merge: {
      lastUpdated: Date.now(),
      previousLastUpdated: state.lastUpdated || Date.now(),
    },
  });
};

const useCache = state =>
  update(state, {
    isReady: { $set: true },
  });

const setInitialState = (state, { communication = initialState }) =>
  update(state, {
    $merge: {
      ...communication,
      isReady: false,
    },
  });

const cleanDate = (state, payload) => {
  return initialState;
};

const actionsRepo = {
  [INITIAL_STATE]: setInitialState,
  [COMMUNICATION_UPDATE_CONTENT]: updateCommunication,
  [COMMUNICATION_DELETE_ITEM]: deleteCommunicationItem,
  [COMMUNICATION_USE_CACHE]: useCache,
  [APPLICATION_WIPE_DATA]: cleanDate,
  [AUTH_LOGOUT]: cleanDate,
};

export default createReducer({ initialState, actionsRepo });
