import { LANG_MANAGER_UPDATE_LANG } from 'shared/store/actions';
import { DEFAULT_LANGUAGE, DEVICE_FLAGS, LANGUAGE } from '../../constants';
import { updateDeviceFlags } from './deviceFlags.actions';

export const updateLang = ({ lang }) => ({
  type: LANG_MANAGER_UPDATE_LANG,
  payload: {
    lang,
  },
});

export const setLang =
  ({ key }) =>
  (dispatch, getState) => {
    dispatch(updateDeviceFlags({ key: DEVICE_FLAGS.LANGUAGE, state: key }));

    const { langManager } = getState();

    const { lang } =
      langManager.repo[key.toLowerCase()] ||
      LANGUAGE[key.toLowerCase()] ||
      LANGUAGE[DEFAULT_LANGUAGE];

    dispatch(updateLang({ lang }));
  };
