import { FS_DOWNLOAD_FILE, FS_REMOVE_FILE, KNOWLEDGE_UPDATE_CONTENT } from '../actions';
import { verifyDownloadContentIsUpdated } from '../actions/fs.actions';
import { updateStateToStorage } from '../actions/storage.actions';

export const fsDownloadMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === FS_DOWNLOAD_FILE || action.type === FS_REMOVE_FILE) {
      dispatch(updateStateToStorage());
    }
  };

export const fsCheckLastUpdatedFilesMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action);

    if (action.type === KNOWLEDGE_UPDATE_CONTENT) {
      dispatch(verifyDownloadContentIsUpdated());
    }
  };

export const fsMiddleware = [fsDownloadMiddleware, fsCheckLastUpdatedFilesMiddleware];
