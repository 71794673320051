import { Lang } from 'shared/constants/lang';

export const getLang =
  key =>
  ({ lang }) =>
    lang[key] ?? Lang[key] ?? {};

export const getLangKey =
  () =>
  ({ deviceFlags }) =>
    deviceFlags?.LANGUAGE?.toUpperCase() ?? 'EN';
