import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { SEARCH_ON_CHANGE, SEARCH_RESULTS, SEARCH_START } from '../actions';

const initialState = {
  term: null,
  results: null,
  initialResults: null,
  isOnSearch: false,
  sessionId: null,
};

const onStartSearch = state =>
  update(state, {
    isOnSearch: { $set: true },
  });

const searchChange = (state, { term, sessionId, force = false }) => {
  const change = {
    term,
    results: null,
  };

  if (!force) {
    change.sessionId = sessionId;
  }

  return update(state, {
    $merge: change,
  });
};

const updateSearchResults = (state, { results, initialResults }) => {
  let change = {
    results: { $set: results },
    isOnSearch: { $set: false },
  };

  if (initialResults) {
    change.initialResults = { $set: initialResults };
  }

  return update(state, change);
};

const actionsRepo = {
  [SEARCH_START]: onStartSearch,
  [SEARCH_ON_CHANGE]: searchChange,
  [SEARCH_RESULTS]: updateSearchResults,
};

export default createReducer({ initialState, actionsRepo });
