import userService from '../../services/user.service';
import {
  APPLICATION_REFRESH_DATE,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
  COMMUNICATION_MESSAGE_SEND,
  COMMUNICATION_UPDATE_CONTENT,
  NETWORK_CONNECTION_CHANGE_ONLINE,
} from '../actions';
import { getCommunicationContent } from '../actions/communication-center.actions';
import { updateStateToStorage } from '../actions/storage.actions';

export const communicationDataFetchedMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === COMMUNICATION_UPDATE_CONTENT) {
      dispatch(updateStateToStorage());
    }
  };

export const communicationFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE ||
      action.type === COMMUNICATION_MESSAGE_SEND
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(getCommunicationContent());
    }
  };

export const communicationDataReconnectToNetworkMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === NETWORK_CONNECTION_CHANGE_ONLINE) {
      dispatch(getCommunicationContent());
    }
  };

export const communicationMiddleware = [
  communicationDataReconnectToNetworkMiddleware,
  communicationFetchDataMiddleware,
];
