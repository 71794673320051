import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { FS_DOWNLOAD_FILE, FS_REMOVE_FILE, INITIAL_STATE } from '../actions';

const initialState = {
  lastUpdated: null,
  map: {},
};

const downloadFile = (state, { id, path, url, ext }) =>
  update(state, {
    lastUpdated: { $set: Date.now() },
    map: {
      [id]: {
        $set: { id, path, url, ext, lastUpdated: Date.now() },
      },
    },
  });

const removeFile = (state, { id }) =>
  update(state, {
    map: {
      $apply: function (obj) {
        delete obj[id];
        return obj;
      },
    },
    lastUpdated: { $set: Date.now() },
  });

const loadState = (state, { fs = initialState }) =>
  update(state, {
    $merge: fs,
  });

const actionsRepo = {
  [INITIAL_STATE]: loadState,
  [FS_DOWNLOAD_FILE]: downloadFile,
  [FS_REMOVE_FILE]: removeFile,
};

export default createReducer({ initialState, actionsRepo });
