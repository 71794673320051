export const STORAGE_KEYS = {
  APPLICATION_DATA: '@application_data',
};

export const STORAGE_REDUCERS_TO_SAVE = [
  'auth',
  'organization',
  'deviceFlags',
  'favorites',
  'user',
  'knowledge',
  'fs',
  'notification',
  'owners',
  'lab',
  'admission',
  'communication',
  'labels',
];

export const STORAGE_REDUCERS_DATA_TO_ELIMINATE = {
  auth: {
    user: {
      web: true,
    },
    token: {
      web: true,
    },
    accessToken: {
      web: true,
    },
    refreshToken: {
      web: true,
    },
  },
};
