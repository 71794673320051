import { DRUG_CLASSES } from 'shared/constants';
import {
  Benzopdiazepines,
  MuscleRelaxants,
  BenzopdiazepinesAntagonists,
  Opioids,
  OpioidsAntagonists,
  Vasopressors,
  LocalAnaesthetics,
  AnticholinergicAgents,
  AntiEmetics,
  Heparin,
  HypotensiveAgents,
  InductionAgents,
  MiscellaneousDrugs,
  Protamine,
  RelaxantReversalAgents,
} from '../images/drugClasses';

const drugClassesToIconsMapper = {
  [DRUG_CLASSES.MUSCLE_RELAXANTS]: MuscleRelaxants,
  [DRUG_CLASSES.BENZOPDIAZEPINES]: Benzopdiazepines,
  [DRUG_CLASSES.OPIOIDS]: Opioids,
  [DRUG_CLASSES.OPIOIDS_ANTAGONISTS]: OpioidsAntagonists,
  [DRUG_CLASSES.VASOPRESSORS]: Vasopressors,
  [DRUG_CLASSES.LOCAL_ANAESTHETICS]: LocalAnaesthetics,
  [DRUG_CLASSES.ANTICHOLINERGIC_AGENTS]: AnticholinergicAgents,
  [DRUG_CLASSES.ANTI_EMETICS]: AntiEmetics,
  [DRUG_CLASSES.HEPARIN]: Heparin,
  [DRUG_CLASSES.BENZOPDIAZEPINES_ANTAGONISTS]: BenzopdiazepinesAntagonists,
  [DRUG_CLASSES.HYPOTENSIVE_AGENTS]: HypotensiveAgents,
  [DRUG_CLASSES.INDUCTION_AGENTS]: InductionAgents,
  [DRUG_CLASSES.MISCELLANEOUS_DRUGS]: MiscellaneousDrugs,
  [DRUG_CLASSES.PROTAMINE]: Protamine,
  [DRUG_CLASSES.RELAXANT_REVERSAL_AGENTS]: RelaxantReversalAgents,
};

export const getDrugClassIcon = drugClass => drugClassesToIconsMapper[drugClass];
