import { connect } from 'react-redux';
import { getCategoryById, getItemsByCategoryId } from 'shared/store/selectors/knowledge.selector';
import { KnowledgeCategoryComponent } from './knowledge-category.component';
import { filterByExpiryDate } from 'shared/hooks/knowledge.hooks';

const mapStateToProps = ({ knowledge }, { id }) => {
  const knowledgeItems = id
    ? getItemsByCategoryId(knowledge, { id }).filter(filterByExpiryDate)
    : [];
  const category = id ? getCategoryById(knowledge, { id }) : {};

  return {
    ...category,
    knowledgeItems,
  };
};

export const KnowledgeCategory = connect(mapStateToProps)(KnowledgeCategoryComponent);
