import { OWNERS_DELETE, OWNERS_UPDATE } from '.';
import OwnersServiceAPI from '../../api/owners.service.api';
import { isConnected } from '../selectors/network.selector';
import update from 'immutability-helper';

export const getOwners = () => async (dispatch, getState) => {
  let state = getState();

  // If the user not connected use the cache
  if (!isConnected(state)) return false;

  let { data, error } = await OwnersServiceAPI.get();

  if (error) {
    console.warn('Fail to fetch owners');
    return false;
  }

  let owners = {};
  data.owners.forEach(owner => (owners[owner.id] = owner));

  dispatch({
    type: OWNERS_UPDATE,
    payload: { owners },
  });
};

export const createOwner = owner => async (dispatch, getState) => {
  let { data, error } = await OwnersServiceAPI.createOwner({ owner });

  if (error) {
    console.warn('Fail to fetch owners');
    return { error: data.message };
  }

  let state = getState();

  let owners = update(state.owners.items, {
    [data.owner.id]: { $set: data.owner },
  });

  dispatch({
    type: OWNERS_UPDATE,
    payload: { owners, isUpdate: true },
  });

  return { owner: data?.owner };
};

export const updateOwner = owner => async (dispatch, getState) => {
  let { data, error } = await OwnersServiceAPI.update(owner);

  if (error) {
    console.warn('Fail to fetch owners');
    return { error: data.message };
  }

  let state = getState();

  let owners = update(state.owners.items, {
    [data.owner.id]: { $set: data.owner },
  });

  dispatch({
    type: OWNERS_UPDATE,
    payload: { owners, isUpdate: true },
  });

  return {};
};

export const deleteOwner =
  ({ id, ownerId }) =>
  async dispatch => {
    let { data, error } = await OwnersServiceAPI.delete({ id, ownerId });

    if (error) {
      console.warn('Fail to delete owners');
      return { error: data.message };
    }

    dispatch({
      type: OWNERS_DELETE,
      payload: {
        id,
      },
    });

    return {};
  };
