import { currentEnvironment } from 'shared/services/Environment';

const FIREBASE_CONFIG_BY_ENV = {
  production: {
    apiKey: 'AIzaSyAM1hpmRvGHXOq8H25EW7qOoQbLeGCvp5o',
    authDomain: 'headtotoe-production.firebaseapp.com',
    projectId: 'headtotoe-production',
    storageBucket: 'headtotoe-production.appspot.com',
    messagingSenderId: '571115247056',
    appId: '1:571115247056:web:4f8f644b2b4c15cce555d5',
    measurementId: 'G-RXKVLVFGSP',
  },
  staging: {
    apiKey: 'AIzaSyAxZgzbQKuWpeaQc_QPl_39pgDGClNbXqs',
    authDomain: 'head-to-toe-dev.firebaseapp.com',
    databaseURL: 'https://head-to-toe-dev.firebaseio.com',
    projectId: 'head-to-toe-dev',
    storageBucket: 'head-to-toe-dev.appspot.com',
    messagingSenderId: '117618283161',
    appId: '1:117618283161:web:ad4b9c3d1fbb41e0d89e08',
    measurementId: 'G-2R64E9FQ6P',
  },
  development: {
    apiKey: 'AIzaSyAxZgzbQKuWpeaQc_QPl_39pgDGClNbXqs',
    authDomain: 'head-to-toe-dev.firebaseapp.com',
    databaseURL: 'https://head-to-toe-dev.firebaseio.com',
    projectId: 'head-to-toe-dev',
    storageBucket: 'head-to-toe-dev.appspot.com',
    messagingSenderId: '117618283161',
    appId: '1:117618283161:web:ad4b9c3d1fbb41e0d89e08',
    measurementId: 'G-2R64E9FQ6P',
  },
};

export const FIREBASE_CONFIG = FIREBASE_CONFIG_BY_ENV[currentEnvironment()];
