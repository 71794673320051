import React from 'react';
import { getTagIcon } from '../../../constants/tagIconsMapper';
import styles from './tag-with-icon.module.scss';
import { Lang } from 'shared/constants';

const TagWithIconComponent = React.memo(function TagWithIconComponent({
  tag = null,
  lang = Lang.TAGS,
}) {
  if (!tag) return;

  const getTagLabel = tag => lang[tag];
  return (
    <div className={styles.tag}>
      <img className={styles.tagIcon} src={getTagIcon(tag)} alt={tag} />
      <h5>{getTagLabel(tag)}</h5>
    </div>
  );
});

export { TagWithIconComponent };
