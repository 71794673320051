import React, { useCallback, useState } from 'react';
import { ArrowLeftIcon } from '../../../images';
import styles from './knowledge-section-list.module.scss';
import { KnowledgeSection } from '../knowledge-section';
import { Lang } from 'shared/constants';

const KnowledgeSectionListComponent = React.memo(function KnowledgeSectionComponent({
  category = {},
  parentCategory = {},
  sections = [],
  knowledgeSelectItem = () => {},
  lang = Lang.WIDGET,
}) {
  const [showAllSections, setShowAllSections] = useState(false);

  const goBack = useCallback(() => {
    const categoryIndexToGoBack =
      category.pathParts.length - 2 > 0 ? category.pathParts.length - 2 : 0;

    knowledgeSelectItem(category.pathParts[categoryIndexToGoBack]);
  }, [knowledgeSelectItem, category]);

  const toggleShowAllSections = useCallback(() => {
    setShowAllSections(state => !state);
  }, [setShowAllSections]);

  const renderSectionTitles = () => {
    if (category?.path === 'root') return;

    return (
      <div className={styles.wrapper}>
        <img className={styles.arrow} src={ArrowLeftIcon} onClick={goBack} alt={'back'} />
        <div className={styles.titleBox}>
          <h5>{parentCategory?.title}</h5>
          <h4>{category?.title}</h4>
        </div>
      </div>
    );
  };

  const renderList = () =>
    sections
      .filter(section => (showAllSections ? section : !section?.options?.hideInWidget))
      .map(section => (
        <KnowledgeSection
          key={section.id}
          categoryId={section.id}
          knowledgeSelectItem={knowledgeSelectItem}
        />
      ));

  const hasHiddenSections = sections.some(section => section?.options?.hideInWidget);

  return (
    <>
      {renderSectionTitles()}
      {renderList()}
      {hasHiddenSections && (
        <div className={styles.showSections}>
          <h5 onClick={toggleShowAllSections}>
            {showAllSections ? lang.HIDE_SECTIONS : lang.SHOW_SECTIONS}
          </h5>
        </div>
      )}
    </>
  );
});

export { KnowledgeSectionListComponent };
